/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkStickyHeader', [
  '$window',
  function ($window) {
    let directiveDefinitionObject;
    return (directiveDefinitionObject = {
      restrict: 'A',
      link(scope, element, attr) {
        const PAGE_HEADER_HEIGHT = 49 + 80; // nav bar at top of page + collapsed header
        const profilePageMainCon = document.getElementById(
          'scrollRegionForTracker'
        );

        const stickyHeader = angular.element(element[0]);
        const splashWrapper = angular.element('.splashWrapper');
        let clonedStickyHeader = null;

        const createClone = function (positionTop) {
          clonedStickyHeader = stickyHeader.clone();
          stickyHeader.after(clonedStickyHeader);
          clonedStickyHeader.css({
            position: 'fixed',
            top: positionTop,
            width: '980px',
            'z-index': '99',
          });
        };

        const determineVisibility = function () {
          const splashHeight = splashWrapper.height();
          const stickyHeaderTop =
            stickyHeader.offset().top - (PAGE_HEADER_HEIGHT + splashHeight);
          const scrollTop = angular.element($window).scrollTop();
          const contentBottom =
            stickyHeaderTop +
            stickyHeader.parent().height() -
            PAGE_HEADER_HEIGHT; // page stickyHeader height and footer height

          if (scrollTop > stickyHeaderTop && scrollTop < contentBottom) {
            if (!clonedStickyHeader) {
              createClone(PAGE_HEADER_HEIGHT + splashHeight);
            }
          } else {
            if (clonedStickyHeader) {
              clonedStickyHeader.remove();
              clonedStickyHeader = null;
            }
          }
        };

        return angular
          .element(profilePageMainCon)
          .bind('scroll', _.throttle(determineVisibility, 100));
      },
    });
  },
]);
