/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkExpenseList', [
  '$log',
  '$q',
  '$filter',
  '$parse',
  'TKData',
  'TKDateUtil',
  ($log, $q, $filter, $parse, TKData, TKDateUtil) => ({
    restrict: 'E',
    require: '^tkExpenseTracker',
    templateUrl: 'ng-approvals/templates/expenseTracker/expenseList',

    controller: function ($scope, $element, $attrs) {
      $scope.$watch(
        $attrs.expenses,
        (expenses) => ($scope.expenses = expenses)
      );

      $scope.I18n = I18n;

      return ($scope.deleteExpense = function (expense) {
        const index = $scope.expenses.indexOf(expense);
        if (index >= 0) {
          return $scope.expenses.splice(index, 1);
        }
      });
    },
  }),
]);
