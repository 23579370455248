angular.module('app').directive('tkClickToEdit', [
  '$timeout',
  function ($timeout) {
    const ENTER_KEYCODE = 13;
    const ESCAPE_KEYCODE = 27;
    return {
      restrict: 'A',
      transclude: true,
      replace: true,
      template: `\
<div> \
<div ng-click='toggle()' ng-hide='editing' ng-transclude></div> \
<input type='text' \
ng-model='model' \
ng-if='editing' \
ng-keyup='handleKeyup($event)' \
ng-blur='toggle()'> \
</div>\
`,
      scope: {
        model: '=',
        onSubmit: '&',
        disabled: '=',
      },
      link(scope, el) {
        scope.toggle = function () {
          if (!scope.disabled) {
            if (scope.editing) {
              scope.model = el.find('input').val();
              scope.onSubmit(scope);
            } else {
              $timeout(function () {
                el.find('input').focus().select();
              });
            }
            scope.editing = !scope.editing;
          }
        };

        const cancel = function () {
          scope.editing = false;
        };

        scope.handleKeyup = function ($event) {
          switch ($event.keyCode) {
            case ENTER_KEYCODE:
              scope.toggle();
              break;
            case ESCAPE_KEYCODE:
              cancel();
              break;
          }
          // when TAB_KEYCODE then next() # TODO create a way to tab to the next input
        };
      },
    };
  },
]);
