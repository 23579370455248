angular.module('app.services').factory('TKPopoverManager', [
  '$document',
  '$log',
  function ($document, $log) {
    const manager = {};
    const popoverStack = [];
    manager.registerPopover = function (popover) {
      popoverStack.push(popover);
    };

    manager.removePopover = function (popoverId) {
      let i;
      if (_.findIndex) {
        // lodash
        i = _.findIndex(popoverStack, { id: popoverId });
      } else {
        // fall back to underscoreJS
        i = _.indexOf(
          popoverStack,
          _.find(popoverStack, (item) => item.id === popoverId)
        );
      }

      if (i !== -1) {
        const removals = popoverStack.splice(i, popoverStack.length);
        while (removals.length) {
          removals.pop().remove();
        }
      }
    };

    manager.showingPopover = () => popoverStack.length > 0;

    manager.removeAllPopovers = function () {
      while (popoverStack.length) {
        popoverStack.pop().remove();
      }
    };

    $document.on('click', function (evnt) {
      let i;
      if (!(i = popoverStack.length)) {
        return;
      }
      while (i--) {
        var popover = popoverStack[i];
        if (popover.hasClick(evnt)) {
          break;
        } else {
          popover.remove();
          popoverStack.pop();
        }
      }
    });

    return manager;
  },
]);
