angular.module('app.services').factory('TKCleanup', function () {
  // The purpose of this service is to provide
  // a standard and convenient way to collect
  // all the unwatch functions returned by
  // $scope.$watch and $scope.$on, and call
  // them on '$destroy'
  const TKCleanup = {};

  TKCleanup.newCleanup = function ($scope) {
    const cleaners = [];

    const cleanup = function (cleaner) {
      if (_.isFunction(cleaner)) {
        cleaners.push(cleaner);
      } else {
        while (cleaners.length) {
          cleaners.shift()();
        }
      }
    };

    if ($scope) {
      cleanup($scope.$on('$destroy', cleanup));
    } else {
      throw '$scope is required for cleanup';
    }

    return cleanup;
  };

  return TKCleanup;
});
