/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular
  .module('app')
  .controller(
    'RootController',
    function ($scope, $location, $rootScope, $state, $log, TKSession) {
      const onLocationChangeStart = (event, next, current) =>
        $log.info(`location changing to: ${next}`);

      $rootScope.$on('$locationChangeStart', onLocationChangeStart);

      // checks if path is '/signin' or '/invitation/:token'
      const isValidPath = function () {
        const path = /^\/(signin$|invitation\/\w+$)/g;
        return path.test($location.path());
      };

      // TODO Figure out how the approvals app establishes a session.
      // For now, always show the placeholder welcome template
      return $state.go('root.welcome');
    }
  );
