/*
 * decaffeinate suggestions:
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// The primary concern of this service is behaving as an interceptor for logging http requests
// and responses.

angular.module('app.services').factory('TKHttpLogger', [
  '$q',
  '$log',

  function ($q, $log) {
    const httpLogger = {};

    const buildQueryParams = function (params) {
      let result = '';
      if (params) {
        const parts = (() => {
          const result1 = [];
          for (var key in params) {
            var value = params[key];
            var encodedValue = encodeURIComponent(value);
            result1.push(`${key}=${encodedValue}`);
          }
          return result1;
        })();
        result = parts.join('&');
      }
      return result;
    };

    httpLogger.request = function (config) {
      const queryParams = buildQueryParams(config.params);
      $log.info(`${config.method} ${config.url}?${queryParams}`);
      return config || $q.when(config);
    };

    httpLogger.requestError = (rejection) => $q.reject(rejection);

    httpLogger.response = (response) => response || $q.when(response);

    httpLogger.responseError = (rejection) => $q.reject(rejection);

    return httpLogger;
  },
]);
