// Generated by CoffeeScript 2.7.0
(function () {
  angular.module('app').directive('tkApprovablesTracker', [
    '$log',
    '$q',
    '$timeout',
    'TKData',
    'TKPermissions',
    'TKDateUtil',
    'TKOrgSettings',
    'TKTimeTrackerTimerManager',
    'TKCleanup',
    'TKLocalStorage',
    function (
      $log,
      $q,
      $timeout,
      TKData,
      TKPermissions,
      TKDateUtil,
      TKOrgSettings,
      TKTimeTrackerTimerManager,
      TKCleanup,
      TKLocalStorage
    ) {
      return {
        restrict: 'E',
        templateUrl: 'ng-approvals/templates/approvablesTracker',
        scope: {
          userId: '=',
          enableExpenseApprovals: '=',
          enableTimeApprovals: '=',
          type: '=?',
        },
        controller: function ($scope, $element) {
          var ONE_DAY,
            checkPresentTime,
            cleanup,
            getToday,
            localStorage,
            orgSettings,
            showError;
          ONE_DAY = 24 * 60 * 60 * 1000;
          $scope.imgSrc = PROGRESS_INDICATOR;
          $scope.I18n = I18n;
          getToday = function () {
            return TKDateUtil.toStartOfDay(new Date());
          };
          localStorage = TKLocalStorage.getLocalStorageObject();
          $scope.datePickerState = {
            usingCustomDateRange: false,
          };
          orgSettings = null;
          if ($scope.type == null) {
            $scope.type = 'timetracking';
          }
          $scope.errorMessage = '';
          showError = function () {
            $scope.errorMessage = I18n.t('msg_error_loading_data');
            $scope.dataLoaded = false;
          };
          $scope.canEditUser = function () {
            return TKPermissions.canEditUser($scope.userId);
          };
          cleanup = TKCleanup.newCleanup($scope);
          cleanup(
            $scope.$on('tk-approvables-type', function (event, type) {
              $scope.$apply(function () {
                $scope.type = type;
              });
            })
          );
          //$element.on
          TKOrgSettings.promise.then(function (settings) {
            var date, lastDayCount, timer;
            orgSettings = settings;
            lastDayCount = +localStorage.tkApprovablesTrackerDayCount;
            $scope.setDayCount(lastDayCount === 1 ? 1 : 7);
            $scope.dataLoaded = !$scope.errorMessage;
            $scope.expandEnabled = TKOrgSettings.timeEntryMode() === 'itemized';
            $scope.timeTracker = {
              isLoaded: false,
            };
            if ((timer = TKTimeTrackerTimerManager.getRunningTimer())) {
              date = TKDateUtil.parseRubyDate(timer.timeEntryDate);
              $scope.toDayView(date);
            }
          }, showError);
          cleanup(
            $scope.$on('tk-time-tracker-loaded', function () {
              $scope.timeTracker.isLoaded = true;
            })
          );
          cleanup(
            $scope.$on('tk-time-tracker-day-view', function (e, date) {
              $scope.toDayView(date);
            })
          );
          cleanup($scope.$on('tk-time-tracker-error', showError));
          cleanup(
            $scope.$watch('type', function (newVal, oldVal) {
              var switchingToTimetracking;
              switchingToTimetracking =
                newVal === 'timetracking' && oldVal !== 'timetracking';
              // custom date range is only allowed in expenses
              if (
                switchingToTimetracking &&
                $scope.datePickerState.usingCustomDateRange
              ) {
                $scope.datePickerState.usingCustomDateRange = false;
                $scope.startDate = getToday();
                $scope.setDayCount(7);
              }
            })
          );
          $scope.toDayView = function (date) {
            var today;
            if (!date) {
              today = getToday();
              if ($scope.startDate <= today && today <= $scope.endDate) {
                date = today;
              } else {
                date = $scope.startDate;
              }
            }
            $scope.datePickerState.usingCustomDateRange = false;
            $scope.startDate = date;
            $scope.setDayCount(1);
          };
          $scope.toWeekView = function () {
            $scope.datePickerState.usingCustomDateRange = false;
            $scope.setDayCount(7);
          };
          $scope.toCustomView = function () {
            $scope.datePickerState.usingCustomDateRange = true;
            return ($scope.datePickerState.isVisible = true);
          };
          $scope.setDayCount = function (count) {
            $scope.startDate = $scope.startDate || getToday();
            if (count === 7 && !$scope.datePickerState.usingCustomDateRange) {
              $scope.startDate = orgSettings.firstWorkdayOfWeek(
                $scope.startDate
              );
            }
            $scope.dayCount = count;
            if (count === 1 || count === 7) {
              // TKAnalytics depends on this to determine which view to track pages for.
              localStorage.tkApprovablesTrackerDayCount = count;
            }
            $scope.endDate = TKDateUtil.addDays(
              $scope.startDate,
              ($scope.dayCount || 1) - 1
            );
            $scope.datePickerState.chosenDate = $scope.startDate;
            $scope.datePickerState.chosenStartDate = $scope.startDate;
            $scope.datePickerState.chosenEndDate = $scope.endDate;
            checkPresentTime();
          };
          $scope.chooseDate = function () {
            $timeout(function () {
              $scope.startDate = TKDateUtil.toStartOfDay(
                $scope.datePickerState.chosenDate
              );
              $scope.setDayCount($scope.dayCount);
              $scope.datePickerState.isVisible = false;
            });
          };
          $scope.chooseStartDate = function () {
            $timeout(function () {
              $scope.startDate = TKDateUtil.toStartOfDay(
                $scope.datePickerState.chosenStartDate
              );
            });
          };
          $scope.chooseEndDate = function () {
            $timeout(function () {
              var dayCount;
              $scope.endDate = TKDateUtil.toStartOfDay(
                $scope.datePickerState.chosenEndDate
              );
              dayCount =
                Math.floor(
                  ($scope.endDate.getTime() - $scope.startDate.getTime()) /
                    ONE_DAY
                ) + 1;
              $scope.setDayCount(dayCount);
            });
          };
          $scope.changeDate = function (deltaDays) {
            $scope.startDate = TKDateUtil.addDays($scope.startDate, deltaDays);
            $scope.endDate = TKDateUtil.addDays(
              $scope.startDate,
              $scope.dayCount - 1
            );
            checkPresentTime();
          };
          $scope.nextPage = function () {
            $scope.changeDate($scope.dayCount);
          };
          $scope.lastPage = function () {
            $scope.changeDate(-$scope.dayCount);
          };
          checkPresentTime = function () {
            $scope.isPresentTime = TKDateUtil.isWithinTimeRange(
              $scope.startDate,
              $scope.endDate
            );
          };
        },
      };
    },
  ]);
}.call(this));
