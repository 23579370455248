/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkIconFix', [
  '$window',
  ($window) => ({
    restrict: 'A',

    link(scope, element, attrs) {
      const setHalfPixelOnOddWindowInnerWidth = function () {
        if ($window.innerWidth % 2 !== 0) {
          attrs.$set('style', 'margin-left:0.5px');
        } else {
          attrs.$set('style', '');
        }
      };

      angular.element($window).on('resize', setHalfPixelOnOddWindowInnerWidth);

      return setHalfPixelOnOddWindowInnerWidth();
    },
  }),
]);
