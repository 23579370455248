/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS201: Simplify complex destructure assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkFilter', [
  '$log',
  ($log) => ({
    restrict: 'E',
    templateUrl: 'ng-approvals/templates/filter',
    replace: true,
    transclude: true,

    scope: {
      filter: '=',
      onSelect: '&',
    },

    controller: function ($scope) {
      $scope.popupVisible = false;

      $scope.I18n = I18n;

      $scope.dateFormat = 'MMM d';

      $scope.dateRange = {};

      $scope.searchBoxAtLength = 5;

      $scope.filterState = {
        searchFilter: null,
      };

      // Preload data
      $scope.filter.loadData();

      $scope.togglePopup = function () {
        $scope.popupVisible = !$scope.popupVisible;
        $scope.fixedHeight =
          $scope.filter.fixedHeight != null ? $scope.filter.fixedHeight : true;
      };

      $scope.hidePopup = function ($event) {
        $scope.popupVisible = false;
        $scope.enableWindowScroll();
        $event.stopPropagation();
      };

      $scope.setValue = function (value) {
        $scope.filter.value = _.clone(value);
        $scope.onSelect();
      };

      $scope.showSearchBox = () =>
        $scope.filter.options.length >= $scope.searchBoxAtLength;

      $scope.searchFilter = function (option) {
        if ($scope.showSearchBox()) {
          const label = $scope.filter.getOption(option).toLowerCase();
          const filter = $scope.filterState.searchFilter || '';
          return label.indexOf(filter.toLowerCase()) >= 0;
        }

        return true;
      };

      $scope.bumpDownDateRangePicker = function (filterType, ...rest) {
        const [el] = Array.from(rest[0]);
        if (filterType === 'dateRange') {
          // fragile hack to get the popover out from under the top nav bar
          // and the arrow to match the content
          el.css({ top: '36px' })
            .find('.tk-arrow')
            .css({ top: '33%', left: '-19px' })
            .addClass('tk-arrow-grey');
        }
      };

      // This logic also exists in the project picker
      // TODO abstract this out into a directive
      (function (previousOnscroll) {
        $scope.enableWindowScroll = function () {
          if (previousOnscroll) {
            document.getElementById('mainCon').onscroll = previousOnscroll;
          }
          previousOnscroll = null;
        };

        $scope.preventWindowScroll = function () {
          const mainCon = document.getElementById('mainCon');
          previousOnscroll = previousOnscroll || mainCon.onscroll || _.noop;
          const { scrollTop } = mainCon;
          mainCon.onscroll = function (event) {
            previousOnscroll(event);
            // event.preventDefault() # `preventDefault` does not prevent scrolling
            mainCon.scrollTop = scrollTop;
          };
        };
      })(null);

      const unwatch = $scope.$watch('filter', function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          $scope.filter.loadData();
        }
      });

      var unbind = $scope.$on('$destroy', function () {
        unwatch();
        unbind();
        $scope.enableWindowScroll();
      });
    },
  }),
]);
