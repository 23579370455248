/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Beware: There is a lot of code duplication between this directive and tkApprovalPage.
// If you change code here, you may also need to change it in there as well.

angular.module('app').directive('tkSubmitApprovablesPage', [
  '$log',
  '$q',
  '$filter',
  'TKData',
  'TKDateUtil',
  'TKOrgSettings',
  'TKTimesheetFactory',
  'TKApprovalFilterManager',
  'TKAssignables',
  function (
    $log,
    $q,
    $filter,
    TKData,
    TKDateUtil,
    TKOrgSettings,
    TKTimesheetFactory,
    TKApprovalFilterManager,
    TKAssignables
  ) {
    return {
      restrict: 'E',
      templateUrl: 'ng-approvals/templates/submitApprovablesPage',
      scope: {
        enableExpenseApprovals: '=',
        enableTimeApprovals: '=',
        userId: '=',
        from: '@',
        to: '@',
        type: '@',
      },
      controller: function ($scope) {
        $scope.I18n = I18n;

        if ($scope.enableExpenseApprovals && $scope.enableTimeApprovals) {
          $scope.pageTitle = I18n.t('lbl_my_time_and_expenses');
        } else if ($scope.enableExpenseApprovals) {
          $scope.pageTitle = I18n.t('lbl_my_expenses');
        } else if ($scope.enableTimeApprovals) {
          $scope.pageTitle = I18n.t('lbl_my_time');
        }

        // These optional timesheets go at the top of the page
        $scope.currentTimesheets = null;
        // These timesheets are listed below any current timesheets
        $scope.otherTimesheets = null;
        // All timesheets together (the union of currentTimesheets and otherTimesheets)
        $scope.timesheets = null;

        $scope.loading = 0;
        const enterLoading = function () {
          ++$scope.loading;
        };
        const exitLoading = function () {
          --$scope.loading;
        };

        if ($scope.from) {
          $scope.fromDate = TKDateUtil.parseRubyDate($scope.from);
        }
        if ($scope.to) {
          $scope.toDate = TKDateUtil.parseRubyDate($scope.to);
        }
        const approvableType = (() => {
          switch ($scope.type) {
            case 'expenses':
              return I18n.t('lbl_my_expenses');
            default:
              return I18n.t('lbl_time');
          }
        })();

        let orgSettings = null;

        let initialized = false;

        const filterGroups = [];

        // Create and configure the `time frame` filter group
        const timeFrameFilterGroup = TKApprovalFilterManager.createFilterGroup(
          I18n.t('lbl_time_frame')
        );
        timeFrameFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter({
            name: 'dateRange',
            type: 'dateRange',
            value: I18n.t('lbl_this_week'),
            options: [
              I18n.t('lbl_last_30_days'),
              I18n.t('lbl_this_week'),
              I18n.t('lbl_last_week'),
              I18n.t('lbl_this_month'),
              I18n.t('lbl_last_month'),
            ],
            fixedHeight: false,
            getValue() {
              if (_.isString(this.value)) {
                return this.value;
              }
              return TKDateUtil.dateRangeString(this.value);
            },
            getDates() {
              let end, start;
              const dates = {};
              // thisWeek = orgSettings.firstWorkdayOfWeek(new Date())
              const now = new Date();
              const today = TKDateUtil.toStartOfDay(now);
              if (_.isObject(this.value)) {
                ({ start, end } = this.value);
                if (start && !end) {
                  if (start <= today) {
                    this.value.end = today;
                  } else {
                    this.value.end = start;
                  }
                } else if (end && !start) {
                  if (today <= end) {
                    this.value.start = today;
                  } else {
                    this.value.start = end;
                  }
                } else if (!start && !end) {
                  this.value.start = this.value.end = today;
                }
                return this.value;
              }
              switch (this.value) {
                case I18n.t('lbl_last_30_days'):
                  dates.start = TKDateUtil.addDays(today, -30);
                  dates.end = today;
                  break;
                case I18n.t('lbl_this_week'):
                  dates.start = TKOrgSettings.firstWorkdayOfWeek(today);
                  dates.end = TKDateUtil.addDays(dates.start, 6);
                  break;
                case I18n.t('lbl_last_week'):
                  dates.start = TKOrgSettings.firstWorkdayOfWeek(
                    TKDateUtil.addDays(today, -7)
                  );
                  dates.end = TKDateUtil.addDays(dates.start, 6);
                  break;
                case I18n.t('lbl_this_month'):
                  dates.start = TKDateUtil.toStartOfMonth(today);
                  dates.end = TKDateUtil.toEndOfMonth(today);
                  break;
                case I18n.t('lbl_last_month'):
                  dates.start = TKDateUtil.toStartOfMonth(
                    TKDateUtil.addDays(TKDateUtil.toStartOfMonth(today), -1)
                  );
                  dates.end = TKDateUtil.toEndOfMonth(dates.start);
                  break;
              }
              return dates;
            },
          })
        );

        filterGroups.push(timeFrameFilterGroup);

        // Create and configure the `type` filter group if both time and expenses are enabled
        if ($scope.enableExpenseApprovals && $scope.enableTimeApprovals) {
          const typeFilterGroup =
            TKApprovalFilterManager.createFilterGroup('Type');
          typeFilterGroup.addFilter(
            TKApprovalFilterManager.createFilter({
              name: 'approvableType',
              type: 'enumeration',
              value: approvableType,
              options: [I18n.t('lbl_time'), I18n.t('lbl_expenses')],
              fixedHeight: false,
            })
          );

          filterGroups.push(typeFilterGroup);
        }

        // Create and configure the `show` filter group
        const showFilterGroup =
          TKApprovalFilterManager.createFilterGroup('Show');

        const statusOptions = [
          I18n.t('lbl_unsubmitted'),
          I18n.t('lbl_pending_approval'),
          I18n.t('lbl_approved'),
        ];
        if (
          gData.accountSettings.moduleEnabled('relationship-based approvals')
        ) {
          statusOptions.push('Rejected');
        }
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter({
            name: 'approvalState',
            type: 'enumeration',
            label: I18n.t('lbl_approval'),
            value: I18n.t('lbl_unsubmitted'),
            options: statusOptions,
            fixedHeight: false,
            clearValue() {
              return {};
            },
          })
        );

        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'project',
              type: 'multiselect',
              label: I18n.t('lbl_project'),
              phaseCount: 0,
              getOptionLabel(option) {
                if (option.parent_id) {
                  return '';
                } // do not show phases as distinct projects
                let label = $filter('tkProjectName')(option);
                if (option.client != null) {
                  label = `${option.client}: ${label}`;
                }
                return label;
              },
              serialize() {
                if (this.value == null) {
                  return null;
                }
                return _.reduce(
                  this.value,
                  function (projectIds, project) {
                    if (!project.parent_id) {
                      projectIds.push(project.id);
                    }
                    return projectIds;
                  },
                  []
                );
              },
              deserialize(value) {
                if (!this.dataLoaded) {
                  this.deserializeOnLoad = value;
                  return;
                }
                if (value == null) {
                  this.value = null;
                  return;
                }
                this.value = [];
                _.each(value, (id) => {
                  const project = this.optionLookupById(id);
                  if (project && !project.parent_id) {
                    this.multiSelectAdd(project);
                  }
                });
              },
              multiSelectAdd(project) {
                // If value is null, then this project is already added, because null means All
                if (this.value != null) {
                  if (!_.contains(this.value, project)) {
                    this.value.push(project);
                  }
                  const phases = TKAssignables.phasesByParentId[project.id];
                  if (phases) {
                    _.each(phases, (phase) => {
                      if (!_.contains(this.value, phase)) {
                        this.value.push(phase);
                        ++this.phaseCount;
                      }
                    });
                  }
                  if (
                    this.value.length - this.phaseCount ===
                    this.options.length
                  ) {
                    // All options are selected so set to null
                    this.value = null;
                    this.phaseCount = 0;
                  }
                }
              },
              multiSelectRemove(option) {
                if (this.value == null) {
                  this.value = [];
                  this.phaseCount = 0;
                  _.each(this.options, (option) => {
                    this.value.push(option);
                    const phases = TKAssignables.phasesByParentId[option.id];
                    if (phases) {
                      _.each(phases, (phase) => {
                        this.value.push(phase);
                        ++this.phaseCount;
                      });
                    }
                  });
                }

                _.pull(this.value, option);
                const phases = TKAssignables.phasesByParentId[option.id];
                if (phases) {
                  _.each(phases, (phase) => {
                    _.pull(this.value, phase);
                    --this.phaseCount;
                  });
                }
              },
              optionLookupById(id) {
                return TKAssignables.allAssignablesById[id];
              },
            },
            function (values) {
              enterLoading();
              return TKAssignables.promise().then(function () {
                // Phases are not included as visible options, but they are
                // added or removed automatically when their parent project is
                // selected or deselected
                values.push(...Array.from(TKAssignables.projects || []));
                exitLoading();
              });
            }
          )
        );

        // Add `client` filter to `show` filter group
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'client',
              type: 'multiselect',
              label: I18n.t('lbl_client'),
              getOptionLabel(option) {
                return option.client;
              },
              serialize() {
                if (this.value != null) {
                  return _.map(this.value, (val) => _.pick(val, 'client'));
                } else {
                  return null;
                }
              },
              deserialize(value) {
                if (this.dataLoaded && value != null) {
                  this.value = _.map(value, ({ client }) =>
                    _.find(this.options, { client })
                  );
                } else if (!this.dataLoaded) {
                  this.deserializeOnLoad = value;
                } else {
                  this.value = null;
                }
              },
            },
            function (values) {
              enterLoading();
              return TKData.getRealProjects().then(function (projects) {
                const valuesByClient = {};
                _.each(projects, function (project) {
                  if (project.client) {
                    let value;
                    if (!valuesByClient[project.client]) {
                      value = {
                        client: project.client,
                        project_ids: [],
                      };
                      valuesByClient[project.client] = value;
                      values.push(value);
                    }
                    value = valuesByClient[project.client];
                    value.project_ids.push(project.id);
                  }
                });
                exitLoading();
              });
            }
          )
        );

        // Add `leaveType` filter to `show` filter group
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'leaveType',
              type: 'multiselect',
              label: I18n.t('lbl_leave_type'),
              getOptionLabel(option) {
                return option.name;
              },
            },
            function (values) {
              enterLoading();
              return TKData.getLeaveTypes().then(function (leaveTypes) {
                _.each(leaveTypes, function (leaveType) {
                  values.push(leaveType);
                });
                exitLoading();
              });
            }
          )
        );

        // Add `phaseName` filter to `show` filter group
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'phaseName',
              type: 'multiselect',
              label: I18n.t('lbl_phase_name'),
              getOptionLabel(option) {
                return option.phaseName;
              },
            },
            function (values) {
              const NON_PHASE_SPECIFIC = '[Non Phase Specific]';
              enterLoading();
              return TKAssignables.promise().then(function () {
                const valuesByPhasename = {};
                _.each(TKAssignables.allProjects, function (project) {
                  let value;
                  if (project.phase_name) {
                    if (!valuesByPhasename[project.phase_name]) {
                      value = {
                        phaseName: project.phase_name,
                        project_ids: [],
                      };
                      valuesByPhasename[project.phase_name] = value;
                      values.push(value);
                    }
                    value = valuesByPhasename[project.phase_name];
                    value.project_ids.push(project.id);
                  } else {
                    if (!valuesByPhasename[NON_PHASE_SPECIFIC]) {
                      value = {
                        phaseName: NON_PHASE_SPECIFIC,
                        project_ids: [],
                      };
                      valuesByPhasename[NON_PHASE_SPECIFIC] = value;
                      values.push(value);
                    }
                    value = valuesByPhasename[NON_PHASE_SPECIFIC];
                    value.project_ids.push(project.id);
                  }
                });
                exitLoading();
              });
            }
          )
        );

        filterGroups.push(showFilterGroup);

        // Create FilterSet from the defined filter groups
        $scope.filterSet =
          TKApprovalFilterManager.createFilterSet(filterGroups);

        const dateFilter = $scope.filterSet.getFilter('dateRange');
        const approvableTypeFilter =
          $scope.filterSet.getFilter('approvableType');

        enterLoading();
        $q.all([TKOrgSettings.promise]).then(function (results) {
          orgSettings = results[0];
          initialized = true;
          update();
          exitLoading();
        });

        let lastQueryRange = null;

        // Query data if necessary and update filters
        var update = function () {
          if (initialized) {
            const dates = dateFilter.getDates();

            const queryRange =
              dates.start.getTime() +
              ':' +
              dates.end.getTime() +
              ':' +
              $scope.fromDate +
              ':' +
              $scope.toDate +
              ':' +
              (approvableTypeFilter != null
                ? approvableTypeFilter.value
                : undefined);
            if (queryRange !== lastQueryRange) {
              // Date range has changed, query data
              lastQueryRange = queryRange;

              // Query time entries and expenses as needed
              const promises = [];

              const { enableTimeApprovals } = $scope;
              const { enableExpenseApprovals } = $scope;

              $scope.includeTime = false;
              if (enableTimeApprovals && !enableExpenseApprovals) {
                $scope.includeTime = true;
              }
              if (
                enableTimeApprovals &&
                enableExpenseApprovals &&
                approvableTypeFilter.value === I18n.t('lbl_time')
              ) {
                $scope.includeTime = true;
              }

              $scope.includeExpenses = false;
              if (enableExpenseApprovals && !enableTimeApprovals) {
                $scope.includeExpenses = true;
              }
              if (
                enableTimeApprovals &&
                enableExpenseApprovals &&
                approvableTypeFilter.value === I18n.t('lbl_expenses')
              ) {
                $scope.includeExpenses = true;
              }

              if ($scope.includeTime) {
                // fix start and end dates of filter
                const startDate = TKDateUtil.toStartOfWeek(dates.start);
                const endDate = TKDateUtil.toEndOfWeek(dates.end);

                if ($scope.fromDate && $scope.toDate) {
                  // get time entries for submitted date range
                  promises.push(
                    TKData.getTimeEntries(
                      $scope.userId,
                      $scope.fromDate,
                      $scope.toDate,
                      false
                    )
                  );
                  // get time entries for filter start
                  promises.push(
                    TKData.getTimeEntries(
                      $scope.userId,
                      startDate,
                      TKDateUtil.addDays($scope.fromDate, -1),
                      false
                    )
                  );
                  // get time entries for filter end
                  promises.push(
                    TKData.getTimeEntries(
                      $scope.userId,
                      TKDateUtil.addDays($scope.toDate, 1),
                      endDate,
                      false
                    )
                  );
                } else {
                  promises.push(
                    TKData.getTimeEntries(
                      $scope.userId,
                      startDate,
                      endDate,
                      false
                    )
                  );
                }
              } else {
                promises.push(
                  TKData.getExpenses($scope.userId, dates.start, dates.end)
                );
              }

              enterLoading();
              $scope.timeEntries = $scope.expenses = [];
              $q.all(promises).then(function (results) {
                if ($scope.includeTime) {
                  $scope.timeEntries = _.flatten(results);

                  // Remove 0hr time entries, we won't be submitting those
                  $scope.timeEntries = _.filter(
                    $scope.timeEntries,
                    (timeEntry) => timeEntry.hours > 0
                  );

                  // Ensure duplicates don't exist
                  $scope.timeEntries = _.uniq($scope.timeEntries, 'id');
                } else {
                  $scope.expenses = _.flatten(results);
                }

                // make array of all assignables
                let assignables = $scope.timeEntries.concat($scope.expenses);

                // filter out project assignables
                const assignablesForProjects = _.filter(
                  assignables,
                  (assignable) => assignable.assignable_type === 'Project'
                );

                // filter out leaveType assignables
                const assignablesForLeaveTypes = _.filter(
                  assignables,
                  (assignable) => assignable.assignable_type === 'LeaveType'
                );

                // Gather all unique project and leaveType ids
                const projectIds = _.tk.pluckUnique(
                  assignablesForProjects,
                  'assignable_id'
                );
                const leaveTypeIds = _.tk.pluckUnique(
                  assignablesForLeaveTypes,
                  'assignable_id'
                );

                $q.all([
                  TKAssignables.getTheseProjectsNow(projectIds),
                  TKAssignables.getTheseLeaveTypesNow(leaveTypeIds),
                ]).then(function (results) {
                  assignables = _.flatten(results);
                  if ($scope.includeTime) {
                    $scope.timeEntries = _.filter(
                      $scope.timeEntries,
                      function (timeEntry) {
                        const assignable = _.find(assignables, {
                          id: timeEntry.assignable_id,
                        });
                        if (assignable.deleted_at) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    );
                  } else {
                    $scope.expenses = _.filter(
                      $scope.expenses,
                      function (expenseItem) {
                        const assignable = _.find(assignables, {
                          id: expenseItem.assignable_id,
                        });
                        if (assignable.deleted_at) {
                          return false;
                        } else {
                          return true;
                        }
                      }
                    );
                  }

                  updateTimesheets();
                  exitLoading();
                });
              });
            } else {
              updateTimesheets();
            }
          }
        };

        const updateMessages = function () {
          switch ($scope.filterSet.getFilter('approvalState').value) {
            case I18n.t('lbl_unsubmitted'):
              $scope.otherTimesheetMessage = I18n.t('msg_other_unsubmitted');
              $scope.noTimesheetMessage = I18n.t('msg_no_time_sheets_matching');
              $scope.noExpensesMessage = I18n.t('msg_no_expenses_matching');
              break;
            case I18n.t('lbl_pending_approval'):
              $scope.otherTimesheetMessage = I18n.t('msg_other_sheets_pending');
              $scope.noTimesheetMessage = I18n.t('msg_no_time_sheets_pending');
              $scope.noExpensesMessage = I18n.t('msg_no_expenses_pending');
              break;
            case I18n.t('lbl_approved'):
              $scope.otherTimesheetMessage = I18n.t(
                'msg_other_time_sheets_approved'
              );
              $scope.noTimesheetMessage = I18n.t('msg_no_time_sheets_approved');
              $scope.noExpensesMessage = I18n.t('msg_no_expenses_approved');
              break;
            case I18n.t('lbl_rejected'):
              $scope.otherTimesheetMessage = I18n.t(
                'msg_other_time_sheets_rejected'
              );
              $scope.noTimesheetMessage = I18n.t('msg_no_time_sheets_rejected');
              $scope.noExpensesMessage = I18n.t('msg_no_expenses_rejected');
              break;
          }
        };

        var updateTimesheets = function () {
          $scope.timesheets = TKTimesheetFactory.createTimesheets(
            $scope.timeEntries,
            $scope.filterSet,
            orgSettings
          );
          $scope.expensesheets = TKTimesheetFactory.createExpensesheets(
            $scope.expenses,
            $scope.filterSet
          );
          $scope.currentTimesheets = [];
          $scope.otherTimesheets = [];
          updateMessages();
          _.each($scope.timesheets, function (timesheet) {
            if (timesheet.overlapsDateRange($scope.fromDate, $scope.toDate)) {
              $scope.currentTimesheets.push(timesheet);
            } else {
              $scope.otherTimesheets.push(timesheet);
            }
          });
        };

        return $scope.$watch('filterSet.groups', update, true);
      },
    };
  },
]);
