angular.module('app').directive('tkDropdown', [
  '$document',
  'TKCleanup',
  ($document, TKCleanup) => ({
    restrict: 'AE',
    templateUrl: 'ng-common/templates/dropdown',
    replace: true,

    scope: {
      selectionModel: '=',
      options: '=',
      optionText: '&',
      onSelect: '&',
    },

    link(scope, el) {
      const cleanup = TKCleanup.newCleanup(scope);

      function __mod__(a, b) {
        a = +a;
        b = +b;
        return ((a % b) + b) % b;
      }

      const handleKeydown = function (evnt) {
        const ENTER_KEY = 13;
        const UP_KEY = 38;
        const DOWN_KEY = 40;
        const ESC_KEY = 27;
        const preventDefaultAndApply = function () {
          evnt.preventDefault();
          scope.$apply();
        };
        switch (evnt.keyCode) {
          case UP_KEY:
            scope.highlight(-1);
            preventDefaultAndApply();
            break;
          case DOWN_KEY:
            scope.highlight(1);
            preventDefaultAndApply();
            break;
          case ENTER_KEY:
            scope.select(scope.highlightedOption);
            preventDefaultAndApply();
            break;
          case ESC_KEY:
            scope.optionsAreVisible = false;
            preventDefaultAndApply();
            break;
        }
      };

      scope.showOptions = function () {
        scope.optionsAreVisible = true;
      };

      scope.getOptionText = (option) => scope.optionText({ option });

      scope.select = function (option) {
        scope.selectionModel = option;
        scope.onSelect({ option });
        scope.optionsAreVisible = false;
      };

      scope.highlight = function (option) {
        if (_.isNumber(option)) {
          const currentIndex = scope.options.indexOf(scope.highlightedOption);
          const newIndex = __mod__(currentIndex + option, scope.options.length);
          scope.highlightedOption = scope.options[newIndex];
        } else {
          scope.highlightedOption = option;
        }
      };

      cleanup(
        scope.$watch('optionsAreVisible', function (newVal, oldVal) {
          if (newVal === true && !oldVal) {
            $document.on('keydown', handleKeydown);
          }
          if (newVal === false && oldVal === true) {
            scope.highlightedOption = null;
            $document.off('keydown', handleKeydown);
          }
        })
      );

      cleanup(function () {
        $document.off('keydown', handleKeydown);
      });
    },
  }),
]);
