/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app.services').factory('TKErrorMessenger', [
  '$rootScope',
  'TKAnalytics',
  function ($rootScope, TKAnalytics) {
    const TKErrorMessenger = {};

    TKErrorMessenger.newHandler = function (options) {
      if (options == null) {
        options = {};
      }
      const defaults = {
        notifyAnalytics: true,
        broadcast: 'tk-error',
      };
      options = _.assign(defaults, options);
      return function (error) {
        // TODO: notify analytics
        // TKAnalytics.error() if options.notifyAnalytics
        if (options.broadcast) {
          let data, eventName;
          if (_.isString(options.broadcast)) {
            eventName = options.broadcast;
          } else {
            ({ eventName, data } = options.broadcast);
          }
          data = error;
          $rootScope.$broadcast(eventName, data);
        }
        return typeof options.callback === 'function'
          ? options.callback(error)
          : undefined;
      };
    };

    return TKErrorMessenger;
  },
]);
