angular.module('app').filter('tkFixedNumber', function () {
  let localizeSeparator;
  const { separator } = I18n.lookup('number').format;

  if (separator === '.') {
    localizeSeparator = _.identity;
  } else {
    localizeSeparator = (numberString) => numberString.replace(/\./, separator);
  }

  const trimTrailingZero = (numberString) => numberString.replace(/0$/, '');

  const formatNumber = (number) =>
    localizeSeparator(trimTrailingZero(number.toFixed(2)));

  return function (number) {
    number = parseFloat(number);
    if (isNaN(number)) {
      return '';
    } else {
      return formatNumber(number);
    }
  };
});
