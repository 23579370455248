angular.module('app').directive('tkDateRangePicker', [
  '$log',
  ($log) => ({
    restrict: 'AE',
    transclude: true,
    templateUrl: 'ng-common/templates/dateRangePicker',

    scope: {
      startDateModel: '=',
      endDateModel: '=',
      onSubmit: '&?',
    },

    controller: function ($scope, $element) {
      $scope.I18n = I18n;
    },
  }),
]);
