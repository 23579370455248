angular.module('app').directive('tkEnforceMaxlength', [
  '$parse',
  ($parse) => ({
    link(scope, element, attributes) {
      const getMaxlength = $parse(attributes.tkEnforceMaxlength);
      const onExceedMaxlength = $parse(attributes.onExceedMaxlength);
      const ngModel = $parse(attributes.ngModel);

      const handleKeypress = function (e) {
        if (e.target.value.length >= getMaxlength(scope)) {
          e.preventDefault();
          return false;
        }
      };

      const handlePaste = function (e) {
        const { clipboardData } = e.originalEvent;
        const pastingText = clipboardData.getData('text/plain');
        const { selectionStart, selectionEnd } = e.target;
        const currentText = e.target.value;

        // Accounting for pasting text being inserted into
        // existing, potentially highlighted text
        const text =
          currentText.substring(0, selectionStart) +
          pastingText +
          currentText.substring(selectionEnd);

        const maxlength = getMaxlength(scope);
        if (text.length > maxlength) {
          const truncatedText = text.substring(0, maxlength);
          e.preventDefault();
          e.target.value = truncatedText;
          scope.$apply(function () {
            ngModel.assign(scope, truncatedText);
            onExceedMaxlength(scope);
          });
        }
      };

      element.on('keypress', handleKeypress);
      element.on('paste', handlePaste);

      element.on('$destroy', function () {
        element.off('keypress', handleKeypress);
        element.off('paste', handlePaste);
      });
    },
  }),
]);
