/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
_.tk = {};

// lodash v3 changed the name of "createCallback" to "callback".
// this line ensures we are safe if benchpress-server still has v2.4.1
// TODO: remove this line once benchpress-server has v3+
_.tk.callback = _.callback || _.createCallback;

_.negate = (fn) =>
  function () {
    return !fn(...arguments);
  };

// same return value as _.unique(_.pluck(...))
// but uses a more efficient algorithm.
_.tk.pluckUnique = function (array, attribute, thisArg) {
  if (!array || !array.length) {
    return [];
  }
  const callback = _.tk.callback(attribute, thisArg);
  return array.reduce(function (uniqueValues, value) {
    value = callback(value);
    if (uniqueValues.indexOf(value) === -1) {
      uniqueValues.push(value);
    }
    return uniqueValues;
  }, []);
};

_.tk.isInteger = (value) => _.isNumber(value) && value === (value | 0);

_.tk.sum = function (array, callback, thisArg) {
  if (!array || !array.length) {
    return 0;
  }
  callback = _.tk.callback(callback, thisArg);
  return array.reduce((s, value) => s + callback(value), 0);
};

_.tk.queryStringToObject = (query) =>
  query.split('&').reduce(function (object, pair) {
    const pieces = pair.split(/(\=)/); // split with capture
    const key = pieces[0];
    // pieces[1] is '='
    const val = pieces.slice(2).join(''); // account for possible '=' in value
    object[key] = val;
    return object;
  }, {});

_.tk.objectToQueryString = (object) =>
  _.map(object, (val, key) => `${key}=${val}`).join('&');

_.tk.freezeDeep = function (object) {
  _.each(object, function (obj) {
    if (_.isObject(obj)) {
      _.tk.freezeDeep(obj);
    }
  });
  return Object.freeze(object);
};

// Immediately calls provided function with any provided arguments.
// Useful for iterating over an array of functions.
_.tk.callFn = (fn, ...args) => fn.apply(null, args);

_.tk.add = (a, b) => a + b;
_.tk.subtract = (a, b) => a - b;
_.tk.multiply = (a, b) => a * b;
_.tk.divide = (a, b) => a / b;
_.tk.integerDivide = (a, b) => Math.floor(a / b);
_.tk.modulo = (a, b) => __mod__(a, b);

if (+_.VERSION[0] >= 3) {
  _.tk.chunkRight = (arrayOrString, size) =>
    _.chunk(_.toArray(arrayOrString).reverse(), size)
      .map(_.method('reverse'))
      .reverse();
  _.tk.limitArgsTo = _.curryRight(_.ary, 2);
  _.tk.ignoreArgs = _.tk.limitArgsTo(0);
  _.tk.firstArgOnly = _.tk.limitArgsTo(1);

  // Creates a function that will inject provided arguments into whatever
  // function is passed in. Helpful when iterating over an array of functions.
  // Example:
  // numbersInjector = _.tk.injector(1, 2)
  // numbersInjector(_.add) # => 3
  _.tk.injector = _.flow(
    _.partial(_.partialRight, _.tk.callFn),
    _.tk.firstArgOnly
  );

  // Like _.flow, but accepts arrays/objects of functions which are all
  // passed the same value, returning the mapped array/object of return values.
  // Examples:

  // repeatCases = _.tk.flowMap(
  //                   # These two functions are each given the first argument
  //                   [_.identity, _.identity],
  //                   # The array of return values is passed to the next function
  //                   _.method('join', ' '),
  //                   # The return value of the above function is passed
  //                   # to each of the following functions
  //                   {
  //                     camel: _.camelCase,
  //                     snake: _.snakeCase
  //                   }
  //                   # The final return value is an object conaining the two
  //                   # return values from the above function
  //                 )

  // repeatCases('hello') # => { camel: 'helloHello', snake: 'hello_hello' }
  //
  //                       # Using `_.property` shorthand
  // findOwner = _.tk.flowMap({ id: 'user_id' }, _.partial(_.find, users))
  // findOwner({ id: 42, user_id: 56, title: 'hello' }) # => { id: 56, name: 'Bob' }

  // subtract = (a, b) -> a - b
  // divide = (a, b) -> a / b
  // mod = (a, b) -> a % b

  // divideBy = _.curryRight(divide)
  // modOf = _.curryRight(mod)

  // timeSince = _.tk.flowMap(
  //   [_.now, _.method('getTime')],
  //   _.spread(subtract),
  //   {
  //     days: _.flow(divideBy(24 * 60 * 60 * 1000), _.floor),
  //     hours: _.flow(divideBy(60 * 60 * 1000), _.floor, modOf(24))
  //     minutes: _.flow(divideBy(60 * 1000), _.floor, modOf(60))
  //     seconds: _.flow(divideBy(1000), _.floor, modOf(60))
  //     s: _.constant((n) -> if n is 1 then '' else 's')
  //   },
  //   _.template('<%= days %> day<%= s(days) %>, ' +
  //              '<%= hours %> hour<%= s(hours) %>, ' +
  //              '<%= minutes %> minute<%= s(minutes) %>, and ' +
  //              '<%= seconds %> second<%= s(seconds) %>')
  // )

  // timeSince(new Date(_.now() - 30.457 * 60 * 60 * 1000)) # => "1 day, 6 hours, 27 minutes, and 25 seconds"
  _.tk.flowMap = _.restParam(function (sequence) {
    var makeMap = function (object) {
      let map;
      if (_.isArray(object)) {
        ({ map } = _);
      } else if (_.isPlainObject(object)) {
        map = _.mapValues;
      } else {
        return _.callback(object);
      }
      object = map(object, function (fn) {
        if (!_.isFunction(fn)) {
          fn = makeMap(fn);
        }
        return fn;
      });
      return _.flow(_.tk.injector, _.partial(map, object));
    };

    sequence = _.map(sequence, function (fn) {
      if (!_.isFunction(fn)) {
        fn = makeMap(fn);
      }
      return fn;
    });
    return _.spread(_.flow)(sequence);
  });
}

function __mod__(a, b) {
  a = +a;
  b = +b;
  return ((a % b) + b) % b;
}
