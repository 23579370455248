/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app.services').factory('TKTimeTrackerTimerManager', [
  '$log',
  '$rootScope',
  'TKLocalStorage',
  'TKAnalytics',
  function ($log, $rootScope, TKLocalStorage, TKAnalytics) {
    let ls = undefined;
    let timer = undefined;

    const init = function () {
      ls = TKLocalStorage.createResource('tk-timeTracker-timers');
      return (timer = ls.get('timer'));
    };

    const getRunningTimer = () => timer;

    const hasTimerRunning = (timeEntryId) =>
      timer && timer.timeEntryId === timeEntryId;

    const startTimer = function (timeEntryId, timeEntryDate) {
      if (timer && timer.timeEntryId !== timeEntryId) {
        // We have another timer running. Stop it, and fire an event first
        const elapsed = Date.now() - timer.startedAt;
        $rootScope.$emit('tk-timer-stopped', {
          timeEntryId: timer.timeEntryId,
          elapsed,
        });
      }

      timer = {
        timeEntryId,
        timeEntryDate,
        startedAt: Date.now(),
      };
      ls.set('timer', timer);
      TKAnalytics.timeEntry.startTimer();
    };

    const stopTimer = function (timeEntryId) {
      if (timer && timer.timeEntryId === timeEntryId) {
        const elapsed = Date.now() - timer.startedAt;
        timer = undefined;
        ls.set('timer', undefined);
        return elapsed;
      } else {
        $log.warn('attemting to stop an invalid timer');
        // TODO throw?
        return;
      }
      TKAnalytics.timeEntry.stopTimer();
    };

    const manager = {
      getRunningTimer,
      hasTimerRunning,
      startTimer,
      stopTimer,
    };

    init();

    return manager;
  },
]);
