/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Experimental service to provide:
// - interface to 10kft app
// - message bus between 10kft and new-app

angular.module('app.services').factory('TKBridge', [
  '$q',
  '$log',
  'TKSession',

  function ($q, $log, TKSession) {
    const deferred = $q.defer();

    const me = gData.getMe();

    const data = {
      user_id: me.id,
      // exclude optional attributes
      // account_id: undefined,
      // account_name: undefined,
      // account_status: undefined,
      name: me.displayName,
    };

    TKSession.initialize(data).then((response) => deferred.resolve());

    const bridge = {
      signin: deferred.promise,
    };

    return bridge;
  },
]);
