angular.module('app.services').factory('TKUsers', [
  '$q',
  'TKSession',
  'TKData',
  'TKLegacyApi',
  function ($q, TKSession, TKData, TKLegacyApi) {
    const TKUsers = {};

    const isActive = (user) =>
      user.has_login || user.login || user.invitation_pending;

    const isResourceOnly = _.negate(isActive);

    TKUsers.getUser = (userId) => TKData.getUser(userId);

    TKUsers.getCurrentUser = function () {
      if (TKSession.data) {
        return TKUsers.getUser(TKSession.data.user_id);
      }
    };

    let cachedUsersPromise = null;
    TKUsers.getAllUsers = function () {
      const deferred = $q.defer();
      const resolve = _.bind(deferred.resolve, deferred);
      const reject = _.bind(deferred.reject, deferred);
      // Try using v1 API first
      TKData.getUsers()
        .then(resolve)
        .catch(function (response) {
          // for expired trials, use legacy API
          if (response.data.message === 'account locked') {
            if (!cachedUsersPromise) {
              cachedUsersPromise = TKLegacyApi.getUsers().then(
                _.property('data')
              );
            }
            cachedUsersPromise.then(resolve).catch(reject);
          } else {
            reject(response);
          }
        });
      return deferred.promise;
    };

    TKUsers.getAllActiveUsers = () =>
      TKUsers.getAllUsers().then((users) => _.filter(users, isActive));

    TKUsers.getAllResourceOnlyUsers = () =>
      TKUsers.getAllUsers().then((users) => _.filter(users, isResourceOnly));

    TKUsers.getUserCounts = () =>
      TKUsers.getAllUsers().then(function (users) {
        const total = users.length;
        const active = _.filter(users, isActive).length;
        const resourceOnly = total - active;
        return {
          total,
          active,
          resourceOnly,
        };
      });

    TKUsers.getPeopleTags = () =>
      TKUsers.getAllUsers().then(function (users) {
        TKUsers.tags = [];
        TKUsers.tagsById = {};
        TKUsers.usersByTagId = {};
        _.each(users, function (user) {
          if (!user.tags) {
            return;
          }
          _.each(user.tags.data, function (tag) {
            if (!_.find(TKUsers.tags, { id: tag.id })) {
              TKUsers.tags.push(tag);
            }
            TKUsers.tagsById[tag.id] = tag;
            (TKUsers.usersByTagId[tag.id] =
              TKUsers.usersByTagId[tag.id] || []).push(user);
          });
        });
        return TKUsers.tags;
      });

    return TKUsers;
  },
]);
