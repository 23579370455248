/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkCheckChange', [
  '$parse',
  function ($parse) {
    let directiveDefinitionObject;
    return (directiveDefinitionObject = {
      restrict: 'A',
      compile(element, attr) {
        const fn = $parse(attr.tkCheckChange);
        return (scope, element, attr) =>
          element.on('change', (event) =>
            scope.$apply(() =>
              fn(scope, { $event: event, $checked: event.target.checked })
            )
          );
      },
    });
  },
]);
