angular.module('app').directive('tkFocusIf', [
  '$timeout',
  'TKCleanup',
  ($timeout, TKCleanup) => ({
    restrict: 'A',

    link(scope, element, attrs) {
      const cleanup = TKCleanup.newCleanup(scope);
      cleanup(
        scope.$watch(attrs.tkFocusIf, function (current, previous) {
          if (current === true) {
            $timeout(function () {
              element[0].focus();
            });
          } else if (current === false && previous === true) {
            $timeout(function () {
              element[0].blur();
            });
          }
        })
      );
    },
  }),
]);
