/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkWeekviewNotes', [
  '$timeout',
  'TKDateUtil',
  'TKCleanup',
  ($timeout, TKDateUtil, TKCleanup) => ({
    restrict: 'E',
    templateUrl: 'ng-approvals/templates/timeTrackerWeekviewNotes',
    replace: true,

    scope: {
      editState: '=',
      readOnly: '&',
      readOnlyText: '&',
      pointToIndex: '&',
      visibleIf: '&',
      focusIf: '&',
      userId: '=',
      onEnter: '&',
      onTab: '&',
      onShiftTab: '&',
      onUp: '&',
      onEscape: '&',
    },

    controller: function ($scope, $element) {
      let date, editNotes, i, row;
      const DAY_INDEX_PERCENT = 1 / 6;
      const MAX_PERCENT = 0.393; // This can easily be broken. TODO come up with a better solution.
      const ARROW_MARGIN_ADJUSTMENT = DAY_INDEX_PERCENT * MAX_PERCENT * 100;

      const ENTER_KEYCODE = 13;
      const TAB_KEYCODE = 9;
      const UP_KEYCODE = 38;
      const ESCAPE_KEYCODE = 27;

      let timeoutPromise = null;

      $scope.I18n = I18n;

      // Because of the way $scope.visibleIf determines visibility, we want to wait briefly to
      // make sure the visibility really is false. Otherwise, this would jitter in and out when
      // clicking between cells.
      const newVisibilityFunction = (
        (timeoutInProgress, previousVisibleIf) =>
        (valueWhenVisible, valueWhenNotVisible) =>
          function () {
            if (timeoutInProgress) {
              return valueWhenVisible;
            }
            if ($scope.savePending || $scope.visibleIf()) {
              previousVisibleIf = true;
              return valueWhenVisible;
            } else if (previousVisibleIf) {
              previousVisibleIf = false;
              timeoutInProgress = true;
              timeoutPromise = $timeout(function () {
                timeoutInProgress = false;
              }, 100);
              return valueWhenVisible;
            } else {
              return valueWhenNotVisible;
            }
          }
      )(false, false);

      $scope.savePending = false;

      $scope.getHeight = newVisibilityFunction('46px', '0');

      $scope.getArrowMarginTop = newVisibilityFunction('-24px', '0');

      $scope.getArrowHeight = newVisibilityFunction('20px', '0');

      $scope.focusIfAndVisibleIf = (function () {
        const visibleIf = newVisibilityFunction(true, false);
        return () => $scope.focusIf() && visibleIf();
      })();

      const getWeekdayIndex = ((index) =>
        function () {
          const pointToIndex = $scope.pointToIndex();
          if (pointToIndex != null) {
            index = pointToIndex;
          }
          return index;
        })(0);

      $scope.getArrowMarginLeft = () =>
        `${getWeekdayIndex() * ARROW_MARGIN_ADJUSTMENT}%`;

      $scope.saveNotes = function () {
        $scope.charLimitMessageIsVisible = false;
        if (!$scope.savePending) {
          return;
        }
        if (row && date && i != null && $scope.notesModel !== editNotes) {
          window.clientEventLogger &&
            window.clientEventLogger.push({
              eventTimestamp: Date.now(),
              eventData: {
                eventType: 'Inserted',
                featureArea: 'RM Time Tracker',
                description:
                  'A user has entered a time entry note in the week view',
                objectName: 'Time entry note',
                objectType: 'Input',
                viewName: 'Time tracker week view',
              },
            });
          if (row.hasTimeEntry(date, i)) {
            row.setTimeEntry(date, i, { notes: $scope.notesModel });
          } else {
            row.createTimeEntry($scope.userId, date, i, {
              notes: $scope.notesModel,
            });
          }
        }
        $scope.savePending = false;
      };

      const getCellData = function () {
        let nextDate, previousDate;
        const weekdayIndex = getWeekdayIndex();
        if (weekdayIndex === 6) {
          nextDate = TKDateUtil.addDays(date, -6);
        } else if (weekdayIndex === 0) {
          previousDate = TKDateUtil.addDays(date, 6);
        }

        previousDate = previousDate || TKDateUtil.addDays(date, -1);
        nextDate = nextDate || TKDateUtil.addDays(date, 1);
        return {
          row,
          previousDate,
          date,
          nextDate,
          i,
        };
      };

      $scope.handleKeydown = function ($event) {
        switch ($event.keyCode) {
          case ENTER_KEYCODE:
            $scope.saveNotes();
            $element.blur();
            $scope.onEnter(getCellData());
            break;
          case TAB_KEYCODE:
            $event.preventDefault();
            $scope.saveNotes();
            if ($event.shiftKey) {
              $scope.onShiftTab(getCellData());
            } else {
              $scope.onTab(getCellData());
            }
            break;
          case UP_KEYCODE:
            $scope.saveNotes();
            $scope.onUp(getCellData());
            break;
          case ESCAPE_KEYCODE:
            $scope.savePending = false;
            $element.blur();
            $scope.onEscape(getCellData());
            break;
        }
      };

      const cleanup = TKCleanup.newCleanup($scope);

      cleanup(function () {
        $timeout.cancel(timeoutPromise);
      });

      if ($scope.editState) {
        ({ row, i, date, editNotes } = $scope.editState);
      }

      cleanup(
        $scope.$watch('editState', function () {
          if ($scope.editState) {
            // We hold on to the most recent editState data
            // so this still works even though `endEdit`
            // sets editState to null
            ({ row, i, date, editNotes } = $scope.editState);
            $scope.notesModel = editNotes;
          }
        })
      );
    },
  }),
]);
