/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Some common functionality related to approvables
angular.module('app.services').factory('TKApprovables', [
  '$log',
  '$q',
  'TKDateUtil',
  'TKAssignables',
  function ($log, $q, TKDateUtil, TKAssignables) {
    const factory = {};

    factory.approvablesStatus = function (approvables) {
      const status = {
        approvables,
        approved: [],
        pending: [],
        unsubmitted: [],
      };

      _.each(approvables, function (approvable) {
        // Not ideal but this is a way to exclude time entry suggestions that may be mixed
        // in with other approvables
        if (
          (approvable.scheduled_hours != null && approvable.hours == null) ||
          approvable.hours === 0
        ) {
          return;
        }

        // Ignore approvables that are associated with an archived project
        const projectOrLeaveType =
          TKAssignables.allAssignablesById[approvable.assignable_id];
        if (!projectOrLeaveType || projectOrLeaveType.deleted_at) {
          return;
        }

        const entryStatus = __guard__(
          approvable.approvals != null
            ? approvable.approvals.data[0]
            : undefined,
          (x) => x.status
        );

        switch (entryStatus) {
          case 'approved':
            return status.approved.push(approvable);
          case 'pending':
            return status.pending.push(approvable);
          case 'rejected':
          case undefined:
          case null:
            return status.unsubmitted.push(approvable);
          default:
            throw 'Unrecognized';
        }
      });

      status.state = (() => {
        switch (false) {
          case !(status.pending.length > 0) || status.unsubmitted.length !== 0:
            return 'pending';
          case !(status.pending.length > 0):
            return 'resubmit';
          case !(status.unsubmitted.length > 0):
            return 'submit';
          case !(status.approved.length > 0):
            return 'approved';
          default:
            return 'none';
        }
      })();

      return status;
    };

    return factory;
  },
]);
