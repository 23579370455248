/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Beware: There is a lot of code duplication between this directive and tkSubmitApprovablesPage.
// If you change code here, you may also need to change it in there as well.
angular.module('app').directive('tkApprovalPage', [
  '$log',
  '$q',
  '$filter',
  'TKData',
  'TKDateUtil',
  'TKOrgSettings',
  'TKAssignables',
  'TKUsers',
  'TKApprovals',
  'TKPermissions',
  'TKTimesheetFactory',
  'TKApprovalFilterManager',
  function (
    $log,
    $q,
    $filter,
    TKData,
    TKDateUtil,
    TKOrgSettings,
    TKAssignables,
    TKUsers,
    TKApprovals,
    TKPermissions,
    TKTimesheetFactory,
    TKApprovalFilterManager
  ) {
    return {
      restrict: 'E',
      templateUrl: 'ng-approvals/templates/approvalPage',
      scope: {
        enableExpenseApprovals: '=',
        enableTimeApprovals: '=',
        userId: '=',
        filter: '=',
      },
      controller: function ($scope) {
        $scope.I18n = I18n;
        // Set the page title based on enabled features
        if ($scope.enableExpenseApprovals && $scope.enableTimeApprovals) {
          $scope.pageTitle = I18n.t('lbl_approve_time_and_expenses');
        } else if ($scope.enableExpenseApprovals) {
          $scope.pageTitle = I18n.t('lbl_approve_expenses');
        } else if ($scope.enableTimeApprovals) {
          $scope.pageTitle = I18n.t('lbl_approve_time');
        }

        $scope.timesheets = null;
        $scope.expensesheets = null;
        $scope.dateRangePickerIsVisible = false;
        $scope.loading = 0;
        const enterLoading = function () {
          ++$scope.loading;
        };
        const exitLoading = function () {
          --$scope.loading;
        };

        const approvableType = (() => {
          switch ($scope.type) {
            case 'expenses':
              return I18n.t('lbl_expenses');
            default:
              return I18n.t('lbl_time');
          }
        })();

        let orgSettings = null;
        let clients = null;
        let disciplines = null;

        let initialized = false;

        // Empty view model for filter name
        $scope.filterName = '';

        // Empty filter groups array
        const filterGroups = [];

        // Create and configure the `time frame` filter group
        const timeFrameFilterGroup = TKApprovalFilterManager.createFilterGroup(
          I18n.t('lbl_time_frame')
        );
        timeFrameFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter({
            name: 'dateRange',
            type: 'dateRange',
            value: I18n.t('lbl_this_week'),
            options: [
              I18n.t('lbl_last_30_days'),
              I18n.t('lbl_this_week'),
              I18n.t('lbl_last_week'),
              I18n.t('lbl_this_month'),
              I18n.t('lbl_last_month'),
            ],
            fixedHeight: false,
            getValue() {
              if (_.isString(this.value)) {
                return this.value;
              }
              return TKDateUtil.dateRangeString(this.value);
            },
            getDates() {
              let end, start;
              const dates = {};
              // thisWeek = orgSettings.firstWorkdayOfWeek(new Date())
              const now = new Date();
              const today = TKDateUtil.toStartOfDay(now);
              if (_.isObject(this.value)) {
                ({ start, end } = this.value);
                if (start && !end) {
                  if (start <= today) {
                    this.value.end = today;
                  } else {
                    this.value.end = start;
                  }
                } else if (end && !start) {
                  if (today <= end) {
                    this.value.start = today;
                  } else {
                    this.value.start = end;
                  }
                } else if (!start && !end) {
                  this.value.start = this.value.end = today;
                }
                return this.value;
              }
              // switch (this.value) {
              //   case I18n.t('lbl_last_30_days'):
              //     dates.start = TKDateUtil.addDays(today, -30);
              //     dates.end = today;
              //     break;
              //   case I18n.t('lbl_this_week'):
              //     dates.start = TKOrgSettings.firstWorkdayOfWeek(today);
              //     dates.end = TKDateUtil.addDays(dates.start, 6);
              //     break;
              //   case I18n.t('lbl_last_week'):
              //     dates.start = TKOrgSettings.firstWorkdayOfWeek(
              //       TKDateUtil.addDays(today, -7)
              //     );
              //     dates.end = TKDateUtil.addDays(dates.start, 6);
              //     break;
              //   case I18n.t('lbl_this_month'):
              //     dates.start = TKDateUtil.toStartOfMonth(today);
              //     dates.end = TKDateUtil.toEndOfMonth(today);
              //     break;
              //   case I18n.t('lbl_last_month'):
              //     dates.start = TKDateUtil.toStartOfMonth(
              //       TKDateUtil.addDays(TKDateUtil.toStartOfMonth(today), -1)
              //     );
              //     dates.end = TKDateUtil.toEndOfMonth(dates.start);
              //     break;
              // }
              return dates;
            },
          })
        );

        filterGroups.push(timeFrameFilterGroup);

        // Create and configure the `type` filter group if both time and expenses are enabled
        if ($scope.enableExpenseApprovals && $scope.enableTimeApprovals) {
          const typeFilterGroup = TKApprovalFilterManager.createFilterGroup(
            I18n.t('lbl_type')
          );
          typeFilterGroup.addFilter(
            TKApprovalFilterManager.createFilter({
              name: 'approvableType',
              type: 'enumeration',
              value: approvableType,
              options: [I18n.t('lbl_time'), I18n.t('lbl_expenses')],
              fixedHeight: false,
            })
          );
          filterGroups.push(typeFilterGroup);
        }

        // Create and configure the `show` filter group
        const showFilterGroup = TKApprovalFilterManager.createFilterGroup(
          I18n.t('lbl_show')
        );
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter({
            name: 'approvalState',
            type: 'enumeration',
            label: I18n.t('lbl_approval'),
            value: I18n.t('lbl_pending_approval'),
            options: [
              I18n.t('lbl_unsubmitted'),
              I18n.t('lbl_pending_approval'),
              I18n.t('lbl_approved'),
            ],
            fixedHeight: false,
            clearValue() {
              return {};
            },
          })
        );

        // Add `project` filter to `show` filter group
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'project',
              type: 'multiselect',
              label: I18n.t('lbl_project'),
              phaseCount: 0,
              getOptionLabel(option) {
                if (option.parent_id) {
                  return '';
                } // do not show phases as distinct projects
                let label = $filter('tkProjectName')(option);
                if (option.client != null) {
                  label = `${option.client}: ${label}`;
                }
                return label;
              },
              serialize() {
                if (this.value == null) {
                  return null;
                }
                return _.reduce(
                  this.value,
                  function (projectIds, project) {
                    if (!project.parent_id) {
                      projectIds.push(project.id);
                    }
                    return projectIds;
                  },
                  []
                );
              },
              deserialize(value) {
                if (!this.dataLoaded) {
                  this.deserializeOnLoad = value;
                  return;
                }
                if (value == null) {
                  this.value = null;
                  return;
                }
                this.value = [];
                _.each(value, (id) => {
                  const project = this.optionLookupById(id);
                  if (project && !project.parent_id) {
                    this.multiSelectAdd(project);
                  }
                });
              },
              multiSelectAdd(project) {
                // If value is null, then this project is already added, because null means All
                if (this.value != null) {
                  if (!_.contains(this.value, project)) {
                    this.value.push(project);
                  }
                  const phases = TKAssignables.phasesByParentId[project.id];
                  if (phases) {
                    _.each(phases, (phase) => {
                      if (!_.contains(this.value, phase)) {
                        this.value.push(phase);
                        ++this.phaseCount;
                      }
                    });
                  }
                  if (
                    this.value.length - this.phaseCount ===
                    this.options.length
                  ) {
                    // All options are selected so set to null
                    this.value = null;
                    this.phaseCount = 0;
                  }
                }
              },
              multiSelectRemove(option) {
                if (this.value == null) {
                  this.value = [];
                  this.phaseCount = 0;
                  _.each(this.options, (option) => {
                    this.value.push(option);
                    const phases = TKAssignables.phasesByParentId[option.id];
                    if (phases) {
                      _.each(phases, (phase) => {
                        this.value.push(phase);
                        ++this.phaseCount;
                      });
                    }
                  });
                }

                _.pull(this.value, option);
                const phases = TKAssignables.phasesByParentId[option.id];
                if (phases) {
                  _.each(phases, (phase) => {
                    _.pull(this.value, phase);
                    --this.phaseCount;
                  });
                }
              },
              optionLookupById(id) {
                return TKAssignables.allAssignablesById[id];
              },
            },
            function (values) {
              enterLoading();
              return TKAssignables.promise().then(function () {
                // Phases are not included as visible options, but they are
                // added or removed automatically when their parent project is
                // selected or deselected
                values.push(...Array.from(TKAssignables.projects || []));
                exitLoading();
              });
            }
          )
        );

        // Add `teamMember` filter to `show` filter group
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'teamMember',
              type: 'multiselect',
              label: I18n.t('lbl_team_member'),
              getOptionLabel(option) {
                return option.display_name;
              },
            },
            function (values) {
              enterLoading();
              return TKData.getUsers().then(function (users) {
                values.push(...Array.from(users || []));
                exitLoading();
              });
            }
          )
        );
        // Add `client` filter to `show` filter group
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'client',
              type: 'multiselect',
              label: I18n.t('lbl_client'),
              getOptionLabel(option) {
                return option.client;
              },
              serialize() {
                if (this.value != null) {
                  return _.map(this.value, (val) => _.pick(val, 'client'));
                } else {
                  return null;
                }
              },
              deserialize(value) {
                if (this.dataLoaded && value != null) {
                  this.value = _.compact(
                    _.map(value, ({ client }) =>
                      _.find(this.options, { client })
                    )
                  );
                } else if (!this.dataLoaded) {
                  this.deserializeOnLoad = value;
                } else {
                  this.value = null;
                }
              },
            },
            function (values) {
              enterLoading();
              return TKAssignables.promise().then(function () {
                const valuesByClient = {};
                _.each(TKAssignables.allProjects, function (project) {
                  if (project.client) {
                    let value;
                    if (!valuesByClient[project.client]) {
                      value = {
                        client: project.client,
                        project_ids: [],
                      };
                      valuesByClient[project.client] = value;
                      values.push(value);
                    }
                    value = valuesByClient[project.client];
                    value.project_ids.push(project.id);
                  }
                });
                exitLoading();
              });
            }
          )
        );

        // Add `discipline` filter to `show` filter group
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'discipline',
              type: 'multiselect',
              label: I18n.t('lbl_discipline'),
              getOptionLabel(option) {
                return option.discipline;
              },
            },
            function (values) {
              const promises = [TKData.getUsers(), TKData.getDisciplines()];
              enterLoading();
              return $q.all(promises).then(function (...args) {
                let users;
                let disciplines;
                [users, disciplines] = Array.from(args[0]);
                const valuesByDiscipline = {};
                _.each(disciplines, function (discipline) {
                  discipline = discipline.value;
                  if (!valuesByDiscipline[discipline]) {
                    const value = {
                      discipline,
                      user_ids: [],
                    };
                    valuesByDiscipline[discipline] = value;
                    values.push(value);
                  }
                });
                _.each(users, function (user) {
                  if (user.discipline) {
                    const value = valuesByDiscipline[user.discipline];
                    if (value) {
                      value.user_ids.push(user.id);
                    }
                  }
                });
                exitLoading();
              });
            }
          )
        );

        // Add `leaveType` filter to `show` filter group
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'leaveType',
              type: 'multiselect',
              label: I18n.t('lbl_leave_type'),
              getOptionLabel(option) {
                return option.name;
              },
            },
            function (values) {
              enterLoading();
              return TKAssignables.promise().then(function () {
                values.push(...Array.from(TKAssignables.leaveTypes || []));
                exitLoading();
              });
            }
          )
        );

        // Add `phaseName` filter to `show` filter group
        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'phaseName',
              type: 'multiselect',
              label: I18n.t('lbl_phase_name'),
              getOptionLabel(option) {
                return option.phaseName;
              },
            },
            function (values) {
              const NON_PHASE_SPECIFIC = '[Non Phase Specific]';
              enterLoading();
              return TKAssignables.promise().then(function () {
                const valuesByPhasename = {};
                _.each(TKAssignables.allProjects, function (project) {
                  let value;
                  if (project.phase_name) {
                    if (!valuesByPhasename[project.phase_name]) {
                      value = {
                        phaseName: project.phase_name,
                        project_ids: [],
                      };
                      valuesByPhasename[project.phase_name] = value;
                      values.push(value);
                    }
                    value = valuesByPhasename[project.phase_name];
                    value.project_ids.push(project.id);
                  } else {
                    if (!valuesByPhasename[NON_PHASE_SPECIFIC]) {
                      value = {
                        phaseName: NON_PHASE_SPECIFIC,
                        project_ids: [],
                      };
                      valuesByPhasename[NON_PHASE_SPECIFIC] = value;
                      values.push(value);
                    }
                    value = valuesByPhasename[NON_PHASE_SPECIFIC];
                    value.project_ids.push(project.id);
                  }
                });
                exitLoading();
              });
            }
          )
        );

        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'projectTag',
              type: 'multiselect',
              label: I18n.t('lbl_project_tags'),
              getOptionLabel(option) {
                return option.value;
              },
              multiSelectAdd(tag) {
                // If value is null, then this tag is already added, because null means All
                if (this.value != null) {
                  if (!_.contains(this.value, tag)) {
                    this.value.push(tag);
                  }
                  if (this.value.length === this.options.length) {
                    // All options are selected so set to null
                    this.value = null;
                  }
                }
              },
              multiSelectRemove(option) {
                if (this.value == null) {
                  this.value = _.clone(this.options);
                }
                _.pull(this.value, option);
              },
              optionLookupById(id) {
                return TKAssignables.tagsById[id];
              },
            },
            function (values) {
              enterLoading();
              return TKAssignables.promise().then(function () {
                values.push(...Array.from(TKAssignables.tags || []));
                exitLoading();
              });
            }
          )
        );

        showFilterGroup.addFilter(
          TKApprovalFilterManager.createFilter(
            {
              name: 'peopleTag',
              type: 'multiselect',
              label: I18n.t('lbl_people_tags'),
              getOptionLabel(option) {
                return option.value;
              },
            },
            function (values) {
              enterLoading();
              return TKUsers.getPeopleTags().then(function (tags) {
                values.push(...Array.from(tags || []));
                exitLoading();
              });
            }
          )
        );

        filterGroups.push(showFilterGroup);

        // Create FilterSet from the defined filter groups
        $scope.filterSet =
          TKApprovalFilterManager.createFilterSet(filterGroups);

        const dateFilter = $scope.filterSet.getFilter('dateRange');
        const stateFilter = $scope.filterSet.getFilter('approvalState');
        const approvableTypeFilter =
          $scope.filterSet.getFilter('approvableType');

        enterLoading();
        $q.all({
          orgSettings: TKOrgSettings.promise,
          clients: TKData.getClients(),
          disciplines: TKData.getDisciplines(),
          userSettings: TKData.getUserSettings($scope.userId),
        }).then(function (results) {
          ({ orgSettings } = results);
          clients = _.pluck(results.clients, 'value');
          disciplines = _.pluck(results.disciplines, 'value');
          const { userSettings } = results;
          $scope.savedFilters = _.where(userSettings, {
            name: 'approvalPageFilter',
          });
          $scope.savedFiltersById = {};
          _.each($scope.savedFilters, function (filter) {
            $scope.savedFiltersById[filter.id] = filter;
          });
          if ($scope.filter && $scope.savedFiltersById[$scope.filter]) {
            $scope.filterSet.deserialize(
              $scope.savedFiltersById[$scope.filter].value
            );
          }
          initialized = true;
          update();
          exitLoading();
        });

        let lastQueryParams = null;

        $scope.promptSaveFilter = function () {
          $scope.filterSetPopupVisible = true;
        };

        $scope.promptSaveFilterCancel = function () {
          $scope.filterSetPopupVisible = false;
        };

        $scope.saveFilter = function () {
          $scope.filterSet.name = $scope.filterName;
          const serialized = $scope.filterSet.serialize();
          TKData.createUserSettings(
            $scope.userId,
            'approvalPageFilter',
            serialized,
            false
          );
          $scope.filterName = '';
          $scope.filterSetPopupVisible = false;
        };

        $scope.canApprove = () => TKPermissions.canApprove();

        // Query data if necessary and update filters
        var update = function () {
          if (initialized) {
            const dates = dateFilter.getDates();
            const getApprovals =
              stateFilter.value !== I18n.t('lbl_unsubmitted');
            const queryParams = $scope.filterSet.serialize();

            if (queryParams === lastQueryParams) {
              updateTimesheets();
            } else {
              // params have changed, re-query
              lastQueryParams = queryParams;

              const { enableTimeApprovals } = $scope;
              const { enableExpenseApprovals } = $scope;

              $scope.includeTime = false;
              if (enableTimeApprovals && !enableExpenseApprovals) {
                $scope.includeTime = true;
              }
              if (
                enableTimeApprovals &&
                enableExpenseApprovals &&
                approvableTypeFilter.value === I18n.t('lbl_time')
              ) {
                $scope.includeTime = true;
              }

              $scope.includeExpenses = false;
              if (enableExpenseApprovals && !enableTimeApprovals) {
                $scope.includeExpenses = true;
              }
              if (
                enableTimeApprovals &&
                enableExpenseApprovals &&
                approvableTypeFilter.value === I18n.t('lbl_expenses')
              ) {
                $scope.includeExpenses = true;
              }

              if (getApprovals) {
                enterLoading();
                const exitLoadingOnce = _.once(exitLoading);
                const updateTimesheetsOnce = _.once(updateTimesheets);
                const refreshApprovablesAndUpdateTimesheetsOnce = _.once(
                  function () {
                    $scope.timeEntries = [];
                    $scope.expenses = [];
                    updateTimesheets();
                  }
                );
                const refreshTimesheets = function () {
                  $scope.timesheets.refresh();
                  $scope.expensesheets.refresh();
                };
                const refreshTimesheetsEventually = _.debounce(function () {
                  $scope.$evalAsync(refreshTimesheets);
                }, 100);
                const processedIds = [];
                let previousApprovalCount = 0;

                // `processApprovals` is called whenever `TKApprovals` notifies about new data.
                // Because `TKApprovals` caches individual promises it gets from `TKData`,
                // multiple notifications may come in a single synchronous block, one after the other.
                // That is why we use `_.debounce` to ensure that we wait until all
                // synchronous notifications have completed before we do any processing.
                const processApprovals = _.debounce(function (
                  approvals,
                  callback
                ) {
                  $scope.$evalAsync(function () {
                    refreshApprovablesAndUpdateTimesheetsOnce();
                    if (approvals.length > previousApprovalCount) {
                      _.each(
                        approvals.slice(previousApprovalCount),
                        function (approval) {
                          if (
                            approval.approvable_type === 'TimeEntry' &&
                            $scope.includeTime
                          ) {
                            $scope.timeEntries.push(approval.approvable);
                          } else if (
                            approval.approvable_type === 'ExpenseItem' &&
                            $scope.includeExpenses
                          ) {
                            $scope.expenses.push(approval.approvable);
                          }
                          approval.approvable.approvals = {
                            data: [approval],
                          };
                        }
                      );
                      previousApprovalCount = approvals.length;
                      const sheetCount = () =>
                        Math.max(
                          $scope.timesheets.length,
                          $scope.expensesheets.length
                        );
                      // We render as soon as we have more than 10 sheets worth of data
                      if (sheetCount() > 10) {
                        exitLoadingOnce();
                        // We have already rendered, so refreshing timesheets is not
                        // an immediate priority. We use a debounced version of `refreshTimesheets`
                        // so that we avoid using resources unnecessarily.
                        refreshTimesheetsEventually();
                      } else {
                        // We have not yet rendered.
                        refreshTimesheets();
                        // Sheet count may have changed after refresh, so check again.
                        if (sheetCount() > 10) {
                          exitLoadingOnce();
                        }
                      }
                    }

                    if (_.isFunction(callback)) {
                      callback();
                    }
                  });
                });

                const concludeProcessingApprovals = function (approvals) {
                  processApprovals(approvals, exitLoadingOnce);
                };

                TKApprovals.getApprovals(dates.start, dates.end).then(
                  concludeProcessingApprovals,
                  exitLoadingOnce,
                  processApprovals
                );
              } else {
                const promises = [];
                if ($scope.includeTime) {
                  promises.push(
                    TKApprovals.getEveryonesTimeEntries(dates.start, dates.end)
                  );
                } else {
                  const teamMemberFilter =
                    $scope.filterSet.getFilter('teamMember');
                  _.each(
                    teamMemberFilter.options,
                    function (teamMember, index) {
                      promises.push(
                        TKData.getExpenses(
                          teamMember.id,
                          dates.start,
                          dates.end,
                          false
                        )
                      );
                    }
                  );
                }

                const resolvePromises = function (results) {
                  $scope.timeEntries = $scope.expenses = [];

                  if ($scope.includeTime) {
                    $scope.timeEntries = _.flatten(results);

                    // Remove 0hr time entries, we won't be approving those
                    $scope.timeEntries = _.filter(
                      $scope.timeEntries,
                      (timeEntry) => timeEntry.hours > 0
                    );
                  } else {
                    $scope.expenses = _.flatten(results);
                  }

                  let assignables = $scope.timeEntries.concat($scope.expenses);

                  // filter out project assignables
                  const assignablesForProjects = _.filter(
                    assignables,
                    (assignable) => assignable.assignable_type === 'Project'
                  );

                  // filter out leaveType assignables
                  const assignablesForLeaveTypes = _.filter(
                    assignables,
                    (assignable) => assignable.assignable_type === 'LeaveType'
                  );

                  // Gather all unique project and leaveType ids
                  const projectIds = _.tk.pluckUnique(
                    assignablesForProjects,
                    'assignable_id'
                  );
                  const leaveTypeIds = _.tk.pluckUnique(
                    assignablesForLeaveTypes,
                    'assignable_id'
                  );

                  $q.all([
                    TKAssignables.getTheseProjectsNow(projectIds),
                    TKAssignables.getTheseLeaveTypesNow(leaveTypeIds),
                  ]).then(function (results) {
                    assignables = _.flatten(results);
                    if ($scope.includeTime) {
                      $scope.timeEntries = _.filter(
                        $scope.timeEntries,
                        function (timeEntry) {
                          const assignable = _.find(assignables, {
                            id: timeEntry.assignable_id,
                          });
                          if (assignable.deleted_at) {
                            return false;
                          } else {
                            return true;
                          }
                        }
                      );
                    } else {
                      $scope.expenses = _.filter(
                        $scope.expenses,
                        function (expenseItem) {
                          const assignable = _.find(assignables, {
                            id: expenseItem.assignable_id,
                          });
                          if (assignable.deleted_at) {
                            return false;
                          } else {
                            return true;
                          }
                        }
                      );
                    }

                    updateTimesheets();
                    exitLoading();
                  });
                };
                enterLoading();
                $q.all(promises).then(resolvePromises, exitLoading);
              }
            }
          }
        };

        var updateTimesheets = function () {
          if (!$scope.timeEntries || !$scope.expenses) {
            return;
          }
          $scope.timesheets = TKTimesheetFactory.createTimesheets(
            $scope.timeEntries,
            $scope.filterSet,
            orgSettings
          );
          $scope.expensesheets = TKTimesheetFactory.createExpensesheets(
            $scope.expenses,
            $scope.filterSet
          );
        };

        // Trigger `update` on any change to `filterSet.groups`
        $scope.$watch('filterSet.groups', update, true);
      },
    };
  },
]);
