/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkApprovalsFilterList', [
  '$rootScope',
  '$log',
  '$q',
  'TKData',
  'TKDateUtil',
  'TKOrgSettings',
  'TKPopoverManager',
  'TKPermissions',
  (
    $rootScope,
    $log,
    $q,
    TKData,
    TKDateUtil,
    TKOrgSettings,
    TKPopoverManager,
    TKPermissions
  ) => ({
    restrict: 'E',
    templateUrl: 'ng-approvals/templates/approvalsFilterList',
    scope: {
      userId: '=',
    },

    controller: function ($scope) {
      $scope.filters = {};
      $scope.I18n = I18n;

      TKOrgSettings.promise.then(function () {
        if (
          TKOrgSettings.timeEntryApprovals &&
          TKOrgSettings.expenseApprovals
        ) {
          $scope.myApprovalsLabel = I18n.t('lbl_my_time_and_expenses');
        } else if (TKOrgSettings.timeEntryApprovals) {
          $scope.myApprovalsLabel = I18n.t('lbl_my_time');
        } else {
          $scope.myApprovalsLabel = I18n.t('lbl_my_expenses');
        }
      });

      const getUserSettings = function () {
        TKData.getUserSettings($scope.userId).then(function (settings) {
          _.each(settings, function (settings) {
            if (settings.name === 'approvalPageFilter') {
              settings.value = JSON.parse(settings.value);
              $scope.filters[settings.value.name] = settings;
            }
          });
        });
      };

      if (TKData.authenticated) {
        getUserSettings();
      } else {
        $rootScope.$on('authenticated', function () {
          getUserSettings();
        });
      }

      $scope.canApprove = () => TKPermissions.canApprove();

      $scope.selectMyApprovals = function () {
        $scope.$emit('tk-show-approvals-filter', { myApprovals: true });
      };

      $scope.selectFilter = function (filter) {
        if (filter) {
          $scope.$emit('tk-show-approvals-filter', { filter: filter.id });
        } else {
          $scope.$emit('tk-show-approvals-filter');
        }
      };

      $scope.deleteFilter = function (filter, userConfirmed) {
        if (userConfirmed == null) {
          userConfirmed = false;
        }
        if (TKPopoverManager.showingPopover() && !userConfirmed) {
          return;
        }
        if (!userConfirmed) {
          filter.selected = true;
        } else {
          $scope.deleteFilterCancel(filter);
          TKData.deleteUserSetting($scope.userId, filter).then(function () {
            delete $scope.filters[filter.value.name];
          });
        }
      };

      $scope.deleteFilterCancel = function (filter) {
        filter.selected = false;
      };
    },
  }),
]);
