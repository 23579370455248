/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkEnter', function () {
  const directiveDefinitionObject = {
    restrict: 'A',
    link(scope, element, attrs) {
      return element.bind('keydown keypress', function (event) {
        if (event.which === 13) {
          scope.$apply(() => scope.$eval(attrs.tkEnter));
          return event.preventDefault();
        }
      });
    },
  };

  return directiveDefinitionObject;
});
