/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Focus on creation
angular.module('app').filter(
  'tkProjectName',
  () =>
    function (project) {
      if ((project != null ? project.name : undefined) != null) {
        if (project.phase_name) {
          return `${project.name}: ${project.phase_name}`;
        } else {
          return project.name;
        }
      } else {
        return '';
      }
    }
);
