/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkMultiPane', [
  '$log',
  '$q',
  '$timeout',
  'TKData',
  'TKOrgSettings',
  'TKDateUtil',
  (
    $log,
    $q,
    $timeout,
    TKData,
    TKOrgSettings,
    TKDateUtil // CSS/JS to
  ) =>
    // 1. move target pane into appropriate position (css classes)
    // 2. apply css to animate out old and animate in new pane simultaneously
    ({
      restrict: 'E',
      transclude: 'true',
      templateUrl: 'ng-common/templates/multiPane',

      scope: {
        activePane: '=activePane',
      },

      link($scope, element, attrs, ctrl, transcribe) {
        $scope.isProjectPickerVisible = true;
        $scope.paneTitle = '';
        $scope.activePane = $scope.activePane;
        $scope.titleBar = attrs.titleBar; // this is a non-assignable boolean
        $scope.backButton = attrs.backButton || false;
        const $panes = element.find('.pane');
        $scope.panes = [];
        $scope.history = [];

        // just setting $scope.paneTitle isn't enough, have to $apply via $timeout.
        const updatePaneTitle = (title) =>
          $timeout(() => ($scope.paneTitle = title), 0);

        // adds to a local array of pane history, for use with the back button.
        // @param {string} targetPane The targetPane name to add to the history array.
        const pushHistory = function (targetPane) {
          const { history } = $scope;

          // disallow history to have 2 panes back-to-back
          if (history[history.length - 1] === targetPane) {
            return;
          }

          return $scope.history.push(targetPane);
        };

        // @returns {string} the most-recent pane in the history array.
        const popHistory = function () {
          if ($scope.history.length > 1) {
            return $scope.history.pop();
          }
          return undefined;
        };

        // Emits an event that the 'back' button was clicked.
        // @param {boolean} [shouldTransition=false] Whether or not multi-pane
        //                  should transition to the previous pane. Useful if you
        //                  want to delegate transition to a different directive
        //                  which can bind to the 'multiPane-goBack' $scope event.
        // @return {mixed} the lastPane name, or if none exists, undefined.
        $scope.goBack = function (shouldTransition) {
          shouldTransition = shouldTransition || false;

          if ($scope.history.length > 1) {
            const lastPane = $scope.history[$scope.history.length - 2];
            $scope.$emit('multiPane-goBack', lastPane);

            if (shouldTransition) {
              $scope.transitionToPane(lastPane, $scope.activePane, true);
            }

            $scope.history.pop();

            return lastPane;
          }

          $scope.$emit('multiPane-goBack', undefined);
          return undefined;
        };

        // determines if the back button should be visible
        $scope.isBackButtonVisible = function () {
          if ($scope.backButton) {
            return $scope.history.length > 1;
          } else {
            return false;
          }
        };

        // Manipulates DOM to change the viewable pane to the target pane
        // @param {string|number} targetPane The name of the pane to activate
        //                                   If number, the index of the pane to activate
        // @param {string|number} oldPane The name of the pane that should be transitioned out
        //                                If number, the index of the pane to activate
        // @param {boolean} [updateActivePane=false] Whether or not to update $scope.activePane
        $scope.transitionToPane = function (
          targetPane,
          oldPane,
          updateActivePane
        ) {
          let paneIn, paneOut;
          const { panes } = $scope;
          updateActivePane = updateActivePane || false;

          const updateTitleBar = function (paneIn) {
            if ($scope.titleBar === false) {
              return;
            }

            updatePaneTitle(paneIn.title);
            pushHistory(paneIn.name);

            if (updateActivePane) {
              return ($scope.activePane = paneIn.name);
            }
          };

          // guardian. Need target pane to transition
          if (targetPane === undefined) {
            $log.warn('No Target Pane set, doing nothing.');
            return;
          }

          // support pane name as targetPane
          if (typeof targetPane === 'string') {
            paneIn = _.find(panes, (pane) => pane.name === targetPane);
            // support pane index as targetPane
          } else if (typeof targetPane === 'number') {
            if (panes[targetPane] !== undefined) {
              paneIn = panes[targetPane];
            } else {
              $log.warn('Pane ID ' + targetPane + ' does not exist. Stopping.');
              return;
            }
          }

          // support pane name as oldPane
          if (typeof oldPane === 'string') {
            paneOut = _.find(panes, (pane) => pane.name === oldPane);
            // support pane index as old pane
          } else if (typeof oldPane === 'number') {
            if (panes[oldPane] !== undefined) {
              paneOut = panes[oldPane];
            } else {
              $log.warn('Pane ID ' + targetPane + ' does not exist. Stopping.');
              return;
            }
          }

          // guardian
          if (paneIn === undefined) {
            $log.warn('paneIn is undefined. Stopping.');
            return;
          }

          const paneTitle = paneIn.title || '';

          // emit event to alert parent scopes of pane change.
          $scope.$emit('multiPane-updateActivePane', paneIn);

          // guardian: just ensure paneIn isn't hidden
          if (paneIn === paneOut) {
            paneIn.$el.removeClass('hidden');
            updateTitleBar(paneIn);
            return;
          }

          // if oldPane is not used, assume a quick show/hide.
          if (paneOut === undefined) {
            _.each(panes, function (pane) {
              if (pane.name !== targetPane) {
                return pane.$el.addClass('hidden');
              } else {
                return pane.$el.removeClass('hidden');
              }
            });

            updateTitleBar(paneIn);
            return;
          }

          // transtition panes without animation
          paneIn.$el.removeClass('hidden');
          paneOut.$el.addClass('hidden');
          return updateTitleBar(paneIn);
        };

        // init -------------------------------------------------------

        $panes.each(function (index, el) {
          const $el = $(el);
          $scope.panes.push({
            $el,
            name: $el.attr('pane-name') || index,
            title: I18n.t($el.attr('pane-title')),
          });

          // hide all panes
          return $el.addClass('hidden');
        });

        if ($scope.activePane === undefined) {
          $scope.activePane = 0;
        }

        // events -----------------------------------------------------

        const unbindActivePane = $scope.$watch(
          'activePane',
          (newPane, oldPane) => $scope.transitionToPane(newPane, oldPane)
        );

        const offGoBack = $scope.$on('goBack', function (e, data) {
          let shouldTransition = undefined;

          if (typeof data === 'object') {
            ({ shouldTransition } = data);
          }

          $scope.goBack(shouldTransition);
        });

        // cleanup ----------------------------------------------------

        const cleanup = function () {
          unbindActivePane();
          return offGoBack();
        };

        $scope.$on('$destroy', function () {
          cleanup();
        });

        return element.on('$destroy', function () {
          cleanup();
        });
      },
    }),
]);
