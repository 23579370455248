/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkExpenseTracker', [
  '$log',
  '$q',
  '$filter',
  'TKData',
  'TKPermissions',
  'TKOrgSettings',
  'TKDateUtil',
  'TKPopoverManager',
  'TKApprovables',
  'TKAssignables',
  'TKCleanup',
  'TKErrorMessenger',
  (
    $log,
    $q,
    $filter,
    TKData,
    TKPermissions,
    TKOrgSettings,
    TKDateUtil,
    TKPopoverManager,
    TKApprovables,
    TKAssignables,
    TKCleanup,
    TKErrorMessenger
  ) => ({
    restrict: 'E',
    templateUrl: 'ng-approvals/templates/expenseTracker/expenseTracker',

    scope: {
      userId: '=',
      enableApprovals: '=',
      currentStartDate: '=startDate',
      currentEndDate: '=endDate',
      currentDayCount: '=dayCount',
      mode: '@',
      approvalUserId: '=?',
      approvalData: '=?',
    },

    controller: function ($scope) {
      $scope.I18n = I18n;

      $scope.modeEntry = $scope.mode === 'expense-entry';
      $scope.modeExpensesheetApproval = $scope.mode === 'approval-expensesheet';

      $scope.canEditUser = () => TKPermissions.canEditUser($scope.userId);

      $scope.canEdit = () => $scope.canEditUser() && $scope.modeEntry;

      // This is a list of expenses that were added during the current session,
      // which gets reset if there is a date change.
      $scope.recentExpenses = [];

      const orgSettings = null;

      $scope.state = {
        showAdd: false,
      };

      $scope.$on(
        'tk-expand-all',
        (event, expanded) => ($scope.expandAll = expanded)
      );

      $scope.assignables = TKAssignables.allAssignables;

      TKAssignables.promise().then(function () {
        $scope.assignablesById = TKAssignables.allAssignablesById;
      });

      $scope.loadingAssignables = true;

      TKAssignables.promise().then(function () {
        $scope.loadingAssignables = false;
      });

      $scope.sumExpenseAmounts = (expenses) => _.tk.sum(expenses, 'amount');

      $scope.showAdd = () => $scope.state.showAdd;

      $scope.addExpense = function () {
        $scope.state.showAdd = true;
      };

      $scope.expenseAction = function ($action) {
        if ($action === 'close') {
          $scope.state.showAdd = false;
        }
      };

      // NOTE returns assignable_id's for valid expenses (excludes leave types)
      const getValidExpenseAssignableIds = function (expenses) {
        const leaveTypeIdsToExclude = _.tk.pluckUnique(
          _.filter(
            $scope.assignables,
            (assignable) => assignable.type === 'LeaveType'
          ),
          'id'
        );
        const expenseAssignableIds = _.tk.pluckUnique(
          expenses,
          'assignable_id'
        );
        return _.difference(expenseAssignableIds, leaveTypeIdsToExclude);
      };

      const getAndShowExpenses = function () {
        const showData = function () {
          $scope.startDate = $scope.currentStartDate;
          $scope.endDate = $scope.currentEndDate;
          return ($scope.dayCount = $scope.currentDayCount);
        };

        if ($scope.approvalData) {
          if ($scope.expenses == null) {
            $scope.expenses = $scope.approvalData.approvables;
          }
          showData();
        } else {
          // Widen the range and filter the results to account for timezone weirdness
          const dayBeforeStartDate = TKDateUtil.addDays(
            $scope.currentStartDate,
            -1
          );
          const dayAfterEndDate = TKDateUtil.addDays($scope.currentEndDate, 1);
          TKData.getExpenses(
            $scope.userId,
            dayBeforeStartDate,
            dayAfterEndDate
          ).then(function (expenses) {
            const assignableIdsToFetch = getValidExpenseAssignableIds(expenses);
            TKAssignables.getTheseProjectsNow(assignableIdsToFetch);
            $scope.expenses = _.filter(expenses, function (expense) {
              const date = TKDateUtil.parseRubyDate(expense.date);
              return (
                $scope.currentStartDate <= date && date <= $scope.currentEndDate
              );
            });
            $scope.$evalAsync(showData);
          });
        }
      };

      let submissionIsInProgress = false;
      $scope.approvalStatus = function () {
        let expenses = $scope.recentExpenses || [];
        if ($scope.expenses) {
          expenses = expenses.concat($scope.expenses);
        }
        if (expenses.length) {
          if (submissionIsInProgress) {
            return 'pending';
          }
          const status = TKApprovables.approvablesStatus(expenses);
          $scope.cachedApprovalStatus = status;
          return status.state;
        }
      };

      $scope.submitApprovals = function () {
        if (
          gData.accountSettings.moduleEnabled('relationship-based approvals')
        ) {
          submissionIsInProgress = true;
          let expenses = $scope.expenses || [];
          if ($scope.recentExpenses) {
            expenses = expenses.concat($scope.recentExpenses);
          }
          const filteredApprovables = _.filter(
            expenses,
            function (expenseEntry) {
              const assignable =
                TKAssignables.projectsById[expenseEntry.assignable_id] ||
                TKAssignables.leaveTypesById[expenseEntry.assignable_id];
              const isPending =
                (expenseEntry && !expenseEntry.approvals) ||
                !expenseEntry.approvals.data[0];
              const isNotArchived = assignable && !assignable.deleted_at;
              return isNotArchived && isPending;
            }
          );
          const updateFilteredApprovables = function ({ data }) {
            submissionIsInProgress = false;
            _.each(filteredApprovables, function (approvable) {
              const approval = _.find(data, { approvable_id: approvable.id });
              approvable.approvals = approvable.approvals || { data: [] };
              approvable.approvals.data[0] = approval;
            });
          };
          const errorHandler = TKErrorMessenger.newHandler({
            broadcast: 'tk-submit-approval-fail',
          });
          TKData.setApprovablesStatus(
            $scope.userId,
            filteredApprovables,
            'pending',
            false,
            'ExpenseItem'
          ).then(updateFilteredApprovables, errorHandler);
        } else {
          $scope.$emit('tk-submit-approvals', {
            from: $scope.startDate,
            to: $scope.endDate,
            type: 'expenses',
          });
        }
      };

      $scope.approvalFlagIncludeAll = function (id) {
        // Convert id to number
        id = parseInt(id);

        let all = true;
        _.each($scope.approvalData.approvables, function (expense) {
          if (
            expense.assignable_id === id &&
            !$scope.approvalData.submitIds[expense.id]
          ) {
            all = false;
          }
        });

        return all;
      };

      $scope.approvalFlagChanged = function (checked, id) {
        // Convert id to number
        id = parseInt(id);

        _.each($scope.approvalData.approvables, function (expense) {
          if (expense.assignable_id === id) {
            $scope.approvalData.submitIds[expense.id] = checked;
          }
        });
      };

      const cleanup = TKCleanup.newCleanup($scope);

      // $scope.$watch "expense.approve", (newValue) ->
      //   $scope.$emit 'tk-assignable-check-state', $scope.data.assignable.id, newValue

      // Watch for date changes
      cleanup(
        $scope.$watch(
          'currentStartDate.getTime()+":"+currentDayCount',
          function (newVal, oldVal) {
            $scope.recentExpenses = [];
            getAndShowExpenses();
          }
        )
      );

      return cleanup(
        $scope.$watchCollection('approvalData.approvables', function () {
          getAndShowExpenses();
        })
      );
    },
  }),
]);
