angular.module('app').directive('tkMeasureScrollTopInto', [
  '$parse',
  '$rootScope',
  ($parse, $rootScope) => ({
    restrict: 'A',

    link(scope, el, attrs) {
      let assignScrollTop;
      const model = $parse(attrs.tkMeasureScrollTopInto);
      let currentScrollTop = 0;

      (assignScrollTop = _.debounce(function () {
        scope.$apply(function () {
          currentScrollTop = el.scrollTop();
          model.assign(scope, currentScrollTop);
          $rootScope.$broadcast('tkScrollChange');
        });
      }, 200))();

      scope.$watch(
        () => model(scope, {}),
        function (scrollTop) {
          if (scrollTop !== currentScrollTop) {
            el.scrollTop(scrollTop);
          }
        }
      );

      el.scroll(assignScrollTop);
    },
  }),
]);
