angular.module('app').directive('tkUserBadge', [
  'TKData',
  (TKData) => ({
    restrict: 'E',
    replace: true,
    templateUrl: 'ng-approvals/templates/userBadge',

    scope: {
      userId: '=',
    },

    controller: function ($scope) {
      $scope.imgSrc = DEFAULT_PERSON_THUMBNAIL_URL;

      TKData.getUser($scope.userId).then(function (user) {
        $scope.user = user;
      });
    },
  }),
]);
