/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Focus on creation
angular.module('app').directive('tkFocus', [
  '$timeout',
  ($timeout) => ({
    restrict: 'A',

    link(scope, element, attrs) {
      return $timeout(function () {
        element.focus();
        if (attrs.tkFocus === 'select') {
          return element.select();
        }
      });
    },
  }),
]);
