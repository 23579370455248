/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app.services').factory('TKPermissions', [
  '$rootScope',
  '$q',
  'TKSession',
  'TKData',
  function ($rootScope, $q, TKSession, TKData) {
    const TKPermissions = {};

    let currentUser = null;
    let currentUserId = null;
    let currentUserType = null;
    let currentUserCanEditOthers = null;

    const userTypeIdToUserTypeMap = {
      1: 'account owner',
      2: 'portfolio editor',
      3: 'portfolio reporter',
      4: 'portfolio viewer',
      5: 'contractor',
      6: 'api user',
      7: 'people scheduler',
      8: 'project editor',
    };

    const userTypesThatCanEditOthers = ['account owner', 'portfolio editor'];

    const getUser = (
      (usersById) => (userId) =>
        (usersById[userId] =
          usersById[userId] || TKData.getUser(userId).$object)
    )({});

    const getCurrentUser = function () {
      currentUserId = TKSession.data.user_id;
      TKData.getUser(currentUserId).then(function (user) {
        currentUser = user;
        currentUserType = userTypeIdToUserTypeMap[currentUser.user_type_id];
        currentUserCanEditOthers = _.contains(
          userTypesThatCanEditOthers,
          currentUserType
        );
      });
    };

    if (TKSession.data != null ? TKSession.data.user_id : undefined) {
      getCurrentUser();
    } else {
      $rootScope.$on('authenticated', function () {
        getCurrentUser();
      });
    }

    const userIsCurrentUser = (userId) => userId === currentUserId;

    const userIsEditable = (userId) =>
      getUser(userId).license_type === 'licensed';

    const currentUserCanEditOtherUser = (userId) =>
      currentUserCanEditOthers && userIsEditable(userId);

    TKPermissions.canEditUser = (userId) =>
      userIsCurrentUser(userId) || currentUserCanEditOtherUser(userId);

    TKPermissions.canApprove = () => currentUserCanEditOthers;

    TKPermissions.userIsCurrentUser = userIsCurrentUser;

    return TKPermissions;
  },
]);
