/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Time Tracker Project Picker directive.
// NOTE: This is tightly coupled with tk-time-tracker - it cannot work unless placed
// inside a tk-time-tracker directive. It was turned into its own directive to make
// it easier to edit - tk-time-tracker is a large file.

// ALSO NOTE: There now exists a generic project picker that is very similar but out of sync with this one.
// TODO Refactor tkTimeTracker to use the generic project picker instead of this one.
angular.module('app').directive('tkTimeTrackerProjectPicker', [
  '$log',
  '$timeout',
  '$parse',
  '$q',
  'TKData',
  'TKOrgSettings',
  'TKPopoverManager',
  'TKDateUtil',
  'TKPlaceholderRowManager',
  'TKPermissions',
  'TKAssignables',
  function (
    $log,
    $timeout,
    $parse,
    $q,
    TKData,
    TKOrgSettings,
    TKPopoverManager,
    TKDateUtil,
    TKPlaceholderRowManager,
    TKPermissions,
    TKAssignables
  ) {
    const persistentVariables = {};
    return {
      restrict: 'E',
      templateUrl: 'ng-approvals/templates/timeTrackerProjectPicker',
      // This directive currently requires tkTimeTracker. It could be separated in the future by removing references to the tkTimeTracker's
      // scope variables
      require: '^tkTimeTracker',
      controller: function ($scope, $element) {
        $scope.imgSrc = PROGRESS_INDICATOR;
        $scope.I18n = I18n;
        const paneNames = {
          project: 'selectProject',
          phase: 'selectPhase',
          category: 'selectCategory',
        };

        // private functions ----------------------------------------------------

        // Activates a specific pane and resets the keyboard-induced highlights
        // if any exist.
        let activatePane = function (type) {
          const name = paneNames[type];
          $scope.pickerState.activePaneName = name;
          $scope.highlightItem(); // supplying no item will unhighlight the last highlighted item.
          // reset highlights updates models, but DOM is not always updated.
          // Force an update.
          $timeout(_.noop);
        };

        // Debouncing allows successive calls (such as those caused by `checkForAssignable`)
        // to pass through before executing only the final call.
        activatePane = _.debounce(activatePane);

        // Determines if a project is visible in the project picker.
        // @param {object} project The project object to check
        // @return {boolean} whether or not the project is visible.
        const isProjectVisible = (project) =>
          _.find($scope.pickerState.visibleProjects, { id: project.id }) ||
          false;

        // Finds projects whose name or client loosely match a string query
        // @param {string} query The query string
        // @return {array} An array of found proejcts.
        const findProjectsMatchingQuery = function (query) {
          query = typeof query === 'string' ? query.toLowerCase() : '';
          const foundProjects = [];

          _.each($scope.projects, function (project) {
            const name = project.name ? project.name.toLowerCase() : '';
            const client = project.client ? project.client.toLowerCase() : '';

            if (name.indexOf(query) > -1 || client.indexOf(query) > -1) {
              return foundProjects.push(project);
            }
          });

          return foundProjects;
        };

        // Gets the next item in a collection.
        // Returns the first item in the collection if none is defined
        // @param {array} collection An array of objects.
        // @return {number} The ID of the next item.
        const getNextItemIndex = function (
          collection,
          currentItem,
          highlightedItemId
        ) {
          // guardian
          if (collection === undefined) {
            $log.warn('No collection. Stopping.');
            return 0;
          }

          // guardian: default to first paneLink
          if (currentItem === undefined) {
            return 0;
          }

          // guardian: highlighted pane link might be filtered out. Return
          // first paneLink by default.
          if (collection.indexOf(currentItem) === -1) {
            return 0;
          }

          // guardian: if highlighted pane is the last in the list, then return
          // the last pane.
          if (collection.indexOf(currentItem) === collection.length - 1) {
            return collection.length - 1;
          }

          // In the case that 2 or more identical items are listed one
          // after the other, indexOf will return the first result, which will
          // stick nextItemIndex to the same number. In this case, rely on
          // `highlightedItemId` and decrement manually.
          if (highlightedItemId === collection.indexOf(currentItem) + 1) {
            if (highlightedItemId === collection.length - 1) {
              return collection.length - 1;
            }
            return highlightedItemId + 1;
          }

          return collection.indexOf(currentItem) + 1;
        };

        // Gets the previous item in a collection.
        // Returns the last item if none is defined.
        // @param {array} collection An array of objects.
        // @param {mixed} currentItem The current item. It may or may not exist in
        // the current collection.
        // @return {number} The ID of the previous item.
        const getPrevItemIndex = function (
          collection,
          currentItem,
          highlightedItemId
        ) {
          // guardian
          if (collection === undefined) {
            $log.warn('No collection. Defaulting to 0.');
            return 0;
          }

          // guardian: default to null
          if (currentItem === undefined) {
            return null;
          }

          // guardian: highlighted pane link might be filtered out. Return
          // null.
          if (collection.lastIndexOf(currentItem) === -1) {
            return null;
          }

          // guardian: if highlighted pane is the first in the list, then return
          // null
          if (collection.lastIndexOf(currentItem) === 0) {
            return null;
          }

          // In the case that 2 or more identical items are listed one
          // after the other, indexOf will return the first result, which will
          // stick prevItemIndex to the same number. In this case, rely on
          // `highlightedItemId` and decrement manually.
          if (highlightedItemId === collection.lastIndexOf(currentItem) - 1) {
            if (highlightedItemId === 0) {
              return null;
            }
            return highlightedItemId - 1;
          }

          return collection.lastIndexOf(currentItem) - 1;
        };

        // Within the active pane, scrolls to the desired item.
        // NOTE: DOM interaction/manip.
        // @param {object|string} jQuery selection or DOM selector.
        // @param {string} [position='bottom'] Desired position for the item to
        //                 end up. Accepts 'top' or 'bottom'.
        // @return undefined
        const scrollToItem = function ($item, position) {
          // guardian
          if (
            $scope.pickerState.activePane === undefined ||
            $item === undefined
          ) {
            $log.warn('ScrollToItem: no active pane or item - cannot scroll.');
            return;
          }

          position = position === 'bottom' ? 'bottom' : 'top';
          const $activePane = $scope.pickerState.activePane.$el;
          $item =
            $item instanceof jQuery ? $item : $activePane.find($item).eq(0);

          // guardian
          if ($item.length === 0) {
            $log.warn(
              'ScrollToItem: No highlighted item found in DOM. Stopping.'
            );
            return;
          }

          const $parent = $activePane.parents('.panes').eq(0);
          const scrollTop = $parent.scrollTop();
          const parentHeight = $parent.outerHeight();

          const itemTop = $item.position().top;
          const itemHeight = $item.outerHeight();

          if (position === 'bottom') {
            if (
              itemTop + itemHeight < scrollTop ||
              itemTop + itemHeight > scrollTop + parentHeight
            ) {
              $parent.scrollTop(itemTop + itemHeight - parentHeight);
            }
          }

          if (position === 'top') {
            if (itemTop < scrollTop || itemTop > scrollTop + parentHeight) {
              $parent.scrollTop(itemTop);
            }
          }
        };

        // Highlights a pane link either next or previous to the current highlighted
        // paneLink.
        const highlightItemInDirection = function (collection, direction) {
          let newHighlightedItemId;
          direction = direction === 'next' ? 'next' : 'prev';
          const currentItem = _.find(collection, { highlighted: true });
          const scrollPosition = direction === 'next' ? 'bottom' : 'top';

          // guardian
          if (collection === undefined) {
            $log.warn('No collection defined. Stopping.');
            return;
          }

          if (direction === 'next') {
            newHighlightedItemId = getNextItemIndex(
              collection,
              currentItem,
              $scope.pickerState.highlightedItemId
            );
          }

          if (direction === 'prev') {
            newHighlightedItemId = getPrevItemIndex(
              collection,
              currentItem,
              $scope.pickerState.highlightedItemId
            );
          }

          $scope.pickerState.highlightedItemId = newHighlightedItemId;

          // null is returned if user hits 'prev' on the first item.
          // In this case, re-focus the search bar.
          if (newHighlightedItemId === null) {
            focusSearchBar();
            $scope.$apply();
          } else {
            // add a 'highlighted' property to the new highlighted item.
            const newHighlightedItem = collection[newHighlightedItemId];
            $scope.highlightItem(newHighlightedItem);
            // newHighlightedItem.highlighted = true

            $scope.$apply(); // need to apply DOM changes so `scrollToItem` will work correctly.

            // scroll to the hightlighted item
            scrollToItem('.highlighted', scrollPosition);
          }
        };

        // gets all filtered projects, which includes recent and visible projects
        // as well as leaveTypes.
        const getFilteredProjects = () =>
          _.union(
            $scope.pickerState.filteredRecentProjects,
            $scope.pickerState.filteredVisibleProjects,
            $scope.pickerState.filteredLeaveTypes
          );

        // returns all filtered(visible) phases in the UI.
        // @return {array} all filtered phases.
        const getFilteredPhases = function () {
          let phases = $scope.pickerState.filteredPhases || [];

          const query =
            $scope.pickerState.phaseFilter != null
              ? $scope.pickerState.phaseFilter.phase_name.toLowerCase()
              : undefined;

          if (query) {
            phases = _.filter(
              phases,
              ({ phase_name }) => phase_name.toLowerCase().indexOf(query) > -1
            );
          }

          return phases;
        };

        // gets all filtered (visible) categories in the picker UI. Concats
        // project and account categories in the proper order.
        // @return {array} filtered categories.
        const getFilteredCategories = function () {
          let categories = _.union(
            $scope.pickerState.filteredNoCategory,
            $scope.pickerState.filteredProjectCategories,
            $scope.pickerState.filteredAccountCategories
          );

          const query =
            $scope.pickerState.categoryFilter != null
              ? $scope.pickerState.categoryFilter.category.toLowerCase()
              : undefined;

          if (query) {
            // This manual filtering is necessary because ng-repeat does not
            // assign to $scope.pickerState.filtered...Categories if the filter
            // returns an empty array, which means stray categories stick around
            // in these arrays even though they do not show up in the UI.
            categories = _.filter(
              categories,
              ({ category }) => category.toLowerCase().indexOf(query) > -1
            );
          }

          return categories;
        };

        // Removes recent projects from the supplied array
        // @param {array} visibleProjects The projects array (typically visibleProjects)
        //        from which to excise the recent projects.
        // @return {array} An array of projects
        const removeRecentProjects = (visibleProjects) =>
          _.difference(visibleProjects, $scope.recentProjects);

        // Handles 'enter' button presses.
        const handleEnterPress = function () {
          if ($scope.pickerState.activePaneName === paneNames['project']) {
            const activeProject = _.find(getFilteredProjects(), {
              highlighted: true,
            });
            if (activeProject !== undefined) {
              const projectType = $scope.isLeaveType(activeProject)
                ? 'leave'
                : 'project';
              $scope.chooseProject(activeProject, projectType);
            }
          } else if ($scope.pickerState.activePaneName === paneNames['phase']) {
            const activePhase = _.find(getFilteredPhases(), {
              highlighted: true,
            });
            if (activePhase !== undefined) {
              $scope.choosePhase(activePhase);
            }
          } else if (
            $scope.pickerState.activePaneName === paneNames['category']
          ) {
            const activeCategory = _.find(getFilteredCategories(), {
              highlighted: true,
            });
            if (
              !activeCategory ||
              (!activeCategory.id &&
                activeCategory.category !== $scope.emptyCategory.category)
            ) {
              return;
            }
            $scope.chooseCategory(activeCategory);
          }

          // Even though models get updated, DOM is not always updated.
          // Force an update.
          return $timeout(_.noop);
        };

        // handles 'escape' button presses.
        const handleEscapePress = function () {
          $scope.$broadcast('goBack');
        };

        // retrieves all categories for a given project.
        // NOTE: sets the state object - does not return anything.
        const retrieveCategories = function (projectId) {
          if (_.isObject(projectId)) {
            projectId = projectId.id;
          }
          $scope.pickerState.phaseCategories =
            TKData.getProjectCategories(projectId).$object;
          $scope.pickerState.accountCategories =
            $scope.orgSettings.budgetCategories;
        };

        var focusSearchBar = function () {
          const $searchBar = $scope.pickerState.activePane.$el
            .find('.search')
            .focus();
          scrollToItem($searchBar, 'top');
        };

        // resets user's choices in the UI.
        const resetChoices = function () {
          // $log.info('resetChoices')
          $scope.pickerState.chosenProject = undefined;
          $scope.pickerState.chosenCategory = undefined;
          $scope.highlightItem(); // supplying no item will unhighlight the last highlighted item.
        };

        const checkForAssignable = function () {
          const assignable = $parse($element.attr('assignable'))($scope);
          if (assignable && !$scope.isLeaveType(assignable)) {
            $scope.pickerState.activePaneName = 'category';
            $timeout(function () {
              let phase, project;
              if (assignable.parent_id) {
                project = _.find($scope.projects, { id: assignable.parent_id });
                // proxy project for contractors who cannot access data for projects they are not assigned to
                project = project || { id: assignable.parent_id };
                phase = assignable;
              } else {
                project = assignable;
                phase = $scope.emptyPhase;
              }
              $scope.chooseProject(project);
              $scope.choosePhase(phase);
            });
          }
        };

        const isProjectEditor = () => window.whoami.user_type_id === 8;

        // scope methods / vars -------------------------------------------------

        // Project picker state.
        _.extend($scope.pickerState, {
          visibleProjects: [],
          visibleLeaveTypes: undefined,
          useCategories: undefined,
          chosenProject: undefined,
          activePhases: undefined,
          accountCategories: undefined,
          projectCategories: undefined,
          phaseCategories: undefined,
          chosenCategory: undefined,
          activePaneName: undefined, // used by project picker
          activePane: undefined, // updated by tk-multi-pane via events
          projectFilter: undefined,
          phaseFilter: undefined,
          categoryFilter: undefined,
          filteredRecentProjects: undefined, // generated by ng-repeat
          filteredVisibleProjects: undefined, // generated by ng-repeat
          filteredLeaveTypes: undefined, // generated by ng-repeat
          filteredPhases: undefined, // generated by ng-repeat
          filteredProjectCategories: undefined, // generated by ng-repeat
          filteredAccountCategories: undefined, // generated by ng-repeat
          addingItems: false,
          getProjectAndPhaseCategories() {
            const categories = _.union(
              this.projectCategories,
              this.phaseCategories
            );
            _.remove(categories, { category: '' });
            return categories;
          },
        });

        $scope.combinedRecentProjects = {
          name: I18n.t('lbl_add_rows_previous_week'),
          isCombinedRecent: true,
        };

        // If user is not current user, combinedRecentProjects will be hidden
        $scope.userIsCurrentUser = TKPermissions.userIsCurrentUser(
          $scope.userId
        );

        // determines if a project is a leaveType.
        $scope.isLeaveType = function (project) {
          if (_.find(TKAssignables.leaveTypes, project)) {
            return true;
          }
          return false;
        };

        // determines if a project has phases.
        // @param {object} project the project to inspect.
        // @returns {boolean} whether or not the project has phases.
        $scope.doesProjectHavePhases = function (project) {
          if (typeof project !== 'object') {
            return;
          }

          // determine if project has phases
          const phases = _.filter(
            $scope.phases,
            (phase) => phase.parent_id === project.id
          );

          if (phases.length > 0) {
            return true;
          }

          return false;
        };

        // Determines the next pane to transition to. Also updates phase or
        // category data to ensure that the panes have updated content.
        // If clicked on a project, show the phase pane or, if the project contains
        // no phases, go to the category pane.
        // @param {object} Project object
        // @param {string} [type] The type of project. Accepts 'project' or 'leave'.
        // @return [string] paneName
        $scope.chooseProject = function (project, type) {
          const { pickerState } = $scope;

          if (project.isCombinedRecent) {
            $scope.addRecentTimeEntryRows();
            return;
          }

          if (type === undefined) {
            type = $scope.isLeaveType(project) ? 'leave' : 'project';
          }

          // set active project for the picker
          pickerState.chosenProject = project;
          pickerState.chosenProjectType = type;
          pickerState.phaseFilter = {
            phase_name: '',
          };

          // reset category choice
          pickerState.chosenCategory = undefined;
          pickerState.categoryFilter = {
            category: '',
          };

          let phases = null;

          if (type === 'project') {
            // determine if project has phases
            phases = _.filter(
              $scope.phases,
              (phase) => phase.parent_id === project.id
            );
          }

          const pickCategories =
            pickerState.useCategories && type === 'project';

          if (pickCategories) {
            pickerState.accountCategories = $scope.orgSettings.budgetCategories;
            pickerState.projectCategories = TKData.getProjectCategories(
              project.id
            ).$object;
          } else {
            pickerState.chosenCategory = null;
          }

          if (phases && phases.length > 0) {
            pickerState.activePhases = [$scope.emptyPhase].concat(phases); // add empty phase
            // remove empty phase if project is proxy.
            // this can happen for contractors who are assigned to a phase
            // but not the parent project
            if (project.is_proxy) {
              pickerState.activePhases.shift();
            }
            activatePane('phase');
            return paneNames['phase'];
          } else if (pickCategories) {
            retrieveCategories(project);
            activatePane('category');
            return paneNames['category'];
          } else {
            $scope.createTimeEntry();
            resetChoices();
          }
        };

        // projects list can be huge, so only display a small amount and add more
        // when the user requests.
        $scope.getVisibleProjects = function (numProjects) {
          const { pickerState } = $scope;
          pickerState.addingItems = true; // display "loading" item
          numProjects = numProjects || 50;
          const { visibleProjects } = pickerState;
          const start = visibleProjects.length;
          const end = start + 50;
          let projectToAdd = undefined;
          let allProjects = $scope.projects;
          if (isProjectEditor()) {
            allProjects = _.filter(allProjects, (project) =>
              project.can_i != null
                ? project.can_i.report_time_entries
                : undefined
            );
          }

          // guardian, stop if there aren't any more projects to add.
          // However, still remove recent projects from the visibleProjects list,
          // just in case that has changed.
          if (allProjects.length === visibleProjects.length) {
            pickerState.addingItems = false; // hide "loading" item
            pickerState.visibleProjects = removeRecentProjects(
              pickerState.visibleProjects
            );
            return;
          }

          // push more items into the visible projects list.
          for (
            let i = start, end1 = end, asc = start <= end1;
            asc ? i <= end1 : i >= end1;
            asc ? i++ : i--
          ) {
            projectToAdd = allProjects[i];
            if (projectToAdd === undefined) {
              break;
            } else if (isProjectVisible(projectToAdd) === false) {
              visibleProjects.push(projectToAdd);
            }
          }

          pickerState.visibleProjects = removeRecentProjects(
            pickerState.visibleProjects
          );
          pickerState.addingItems = false; // display "loading" item
        };

        // Determines CSS class for the project icon.
        // @param {object} project the project object
        $scope.getProjectIconCssClass = function (project) {
          let cssClass = 'square-icon small ';
          const states = {
            Tentative: 'type-tentative',
            Confirmed: 'type-confirmed',
            Internal: 'type-internal',
            Leave: 'type-leave',
          };

          if (project.isCombinedRecent) {
            cssClass = 'tk-icon-plus small';
          } else if (project.project_state) {
            cssClass += states[project.project_state] || 'type-confirmed';
          } else if ($scope.isLeaveType(project)) {
            cssClass += states.Leave;
          }

          return cssClass;
        };

        // Sets the scope's chosen phase and selects the next logical pane/step
        // for the user.
        // NOTE: A phase is just a project, so this updates `chosenProject`.
        $scope.choosePhase = function (project) {
          const { pickerState } = $scope;

          // reset category choice
          pickerState.chosenCategory = undefined;
          pickerState.categoryFilter = {
            category: '',
          };
          pickerState.phaseCategories = undefined;

          if (project !== $scope.emptyPhase) {
            pickerState.chosenProject = project;
          }

          if (pickerState.useCategories) {
            if (project.id) {
              retrieveCategories(project);
            }
            activatePane('category');
          } else {
            $scope.createTimeEntry();
            resetChoices();
          }
        };

        // TODO:
        // if no categories, call $scope.createTimeEntry()

        const getSpecifiedDate = _.memoize(() =>
          $parse($element.attr('date'))($scope)
        );

        $scope.hasProjectOrPhaseCategories = () =>
          $scope.pickerState.getProjectAndPhaseCategories().length;

        // Sets the active category
        $scope.chooseCategory = function (category) {
          const { pickerState } = $scope;
          pickerState.chosenCategory = category;

          if (pickerState.chosenProject) {
            $scope.createTimeEntry(getSpecifiedDate());
            resetChoices();
            TKPopoverManager.removeAllPopovers();
          }
        };

        $scope.addRecentTimeEntryRows = (function () {
          let addRecentTimeEntryRows;
          const ONE_DAY = 24 * 60 * 60 * 1000;
          const ONE_WEEK = 7 * ONE_DAY;

          const createMultiCategoryTimeEntries = function (
            categoriesByAssignableId
          ) {
            TKPopoverManager.removeAllPopovers();
            TKPlaceholderRowManager.persistRows(
              categoriesByAssignableId,
              getThisWeeksStartAndEnd()
            );
            $scope.rebuildTimesheetData();
            $scope.$evalAsync(function () {
              $scope.endEdit(false);
            });
          };

          const getLastWeeksStartAndEnd = function () {
            const thisWeek = TKDateUtil.toStartOfWeek($scope.dates[0]);
            const lastWeek = new Date(thisWeek.getTime() - ONE_WEEK);
            return [lastWeek, thisWeek];
          };

          var getThisWeeksStartAndEnd = function () {
            const start = TKDateUtil.toStartOfWeek($scope.dates[0]);
            const end = new Date(start.getTime() + ONE_WEEK);
            return [start, end];
          };

          const extractCategoryNamesFromTimeEntries = (timeEntries) =>
            _.tk.pluckUnique(timeEntries, 'task');

          const getTimeEntries = function (
            userId,
            startDate,
            endDate,
            withSuggestions
          ) {
            if (withSuggestions == null) {
              withSuggestions = false;
            }
            return TKData.getTimeEntries(
              userId,
              startDate,
              endDate,
              withSuggestions,
              false
            );
          };

          const getPreviousCategoriesByAssignableId = function (
            userId,
            projectId
          ) {
            const [startDate, endDate] = Array.from(getLastWeeksStartAndEnd());
            return getTimeEntries(userId, startDate, endDate).then(function (
              timeEntries
            ) {
              const timeEntriesByAssignableId = _.groupBy(
                timeEntries,
                'assignable_id'
              );
              const categoryNamesByAssignableId = {};
              for (var assignableId in timeEntriesByAssignableId) {
                timeEntries = timeEntriesByAssignableId[assignableId];
                categoryNamesByAssignableId[assignableId] =
                  extractCategoryNamesFromTimeEntries(timeEntries);
              }
              return categoryNamesByAssignableId;
            });
          };

          return (addRecentTimeEntryRows = function () {
            $scope.pickerState.spinnerIsVisible = true;
            getPreviousCategoriesByAssignableId($scope.userId).then(
              createMultiCategoryTimeEntries
            );
          });
        })();

        // Project Picker: Filter projects specifically by name and client.
        // @param {object} row Row object
        $scope.searchProject = function (row) {
          if (!row) {
            return false;
          }
          if (
            isProjectEditor() &&
            row.type === 'Project' &&
            !(row.can_i != null ? row.can_i.report_time_entries : undefined)
          ) {
            return false;
          }
          const query = ($scope.pickerState.projectFilter || '').toLowerCase();
          const isMatch = function (string) {
            if (string == null) {
              string = '';
            }
            return string.toLowerCase().indexOf(query) !== -1;
          };
          return isMatch(row.name) || isMatch(row.client);
        };

        // Add a unique DOM ID to the project picker so ngInfiniteScroll can
        // find scroll container properly.
        $scope.pickerState.pickerId = _.uniqueId('project-picker-');

        // returns a string that is the ng-infinite-scroll container directive selector.
        $scope.getInfiniteScrollSelector = () =>
          '#' + $scope.pickerState.pickerId + ' .panes';

        // Highlights an item and unhighlights the last highlighted item. This is
        // set up in such a way that only one item(project/phase/category) can be
        // highlighted at a time.
        // NOTE: IIFE, to keep lastHighlightedItem isolated.
        // @param {object} [item=undefined] the item object to set as highlighted.
        //        If none is supplied, unhighlight the last highlighted item.
        $scope.highlightItem = ((lastHighlightedItem) =>
          function (item) {
            if (lastHighlightedItem) {
              lastHighlightedItem.highlighted = false;
            }

            if (item) {
              lastHighlightedItem = item;
              item.highlighted = true;
            }
          })(undefined);

        (function (previousOnscroll) {
          // Enables window scrolling. Used in template when mouse exits the
          // project picker, and in the `cleanup` function below.
          // @return {undefined}
          $scope.enableWindowScroll = function () {
            if (previousOnscroll) {
              document.body.onscroll = previousOnscroll;
            }
            previousOnscroll = null;
          };

          // Disables window scrolling. Used in template when mouse enters the
          // project picker.
          // @return {undefined}
          return ($scope.preventWindowScroll = function () {
            previousOnscroll =
              previousOnscroll || document.body.onscroll || _.noop;
            const { scrollTop } = document.body;
            // neither jQuery's `on` nor plain DOM `addEventListener` works
            // with the "scroll" event, but `onscroll` does. I don't know why.
            // TODO Figure out the proper way to do this.
            document.body.onscroll = function (event) {
              previousOnscroll(event);
              // event.preventDefault() # `preventDefault` does not prevent scrolling
              document.body.scrollTop = scrollTop;
            };
          });
        })(null);

        // Used in the template.
        // Wraps a function - will not call that function unless the user
        // is not using the keyboard. Used to prevent mouse events from conflicting
        // with keyboard events and intention.
        // @param {function} fn the function to call
        // @param {array} argsArray an array of arguments to pass to the function.
        $scope.whenNotUsingKeyboard = function (fn, argsArray) {
          if ($scope.pickerState.usingKeyboard === true) {
            $log.warn('preventing');
            return;
          }

          return fn.apply(this, argsArray);
        };

        // init -----------------------------------------------------------------

        $scope.pickerState.spinnerIsVisible =
          !persistentVariables.recentProjectsHaveBeenLoadedAtLeastOnce;
        // get recent projects
        $scope.getRecentProjects().then(function () {
          $scope.getVisibleProjects();
          const activeLeaveTypes = _.filter(
            TKAssignables.leaveTypes,
            (leaveType) => !leaveType.deleted_at
          );
          $scope.pickerState.visibleLeaveTypes =
            removeRecentProjects(activeLeaveTypes);
          $scope.pickerState.spinnerIsVisible = false;
          persistentVariables.recentProjectsHaveBeenLoadedAtLeastOnce = true;
        });

        TKOrgSettings.promise.then(function (orgSettings) {
          $scope.orgSettings = orgSettings;
        });

        checkForAssignable();

        // events ---------------------------------------------------------------

        // watch the projectFilter string and ensure all matched projects are
        // visible to the user
        const unbindProjectFilterWatcher = $scope.$watch(
          'pickerState.projectFilter',
          function (newVal, oldVal) {
            if (newVal === undefined) {
              return;
            }

            const foundProjects = findProjectsMatchingQuery(newVal);
            $scope.pickerState.addingItems = true;
            _.each(foundProjects, function (project) {
              if (isProjectVisible(project) === false) {
                return $scope.pickerState.visibleProjects.push(project);
              }
            });
            $scope.pickerState.visibleProjects = removeRecentProjects(
              $scope.pickerState.visibleProjects
            );
            $scope.pickerState.addingItems = false;

            // ensure DOM is updated so highlighting works correctly
            $timeout(function () {
              if (getFilteredProjects().length > 0) {
                $scope.highlightItem(getFilteredProjects()[0]);
              } else {
                $scope.highlightItem();
              }
            });
          }
        );

        const unbindPhaseFilterWatcher = $scope.$watch(
          'pickerState.phaseFilter.phase_name',
          (
            newVal,
            oldVal // wait for DOM to update before getting filtered Phases
          ) =>
            $timeout(function () {
              if (getFilteredPhases().length > 0) {
                return $scope.highlightItem(getFilteredPhases()[0]); // supplying no item will unhighlight the last highlighted item.
              } else {
                return $scope.highlightItem();
              }
            }, 0)
        );

        const unbindCategoryFilterWatcher = $scope.$watch(
          'pickerState.categoryFilter.category',
          (newVal, oldVal) =>
            $timeout(function () {
              if (getFilteredCategories().length > 0) {
                return $scope.highlightItem(getFilteredCategories()[0]);
              } else {
                return $scope.highlightItem(); // supplying no item will unhighlight the last highlighted item.
              }
            }, 0)
        );

        const unbindLoadedWatcher = $scope.$watch(
          'loaded',
          function (newVal, oldval) {
            if (newVal === true) {
              $scope.pickerState.useCategories =
                $scope.timeEntryMode === 'itemized' ? true : false;
            }
          }
        );

        const unbindIsVisibleWatcher = $scope.$watch(
          'pickerState.isVisible',
          (name) => activatePane('project')
        );

        const unbindRecentProjectsWatcher = $scope.$watch(
          'recentProjects',
          function (newVal, oldVal) {
            if (newVal !== oldVal) {
              $scope.pickerState.visibleProjects = removeRecentProjects(
                $scope.pickerState.visibleProjects
              );
              $scope.pickerState.visibleLeaveTypes = removeRecentProjects(
                $scope.pickerState.visibleLeaveTypes
              );
            }
          }
        );

        // keyboard UI ------------------------------------------------

        $scope.keyBind = {
          down(e) {
            $scope.pickerState.usingKeyboard = true;
            if ($scope.pickerState.activePaneName === paneNames['project']) {
              highlightItemInDirection(getFilteredProjects(), 'next');
              return;
            }

            if ($scope.pickerState.activePaneName === paneNames['phase']) {
              highlightItemInDirection(getFilteredPhases(), 'next');
              return;
            }

            if ($scope.pickerState.activePaneName === paneNames['category']) {
              highlightItemInDirection(getFilteredCategories(), 'next');
              return;
            }
          },

          up() {
            $scope.pickerState.usingKeyboard = true;
            if ($scope.pickerState.activePaneName === paneNames['project']) {
              highlightItemInDirection(getFilteredProjects(), 'prev');
              return;
            }

            if ($scope.pickerState.activePaneName === paneNames['phase']) {
              highlightItemInDirection(getFilteredPhases(), 'prev');
              return;
            }

            if ($scope.pickerState.activePaneName === paneNames['category']) {
              highlightItemInDirection(getFilteredCategories(), 'prev');
              return;
            }
          },

          enter() {
            $scope.pickerState.usingKeyboard = true;
            handleEnterPress();
          },

          escape() {
            $scope.pickerState.usingKeyboard = true;
            handleEscapePress();
          },
        };

        $element.on(
          'mousemove',
          (e) => ($scope.pickerState.usingKeyboard = false)
        );

        // events -----------------------------------------------------

        // Retrieve the active pane from `tk-multi-pane`.
        const offUpdateActivePane = $scope.$on(
          'multiPane-updateActivePane',
          (e, data) => ($scope.pickerState.activePane = data)
        );

        // handle 'back button' click events from multiPane UI
        const offGoBack = $scope.$on('multiPane-goBack', function (e, data) {
          if (data) {
            for (var key in paneNames) {
              var val = paneNames[key];
              if (val === data) {
                activatePane(key);
              }
            }
          } else {
            // I can't find an easy way to tell the containing popover to close
            // so instead I'm triggering a click on the `body`
            $('body').trigger('click');
          }
        });

        // cleanup ----------------------------------------------------

        const cleanup = function () {
          // watchers
          unbindLoadedWatcher();
          unbindIsVisibleWatcher();
          unbindCategoryFilterWatcher();
          unbindPhaseFilterWatcher();
          unbindProjectFilterWatcher();
          unbindRecentProjectsWatcher();

          // event listeners
          offUpdateActivePane();
          offGoBack();
          resetChoices();

          // allow scrolling again
          $scope.enableWindowScroll();
        };

        // unbind all watch and event listeners
        $scope.$on('$destroy', function (e, data) {
          cleanup();
        });

        // unbind all watch and event listeners
        return $element.on('$destroy', function (e, data) {
          cleanup();
        });
      },
    };
  },
]);
