// everything about authenticating and establishing a session

angular.module('app.services').factory('TKSession', [
  '$rootScope',
  '$http',
  '$log',
  '$q',
  'DSCacheFactory',
  function ($rootScope, $http, $log, $q, DSCacheFactory) {
    const session = {};

    // TODO Make this line environment-agnostic
    const BASE_URL = window.API_BASE_URL;
    const KEY_SESSION_DATA = 'session_data';

    const sessionCache = DSCacheFactory('sessionCache', {
      storageMode: 'sessionStorage',
    });

    session.data = sessionCache.get(KEY_SESSION_DATA);
    session.connected = session.data ? true : false;

    session.signin = function (username, password) {
      const promise = $http
        .post(BASE_URL + '/sessions', { username, password })
        .success(function (data, status, headers, config) {
          $log.info('authenticated: ' + data);
          // exclude token from cached session
          sessionCache.put(KEY_SESSION_DATA, _.omit(data, 'token'));
          session.data = data;
          session.connected = true;
          $rootScope.$emit('sessionChanged', { session });
        })
        .error(function (data, status, headers, config) {
          $log.warn('got error: ' + status);
        });

      return promise;
    };

    session.initialize = function (data) {
      const deferred = $q.defer();

      if (data) {
        session.data = data;
        sessionCache.put(KEY_SESSION_DATA, data);
        session.connected = true;
      }

      if (session.connected === true) {
        $rootScope.$emit('sessionChanged', { session });
        deferred.resolve({ data: session.data });
        return deferred.promise;
      } else {
        $http
          .get(BASE_URL + '/sessions/me')
          .success(function (data, status, headers, config) {
            data = _.omit(data, 'token');
            $log.info('authenticated: ' + data);
            sessionCache.put(KEY_SESSION_DATA, data);
            session.data = data;
            session.connected = true;
            $rootScope.$emit('sessionChanged', { session });
            deferred.resolve({ data: session.data });
            return deferred;
          })
          .error(function (data, status, headers, config) {
            $log.warn('got error: ' + status);
          });

        return deferred.promise;
      }
    };

    session.signout = function () {
      sessionCache.remove(KEY_SESSION_DATA);
      session.data = null;
      session.connected = false;
      delete $http.defaults.headers.common.auth;
    };

    session.getList = function () {
      const deferred = $q.defer();
      $http
        .get(BASE_URL + '/sessions', { params: { auth: session.data.token } })
        .success(function (data, status, headers, config) {
          $log.info('got data: ' + data);
          deferred.resolve(data.data);
        })
        .error(function (data, status, headers, config) {
          deferred.reject(status);
        });

      return deferred.promise;
    };

    session.update = function (user_id) {
      const promise = $http
        .put(
          BASE_URL + '/sessions/unused-id',
          { user_id },
          { params: { auth: session.data.token } }
        )
        .success(function (data, status, headers, config) {
          // exclude token from cached session
          sessionCache.put(KEY_SESSION_DATA, _.omit(data, 'token'));
          session.data = data;
          $rootScope.$emit('sessionChanged', { session });
        })
        .error(function (data, status, headers, config) {
          $log.warn('got error: ' + status);
        });

      return promise;
    };

    return session;
  },
]);
