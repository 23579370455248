/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// This service provides account- and organization-related settings along with
// utility functions related to those settings

angular.module('app.services').factory('TKOrgSettings', [
  '$q',
  '$log',
  '$rootScope',
  'TKData',
  'TKDateUtil',
  'Restangular',

  function ($q, $log, $rootScope, TKData, TKDateUtil, Restangular) {
    const orgSettings = {};

    const deferred = $q.defer();

    orgSettings.promise = deferred.promise;

    const initialize = function () {
      // This is the data that the promise will resolve with
      //data = {}

      // How about the factory creates the object that the promise
      // resolves to so that it can be bound to a scope before it's
      // available. The promise is also available in case the user
      // needs notification that the data is available
      let processData;
      const data = orgSettings;

      data.holidaysByDate = {};
      const workingDays = [];

      const _isWorkdayCache1 = {};
      const _isWorkdayCache2 = {};

      data.isHoliday = (date) =>
        data.holidaysByDate[date.toDateString()] != null;

      data.isWorkday = function (date, treatCompanyHolidayAsWorkday) {
        // cache index
        const ci = date.getTime();
        let result = treatCompanyHolidayAsWorkday
          ? _isWorkdayCache1[ci]
          : _isWorkdayCache2[ci];

        if (result !== undefined) {
          return result;
        } else {
          result = false;

          if (workingDays[date.getDay()]) {
            if (treatCompanyHolidayAsWorkday || !data.isHoliday(date)) {
              result = true;
            }
          }

          if (treatCompanyHolidayAsWorkday) {
            _isWorkdayCache1[ci] = result;
          } else {
            _isWorkdayCache2[ci] = result;
          }
        }

        return result;
      };

      data.firstWorkdayOfWeek = (
        date //given = date
      ) => (date = TKDateUtil.toStartOfWeek(date, data.firstWorkingDay));
      // i = 0
      // maxDaysBack = 7;

      // while i < maxDaysBack && !data.isWorkday(date, true)
      //   date = TKDateUtil.addDays date, -1
      //   ++i

      // previous = TKDateUtil.addDays date, -1

      // while i < maxDaysBack && data.isWorkday(previous, true)
      //   date = previous
      //   ++i
      //   previous = TKDateUtil.addDays previous, -1

      // if i <= maxDaysBack
      //   date
      // else
      //   TKDateUtil.toStartOfWeek TKDateUtil.addDays(given, -1)

      const sortCategoriesAlphabetically = function (categories) {
        categories.sort(function (a, b) {
          if (a.category > b.category) {
            return 1;
          }
          if (a.category < b.category) {
            return -1;
          }
          return 0;
        });
        return categories;
      };

      const processAccountSettings = (settings) =>
        (() => {
          const result = [];
          for (var setting of Array.from(settings)) {
            if (!window.accountSettings[setting.name]) {
              result.push(
                (window.accountSettings[setting.name] = setting.value)
              );
            } else {
              result.push(undefined);
            }
          }
          return result;
        })();

      const { accountSettings } = window;
      if (accountSettings) {
        if (accountSettings.settings) {
          processAccountSettings(accountSettings.settings);
        }
        $q.all([
          TKData.getTimeEntryCategories(),
          TKData.getExpenseItemCategories(),
        ]).then(function (...args) {
          const [timeEntryCategories, expenseCategories] = Array.from(args[0]);
          return processData(
            accountSettings,
            window.accountSettings.holidays,
            timeEntryCategories,
            expenseCategories
          );
        });
      } else {
        $q.all([
          TKData.getSettings(),
          TKData.getHolidays(),
          TKData.getTimeEntryCategories(),
          TKData.getExpenseItemCategories(),
        ]).then(function (...args) {
          const [settings, holidays, timeEntryCategories, expenseCategories] =
            Array.from(args[0]);
          return processData(
            accountSettings,
            holidays,
            timeEntryCategories,
            expenseCategories
          );
        });
      }

      const settingsParams = [
        'first working day',
        'hours in workday',
        'time entry min step percent',
        'time entry max percent',
        'minimum hours reported',
        'allocate unit',
        'working days',
      ];

      return (processData = function (
        settings,
        holidays,
        timeEntryCategories,
        expenseCategories
      ) {
        data.timeEntryApprovals = parseInt(
          accountSettings['time_entry_approvals']
        );
        data.expenseApprovals = parseInt(accountSettings['expense_approvals']);
        data.firstWorkingDay = accountSettings['first_working_day']
          ? parseInt(accountSettings['first_working_day'])
          : parseInt(settingsParams[0]);
        data.hoursInWorkday = accountSettings['hours_in_workday']
          ? parseFloat(accountSettings['hours_in_workday'])
          : parseFloat(settingsParams[1]);
        data.timeEntryMinStepPercent = accountSettings[
          'time_entry_min_step_percent'
        ]
          ? parseFloat(accountSettings['time_entry_min_step_percent'])
          : parseFloat(settingsParams[2]);
        data.timeEntryMaxPercent = accountSettings['time_entry_max_percent']
          ? parseFloat(accountSettings['time_entry_max_percent'])
          : parseFloat(settingsParams[3]);
        data.minimumHoursReported = accountSettings['min_hours_report']
          ? parseFloat(accountSettings['min_hours_report'])
          : parseFloat(settingsParams[4]);
        data.allocateUnit = accountSettings['allocateunit']
          ? accountSettings['allocateunit']
          : settingsParams[5];
        data.workingDays = accountSettings['working_days']
          ? accountSettings['working_days']
          : settingsParams[6];
        data.holidays = Restangular.stripRestangular(holidays);
        data.budgetCategories =
          sortCategoriesAlphabetically(timeEntryCategories);
        data.expenseCategories =
          sortCategoriesAlphabetically(expenseCategories);
        data.timeEntryMaxHours = data.timeEntryMaxPercent * data.hoursInWorkday;
        data.timeEntryStepHours =
          data.timeEntryMinStepPercent * data.hoursInWorkday;
        data.allowBulkConfirmTimeEntries = parseInt(
          accountSettings['allow_bulk_confirm_time_entries']
        );
        const timeEntryMode = accountSettings['time entry mode'];
        data.timeEntryMode = function () {
          if (timeEntryMode === 'itemized') {
            return 'itemized';
          } else if (data.timeEntryMinStepPercent > 0) {
            return 'halfDays';
          } else {
            return 'hoursMinutes';
          }
        };

        // Extract working day flags into lookup table
        let mask = data.workingDays;
        for (let i = 0; i < 7; i++) {
          workingDays.push((mask & 1) === 1); // same as `(mask % 2) == 1`
          mask >>= 1;
        } // same as `mask = Math.floor(mask / 2)`

        // The "holidays" data structure will either come from window.accountSettings or from Restangular.  If it comes
        // from the server, we need to parse the date.  We look for a "getAllPages" field to indicate that Restangular loaded
        // the data.
        if (data.holidays[0] && data.holidays[0].getAllPages) {
          _.each(data.holidays, function (holiday) {
            holiday.date = TKDateUtil.parseRubyDate(holiday.date);
            return (data.holidaysByDate[holiday.date.toDateString()] = holiday);
          });
        }

        return deferred.resolve(data);
      });
    };

    if (TKData.authenticated) {
      initialize();
    } else {
      $rootScope.$on('authenticated', initialize);
    }

    return orgSettings;
  },
]);
