/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkModal', function () {
  const directiveDefinitionObject = {
    scope: {
      show: '=',
    },
    restrict: 'E',
    template:
      "<div class='modal' ng-show='show'><div class='modal-overlay' ng-click='hideModal()'></div><div class='modal-dialog' ng-style='dialogStyle' ng-transclude></div></div>",
    replace: true,
    transclude: true,
    link(scope, element, attrs) {
      scope.dialogStyle = {};
      if (attrs.width) {
        scope.dialogStyle.width = attrs.width;
      }
      if (attrs.height) {
        scope.dialogStyle.height = attrs.height;
      }
      return (scope.hideModal = () => (scope.show = false));
    },
  };

  return directiveDefinitionObject;
});
