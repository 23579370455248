/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Directive that provides an easy way to bind to keyboard events.
// Common buttons are mapped to english words for easier binding.
//
// By default `preventDefault()` will be called after the handler is called.
// If you don't want this behavior, add `tk-key-bind-prevent-default="false"`
// as an attr to your element.
//
// (ex: tk-key-bind="something" tk-key-bind-prevent-default="false")
//
// Also, you can set `tk-key-bind-prevent-default="'always'"` to only allow behavior
// that is defined in `tk-key-bind`. If you choose this option, but still want to
// selectively allow the default behavior for specific keys, you can define those
// keys with the string 'default', or you can pass an array that contains both a
// function and the string 'default' for cases where you want to handle default
// prevention conditionally in your own callback.

// NOTE: Make sure you add `tabindex="0"` to your element to ensure that keyboard
// events are captured.
//
angular.module('app').directive('tkKeyBind', function () {
  const directiveDefinitionObject = {
    restrict: 'A',
    scope: {
      map: '=tkKeyBind',
      tkKeyBindPreventDefault: '=',
    },
    link(scope, element, attrs) {
      // by default, assume preventDefault is required.
      const shouldPreventDefault = (() => {
        switch (scope.tkKeyBindPreventDefault) {
          case false:
            return false;
          case 'always':
            return 'always';
          default:
            return true;
        }
      })();

      const commonKeys = {
        40: 'down',
        38: 'up',
        37: 'left',
        39: 'right',
        13: 'enter',
        27: 'escape',
      };

      element.bind('keydown', function (event) {
        let callback =
          scope.map[event.which] || scope.map[commonKeys[event.which]];
        if (callback === 'default') {
          // noop
        } else if (_.isFunction(callback)) {
          callback(event);
          if (shouldPreventDefault) {
            event.preventDefault();
          }
        } else if (_.isArray(callback)) {
          let allowDefault;
          const callbacks = callback;
          for (callback of Array.from(callbacks)) {
            if (typeof callback === 'function') {
              callback(event);
            }
            if (callback === 'default') {
              allowDefault = true;
            }
          }
          if (shouldPreventDefault && !allowDefault) {
            event.preventDefault();
          }
        } else if (shouldPreventDefault === 'always') {
          if (scope.map['allowDefaultIf']) {
            if (!scope.map['allowDefaultIf'](event)) {
              event.preventDefault();
            }
          } else {
            event.preventDefault();
          }
        }

        if (typeof scope.map['all'] === 'function') {
          scope.map['all'](event);
        }
      });

      scope.$on('$destroy', function () {
        element.unbind('keydown');
      });

      element.on('$destroy', function () {
        element.unbind('keydown');
      });
    },
  };

  return directiveDefinitionObject;
});
