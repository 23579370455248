angular.module('app').animation('.submit-approvablesheet', function () {
  const animationDefinitionObject = {
    // enter
    // leave
    // move

    beforeAddClass(element, className, done) {
      // save height of current sheet, save child element
      const height = element.height();
      const children = element.children('.tk-approvablesheet__content');

      // set height of current sheet
      element.height(height);

      // animate opacity, then remove child elements
      children.animate({ opacity: 0 }, 250, function () {
        children.remove();
        done();
      });
    },

    addClass(element, className, done) {
      // setup style object
      const styles = {
        padding: '15px',
        height: '46px',
      };

      // animate styles, then add submitted class to directive
      element.animate(styles, 250, function () {
        element.addClass('tk-approvablesheet--submitted');
        done();
      });
    },
  };

  // beforeRemoveClass
  // removeClass
  // allowCancel

  return animationDefinitionObject;
});
