function validateLogEvent(eventType, featureArea) {
  if (!eventType && !featureArea) return false;

  // Enum values derived from EventLogging namespace in backend. Ensure that the values are synced
  var _EVENT_TYPES = ['Viewed', 'Clicked', 'Selected', 'Inserted'];
  var _FEATURE_AREAS = [
    'RM Analytics',
    'RM Approvals',
    'RM Assignment',
    'RM Authentication',
    'RM Integration Panel',
    'RM Login',
    'RM People Portfolio',
    'RM Schedule',
    'RM Time Tracker',
    'RM User Profile',
  ];

  return (
    _EVENT_TYPES.includes(eventType) && _FEATURE_AREAS.includes(featureArea)
  );
}

function handleLogEvent(opts) {
  var {
    eventType,
    featureArea,
    description,
    viewName,
    objectType,
    objectName,
    eventStart,
    eventEnd,
    actionType,
    actionName,
    additionalInfo,
  } = opts;

  if (!validateLogEvent(eventType, featureArea)) {
    console.error(
      'Unable to log user event: invalid event type or feature area param'
    );
    return;
  }

  window.clientEventLogger &&
    window.clientEventLogger.push({
      eventTimestamp: Date.now(),
      eventData: {
        eventType,
        featureArea,
        description,
        viewName,
        objectType,
        objectName,
        eventStart,
        eventEnd,
        actionType,
        actionName,
        additionalInfo,
      },
    });
}

angular.module('app').directive('tkEventLogging', function () {
  const directiveDefinitionObject = {
    restrict: 'A',
    link(scope, element, attrs) {
      if (element[0].nodeName.toLowerCase() === 'input') {
        return element.bind('keydown keypress', (event) => {
          if (event.which === 13) {
            handleLogEvent(scope.$eval(attrs.tkEventLogging));
            return event.preventDefault();
          }
        });
      }

      // Assuming it's a clickable object
      return element.bind('click', (event) => {
        handleLogEvent(scope.$eval(attrs.tkEventLogging));
        return event.preventDefault();
      });
    },
  };

  return directiveDefinitionObject;
});
