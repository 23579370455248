/*
 * decaffeinate suggestions:
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

angular.module('app').directive('tkApprovablesheet', [
  '$rootScope',
  '$log',
  'TKApprovals',
  '$animate',
  '$filter',
  function ($rootScope, $log, TKApprovals, $animate, $filter) {
    const directiveDefinitionObject = {
      restrict: 'E',
      templateUrl: 'ng-approvals/templates/approvablesheet',
      replace: true,
      controller: function ($scope, $element) {
        $scope.detailView = false;

        // Stores the approvals message, built when submitted
        $scope.approvalMessage = {};

        // Stores the time approvable ids of those that should be submitted
        const submitIds = {};

        const { approvables } = $scope.approvablesheet;

        $scope.approvalData = {
          approvables,
          submitIds,
        };

        $scope.I18n = I18n;

        $scope.approvablesheet.userId = approvables[0].user_id;

        const resetDetails = function () {
          _.each(approvables, function (approvable) {
            submitIds[approvable.id] = true;
          });
        };

        resetDetails();

        $scope.toggleDetailView = function () {
          if ($scope.detailView) {
            resetDetails();
          }
          $scope.detailView = !$scope.detailView;
          $scope.$broadcast('tk-expand-all', $scope.detailView);
        };

        // TODO:
        // - Store approve flags for all assignables
        // - Listen for approval state change by assignable
        // - On submit approval, filter by flags

        // Represents if this approvablesheet has been submitted
        $scope.submitted = false;

        $scope.submitApproval = function () {
          const { approvablesheet } = $scope;

          // Determine status based on approval filter value
          const newStatus = (() => {
            switch (false) {
              case !$scope.showsPending():
                return 'approved';
              case !$scope.showsApproved():
                return 'pending';
              case !$scope.showsUnsubmitted():
                return 'pending';
              case !$scope.showsRejected():
                return 'pending';
            }
          })();

          if (newStatus) {
            // Save new array approved approvables
            const filteredApprovables = _.filter(
              approvables,
              (approvable) => submitIds[approvable.id]
            );

            // Submit for approved approvables for approval
            TKApprovals.setApprovablesStatus(
              $scope.userId,
              filteredApprovables,
              newStatus,
              $scope.showsApproved(),
              approvablesheet.type() === 'timesheet'
                ? 'TimeEntry'
                : 'ExpenseItem'
            ).then(function ({ data }) {
              const successfulCount = _.filter(data, {
                status: newStatus,
              }).length;
              const expectedCount = filteredApprovables.length;
              if (successfulCount < expectedCount) {
                $scope.approvalMessage.count = `${successfulCount} of ${expectedCount}`;
                $scope.approvalMessage.promptRefresh = true;
              }
            });

            // Set approvalMessage values for template
            $scope.approvalMessage = {
              displayName: approvablesheet.user.display_name,
              count: filteredApprovables.length,
              promptRefresh: false,
            };

            if (approvablesheet.type() === 'timesheet') {
              $scope.approvalMessage.type =
                $scope.approvalMessage.count > 1
                  ? 'time entries'
                  : 'time entry';
            } else {
              $scope.approvalMessage.type =
                $scope.approvalMessage.count > 1 ? 'expenses' : 'expense';
            }

            if (newStatus === 'pending' && $scope.showsApproved()) {
              $scope.approvalMessage.approvalType = 'unlocked';
            } else if (newStatus === 'pending') {
              $scope.approvalMessage.approvalType = 'submitted';
            } else if (newStatus === 'pending' && $scope.showsRejected()) {
              $scope.approvalMessage.approvalType = 'rejected';
            } else {
              $scope.approvalMessage.approvalType = 'approved';
            }

            if (approvablesheet.type() === 'timesheet') {
              const dateFilter = $filter('tkDate');
              const { startDate, endDate } = approvablesheet;
              const startDateString = `${dateFilter(
                startDate,
                'abbrMonthName'
              )} ${dateFilter(startDate, 'dayDate')}`;
              const endDateString = `${dateFilter(
                endDate,
                'abbrMonthName'
              )} ${dateFilter(endDate, 'dayDate')}`;
              $scope.approvalMessage.dateRange = `${startDateString} - ${endDateString}`;
            }

            // TODO: Update the time entries correctly
            // this temporary measure assumes success and doesn't
            // update other fields that could potentially have changed
            // Neither does it handle errors etc
            _.each(filteredApprovables, function (approvable) {
              if (approvable.approvals == null) {
                approvable.approvals = {};
              }
              if (approvable.approvals.data == null) {
                approvable.approvals.data = [];
              }
              approvable.approvals.data[0] = {
                status: newStatus,
                submitted_by: $scope.userId,
              };
            });

            // Do animation regardless if they submitted some or all approvables
            // dont show leftover time/expenses
            $animate.addClass($element, 'submit-approvablesheet', function () {
              $scope.$apply(function () {
                $scope.submitted = true;
                $rootScope.$broadcast('tk-approvablesheet-submitted');
              });
            });
          }
        };
      },
    };

    return directiveDefinitionObject;
  },
]);
