/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// A basic service to make localStorage slightly easier. This module will
// prevent localStorage namespace conflicts (i.e. using the same localStorage
// key in different modules) and provide a simple API for getting/setting.
//
// All resources are objects (not strings or arrays).
angular.module('app.services').factory('TKLocalStorage', [
  '$window',
  '$log',
  function ($window, $log) {
    let ls;
    const ds = {};

    try {
      ls = $window.localStorage;
      ls.setItem('test', 'test');
      ls.removeItem('test');
    } catch (error) {
      ls = {
        getItem(key) {
          return this[key];
        },
        setItem(key, val) {
          this[key] = `${val}`;
        },
        removeItem(key) {
          delete this[key];
        },
      };
    }

    // Checks if the incoming string is valid JSON, simply by checking if the
    // first character of the string is '[' or '}'
    // @param {string} data The data string to validate.
    // @return True if the string passes the simple JSON validation check,
    //         False if the string does not.
    const isValidJSON = function (data) {
      if (!data || (data.indexOf('[') !== 0 && data.indexOf('{') !== 0)) {
        $log.warn('Resource is not a valid JSON object.');
        return false;
      }

      return true;
    };

    // Retrieves data from localStorage and runs a basic test to ensure the
    // data is valid JSON before parsing.
    // @param {string} key The localStorage resource key to retrieve.
    const getResource = function (key) {
      const data = ls.getItem(key);

      if (isValidJSON(data)) {
        return JSON.parse(ls.getItem(key));
      }

      // else...
      return {};
    };

    // Saves contents to localStorage.
    // @param {string} key The localStorage key to get/set contents to.
    // @param {object} value The contents to be JSON stringified into localStorage.
    const setResource = (key, value) => ls.setItem(key, JSON.stringify(value));

    // A Resource class. This is generated and returned
    // @constructor
    class Resource {
      constructor(lsKey) {
        // do not overwrite existing data
        this.lsKey = lsKey;
        if (getResource(this.lsKey) === null) {
          ls.setItem(this.lsKey, JSON.stringify({}));
        }
      }

      // sets a key in the datastore object
      set(key, value) {
        if (key === undefined) {
          $log.warn('no key provided');
          return;
        }

        const data = getResource(this.lsKey);

        data[key] = value;
        return setResource(this.lsKey, data);
      }

      // gets the value of a key in the datastore
      get(key) {
        if (key === undefined) {
          $log.warn('no key provided');
          return;
        }

        const data = getResource(this.lsKey);

        return data[key];
      }
    }

    // Creates and returns a Resource object.
    // @return {object} An instance of Resource.
    ds.createResource = function (key) {
      if (ds[key]) {
        $log.warn('There is already a resource named ' + key);
        return ds[key];
      }

      ds[key] = new Resource(key);

      return ds[key];
    };

    ds.getLocalStorageObject = () => ls;

    return ds;
  },
]);
