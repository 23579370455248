angular.module('app').directive('tkFilterSidebar', [
  '$log',
  '$q',
  'TKData',
  'TKDateUtil',
  'TKOrgSettings',
  ($log, $q, TKData, TKDateUtil, TKOrgSettings) => ({
    restrict: 'E',
    templateUrl: 'ng-approvals/templates/filterSidebar',
    replace: true,

    scope: {
      userId: '=',
      filterSet: '=',
    },

    controller: function ($scope) {
      const orgSettings = null;
      $scope.I18n = I18n;
    },
  }),
]);
