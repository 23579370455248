/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app.services').factory('TKPlaceholderRowManager', [
  'TKDateUtil',
  'TKAssignables',
  'TKLocalStorage',
  function ({ toStartOfDay }, TKAssignables, TKLocalStorage) {
    let getLocalStorageData;
    const VERSION = 1;
    const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;
    const LS_KEY = 'persistingTimeEntryRows';
    const RANGE_KEY_SEPARATOR = ' - ';

    const localStorage = TKLocalStorage.getLocalStorageObject();

    class PersistingRows {
      constructor(categoriesByAssignableId, assignableIdsByType) {
        this.categoriesByAssignableId = categoriesByAssignableId;
        this.assignableIdsByType = assignableIdsByType;
      }

      getPersistingRowsAsArray() {
        return (this.array = _.reduce(
          this.categoriesByAssignableId,
          (array, categories, assignableId) =>
            array.concat(
              categories.map((category) => [assignableId, category])
            ),
          []
        ));
      }

      deleteRow(assignableId, category) {
        const categories = this.categoriesByAssignableId[assignableId];
        category = category || null;
        _.pull(categories, category);
        this.array = null;
        if (!(categories != null ? categories.length : undefined)) {
          delete this.categoriesByAssignableId[assignableId];
          _.pull(this.assignableIdsByType['Project'], +assignableId);
          _.pull(this.assignableIdsByType['LeaveType'], +assignableId);
        }
        saveToLocalStorage();
      }

      toJSON() {
        return {
          categoriesByAssignableId: this.categoriesByAssignableId,
          assignableIdsByType: this.assignableIdsByType,
        };
      }
    }

    const isNewWeek = (dateOfSave, today) =>
      dateOfSave.getDay() > today.getDay() ||
      today.getTime() - dateOfSave.getTime() >= ONE_WEEK;

    const dataIsFresh = function (data) {
      if (data.version !== VERSION) {
        return false;
      }
      const dateOfSave = new Date(+data.timestamp);
      const today = toStartOfDay(new Date());
      return !isNewWeek(dateOfSave, today);
    };

    const removeFromLocalStorage = function () {
      localStorage.removeItem(LS_KEY);
    };

    const rowsByDateRange = {};

    (getLocalStorageData = function () {
      const dataString = localStorage[LS_KEY];
      if (dataString) {
        const data = JSON.parse(dataString);
        if (dataIsFresh(data)) {
          _.each(data.rows, function (rows, dateRangeKey) {
            rowsByDateRange[dateRangeKey] = new PersistingRows(
              rows.categoriesByAssignableId,
              rows.assignableIdsByType
            );
          });
        } else {
          removeFromLocalStorage();
        }
      }
    })();

    var saveToLocalStorage = function () {
      localStorage[LS_KEY] = JSON.stringify({
        rows: rowsByDateRange,
        timestamp: toStartOfDay(new Date()).getTime(),
        version: VERSION,
      });
    };

    const stringifyRange = (dateRange) =>
      '' +
      dateRange[0].getTime() +
      RANGE_KEY_SEPARATOR +
      dateRange[1].getTime();

    const getRowsByDate = function (date) {
      date = '' + date.getTime();
      for (var dateRangeKey in rowsByDateRange) {
        var rows = rowsByDateRange[dateRangeKey];
        var range = dateRangeKey.split(RANGE_KEY_SEPARATOR);
        if (range[0] <= date && date < range[1]) {
          return rows;
        }
      }
    };

    const addKnownPlaceholders = function (
      categoriesByAssignableId,
      dateRange
    ) {
      const previousWeek = __guard__(
        getRowsByDate(new Date(dateRange[0].getTime() - ONE_WEEK)),
        (x) => x.categoriesByAssignableId
      );
      _.assign(categoriesByAssignableId, previousWeek, _.union);
    };

    const getAssignableIdsByType = (categoriesByAssignableId) =>
      _.reduce(
        categoriesByAssignableId,
        function (result, cats, assignableId) {
          if (TKAssignables.leaveTypesById[assignableId]) {
            result['LeaveType'].push(+assignableId);
          } else {
            result['Project'].push(+assignableId);
          }
          return result;
        },
        { LeaveType: [], Project: [] }
      );

    const TKPlaceholderRowManager = {};

    TKPlaceholderRowManager.persistRows = function (
      categoriesByAssignableId,
      dateRange
    ) {
      categoriesByAssignableId = _.cloneDeep(categoriesByAssignableId);
      addKnownPlaceholders(categoriesByAssignableId, dateRange);
      rowsByDateRange[stringifyRange(dateRange)] = new PersistingRows(
        categoriesByAssignableId,
        getAssignableIdsByType(categoriesByAssignableId)
      );
      saveToLocalStorage();
    };

    TKPlaceholderRowManager.getPersistingRowsAsArray = function (date) {
      const rows = getRowsByDate(date);
      if (rows) {
        return rows.getPersistingRowsAsArray();
      } else {
        return [];
      }
    };

    TKPlaceholderRowManager.getPersistingAssignableIdsByType = function (date) {
      const rows = getRowsByDate(date);
      if (rows) {
        return rows.assignableIdsByType;
      } else {
        return {};
      }
    };

    TKPlaceholderRowManager.deleteRow = function (
      date,
      assignableId,
      category
    ) {
      const rows = getRowsByDate(date);
      if (rows) {
        rows.deleteRow(assignableId, category);
      }
      saveToLocalStorage();
    };

    return TKPlaceholderRowManager;
  },
]);
