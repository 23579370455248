// Focus on creation
angular.module('app').directive('tkSelectOnFocus', [
  '$timeout',
  ($timeout) => ({
    restrict: 'A',

    link(scope, element, attrs) {
      const select = function () {
        $timeout(function () {
          element.select();
        });
      };

      element.on('focus', select);

      element.on('$destroy', function () {
        element.off('focus', select);
      });
    },
  }),
]);
