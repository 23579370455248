/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkExpenseEntry', [
  '$log',
  '$q',
  '$filter',
  '$parse',
  'TKData',
  'TKDateUtil',
  'TKOrgSettings',
  'TKCleanup',
  (
    $log,
    $q,
    $filter,
    $parse,
    TKData,
    TKDateUtil,
    TKOrgSettings,
    TKCleanup
  ) => ({
    restrict: 'E',
    require: '^tkExpenseTracker',
    templateUrl: 'ng-approvals/templates/expenseTracker/expenseEntry',

    controller: function ($scope, $element, $attrs) {
      let onClick;
      const ENTER_KEYCODE = 13;
      const EMPTY_CATEGORY = '[leave blank]'; // TODO abstract this to a common location, maybe `tkConstants`
      const MAX_AMOUNT = Math.pow(10, 38);

      const cleanup = TKCleanup.newCleanup($scope);

      cleanup(
        $scope.$watch($attrs.expense, function (expense) {
          $scope.existing = expense;
          return bindModel($scope.existing);
        })
      );

      if ($attrs.onClick) {
        onClick = $parse($attrs.onClick);
      }

      // TODO use this boolean to control spinner visibility
      $scope.requestInProgress = false;

      var bindModel = function (expense) {
        if (expense == null) {
          expense = {};
        }
        $scope.modelData = {
          date: TKDateUtil.parseRubyDate(expense.date) || new Date(),
          notes: expense.notes || '',
          amount: expense.amount || '',
          assignable: $scope.assignablesById[expense.assignable_id],
          category: { category: expense.category },
        };
      };

      bindModel();

      const localizedDecimal = I18n.lookup('number').format.separator;
      const localizedPrecision = I18n.lookup('number').format.precision;
      const localizedCurrency = I18n.lookup('number.currency').format.unit;

      const parseAmount = function (amount) {
        amount = `${amount}`
          .replace(localizedCurrency, '')
          .replace(localizedDecimal, '.');
        return parseFloat(amount);
      };

      // called on the keypress event, which only fires when there is a character input
      $scope.validateAmountInput = (function () {
        const allowedChars =
          `0 1 2 3 4 5 6 7 8 9 . - ${localizedDecimal} ${localizedCurrency
            .split('')
            .join(' ')}`.split(' ');

        const decimalPattern = /\,|\./;

        const isAllowedChar = ($event) =>
          _.contains(allowedChars, String.fromCharCode($event.charCode));

        const isAllowedCursorPosition = function ($event) {
          const { selectionStart, selectionEnd, value } = $event.target;
          const textIsSelected = selectionStart !== selectionEnd;
          const charsAfterDecimal = value.split(decimalPattern)[1] || '';
          const maxDecimalPlacesReached =
            charsAfterDecimal.length === localizedPrecision;
          const decimalIndex = _.findIndex(value.split(''), (char) =>
            char.match(decimalPattern)
          );
          const isEditingDecimalValue = selectionStart > decimalIndex;
          const tooManyDecimalPlaces =
            maxDecimalPlacesReached && isEditingDecimalValue;
          return !tooManyDecimalPlaces || textIsSelected;
        };

        const isAllowed = ($event) =>
          isAllowedChar($event) && isAllowedCursorPosition($event);

        return function ($event) {
          if (!isAllowed($event)) {
            $event.preventDefault();
          }
        };
      })();

      // add I18n for translations
      $scope.I18n = I18n;

      $scope.saveOnEnter = function ($event) {
        if ($event.keyCode !== ENTER_KEYCODE) {
          return;
        }
        if ($scope.existing) {
          $scope.update($event, $scope.existing);
        } else {
          $scope.create($event);
        }
      };

      const updateExpense = function (expense) {
        expense.date = TKDateUtil.toRubyDate($scope.modelData.date);
        expense.assignable_id =
          $scope.modelData.assignable != null
            ? $scope.modelData.assignable.id
            : undefined;
        expense.notes = $scope.modelData.notes;
        expense.amount = parseAmount($scope.modelData.amount);
        expense.category =
          $scope.modelData.category != null
            ? $scope.modelData.category.category
            : undefined;
        if (expense.category === EMPTY_CATEGORY) {
          expense.category = null;
        }
        expense.user_id = $scope.userId;
        return expense;
      };

      const expenseValid = function (expense) {
        // If no expense was passed, validate the current form data
        if (expense == null) {
          expense = updateExpense({});
        }
        return (
          expense.amount &&
          expense.assignable_id &&
          expense.date &&
          expense.amount < MAX_AMOUNT
        );
      };

      const notifyUserOfInvalidExpenseData = function (expense) {
        // TODO replace alerts with html
        if (!expense.assignable_id) {
          alert('Please select a project');
        } else if (expense.amount == null || _.isNaN(expense.amount)) {
          alert('Please enter an amount');
        } else if (expense.amount >= MAX_AMOUNT) {
          alert(
            'Expense amount is too large. Must be under $100,000,000,000,000,000,000,000,000,000,000,000,000'
          );
        } else if (!expense.date) {
          alert('Please select a date');
        }
      };

      const handleError = function (error) {
        // TODO handle errors better
        $scope.requestInProgress = false;
        alert('We were unable to save your expense.');
      };

      $scope.create = function ($event) {
        if ($scope.requestInProgress) {
          return;
        }
        const expense = updateExpense({});

        const handleCreatedExpense = function (expense) {
          $scope.requestInProgress = false;
          $scope.recentExpenses.unshift(expense);
          // $log.info "Created expense", expense
          bindModel();
        };

        if (expenseValid(expense)) {
          $scope.requestInProgress = true;
          TKData.createExpense(expense).then(handleCreatedExpense, handleError);
        } else {
          notifyUserOfInvalidExpenseData(expense);
        }
      };

      $scope.update = function ($event, expense) {
        if ($scope.requestInProgress) {
          return;
        }

        if (expenseValid()) {
          const oldAssignable = expense.assignable_id;
          updateExpense(expense);
          const newAssignable = expense.assignable_id;
          // Can only update an existing expense if the assignable_id hasn't changed,
          // otherwise the API requires that it be deleted and a new one created
          if (oldAssignable === newAssignable) {
            $scope.requestInProgress = true;
            TKData.updateExpense(expense).then(function (expenseFromServer) {
              $scope.requestInProgress = false;
              expense.updated_at = expenseFromServer.updated_at;
              onClick($scope, { $action: 'close' });
            }, handleError);
          } else {
            $scope.requestInProgress = true;
            TKData.deleteExpense(expense).then(function () {
              delete expense.id;
              TKData.createExpense(expense).then(function (expenseFromServer) {
                $scope.requestInProgress = false;
                expense.id = expenseFromServer.id;
                onClick($scope, { $action: 'close' });
              }, handleError);
            }, handleError);
          }
        } else {
          notifyUserOfInvalidExpenseData(expense);
        }
      };

      $scope.delete = function ($event, expense) {
        if ($scope.requestInProgress) {
          return;
        }
        $scope.requestInProgress = true;
        TKData.deleteExpense(expense).then(function () {
          $scope.requestInProgress = false;
          onClick($scope, { $action: 'delete' });
        }, handleError);
      };

      return ($scope.close = function ($event) {
        onClick($scope, { $action: 'close' });
      });
    },
  }),
]);
