angular.module('app.services').factory('TKAnalytics', [
  'TKLocalStorage',
  function (TKLocalStorage) {
    const TKAnalytics = {};

    const localStorage = TKLocalStorage.getLocalStorageObject();

    const pageTracker = (action) =>
      function () {
        const url = this._urlTemplate({
          action,
          weekOrDay:
            localStorage.tkApprovablesTrackerDayCount === '1' ? 'day' : 'week',
        });
        //  "TRACKING PAGE #{url}"console.log
        // assume ga has been pre-loaded in the enclosing page
        report_page_view(url);
      };

    TKAnalytics.timeEntry = {
      _urlTemplate: _.template('/time-entry/<%= weekOrDay %>/<%= action %>'),
      create: pageTracker('new'),
      update: pageTracker('update'),
      delete: pageTracker('delete'),
      expandAll: pageTracker('expand-all'),
      collapseAll: pageTracker('collapse-all'),
      clearSuggestions: pageTracker('clear-suggestions'),
      restoreSuggestions: pageTracker('restore-suggestions'),
      reportTimeWithNoCategory: pageTracker('no-category'),
      reportTimeWithCategory: pageTracker('category'),
      addNote: pageTracker('add-note'),
      confirmTime: pageTracker('confirm-time'),
      startTimer: pageTracker('start-timer'),
      stopTimer: pageTracker('stop-timer'),
      clearTimer: pageTracker('clear-timer'),
    };

    TKAnalytics.expenses = {
      _urlTemplate: _.template('/expense/<%= action %>'),
      create: pageTracker('new'),
      update: pageTracker('update'),
      delete: pageTracker('delete'),
    };

    return TKAnalytics;
  },
]);
