/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkStopEvent', function () {
  let directiveDefinitionObject;
  return (directiveDefinitionObject = {
    restrict: 'A',
    link(scope, element, attr) {
      return element.bind('click', (e) => e.stopPropagation());
    },
  });
});
