/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Thank you TWBS :D https://github.com/twbs/bootstrap/blob/master/js/transition.js

// CSS TRANSITION SUPPORT (Shoutout: http://www.modernizr.com/)
// ============================================================

const transitionEnd = function () {
  const el = document.createElement('bootstrap');

  const transEndEventNames = {
    WebkitTransition: 'webkitTransitionEnd',
    MozTransition: 'transitionend',
    OTransition: 'oTransitionEnd otransitionend',
    transition: 'transitionend',
  };

  for (var key in transEndEventNames) {
    var value = transEndEventNames[key];
    if (el.style[key] !== undefined) {
      return { end: value };
    }
  }

  return false; // explicit for ie8 (  ._.)
};

// converts a time string like "0.5s" or "12ms" to milliseconds.
// This doesn't have any logic to handle multiple comma separated time strings,
// which may occur when describing multiple transitions in CSS.
$.support.timeStringToMs = function (timeString) {
  // guardian: if number is passed in, assume milliseconds and
  // return timeString unchanged.
  if (typeof timeString === 'number') {
    return timeString;
  }

  if (typeof timeString === 'string') {
    if (timeString.indexOf('ms') > -1) {
      // Milliseconds is the smallest unit. Integers only.
      // Fractions get parsed to 0.
      return parseInt(timeString, 10);
    }

    if (timeString.indexOf('s') > -1) {
      return parseFloat(timeString, 10) * 1000;
    }

    // assume milliseconds if no unit is given.
    return parseInt(timeString, 10);
  }
};

// http://blog.alexmaccaw.com/css-transitions
//
// Custom jquery function to emulate the `transitionend` callback if for some
// reason the browser doesn't fire `tkTransitionEnd`.
// usage:
//   if $.support.transition
//     $foo.on('tkTransitionEnd, () ->
//       # do something
//     )
//       .emulateTransitionEnd(cssTransitionDuration + 50)
//       .css(# change CSS to create transition)
//
$.fn.emulateTransitionEnd = function (duration) {
  let called = false;
  const $el = this;

  $(this).one('tkTransitionEnd', () => (called = true));

  const callback = function () {
    if (!called) {
      return $($el).trigger($.support.transition.end);
    }
  };

  setTimeout(callback, duration);
  return this;
};

// 1. Creates $.support.transition
// 2. Creates the `tkTransitionEnd` special event if CSS3 `transitionend` events
//    are supported.
$(function () {
  $.support.transition = transitionEnd();

  if (!$.support.transition) {
    return;
  }

  // custom special event: tkTransitionEnd.
  // You can use this to replace all other 'transitionend' events.
  return ($.event.special.tkTransitionEnd = {
    bindType: $.support.transition.end,
    delegateType: $.support.transition.end,
    handle(e) {
      if ($(e.target).is(this)) {
        return e.handleObj.handler.apply(this, arguments);
      }
    },
  });
});
