// used with file inputs <input type="file" tk-image-file-model="myData.imgFile">
angular.module('app').directive('tkImageFileModel', [
  '$parse',
  ($parse) => ({
    restrict: 'A',

    link(scope, element, attrs) {
      const model = $parse(attrs.tkImageFileModel);
      const onUpload = $parse(attrs.onUpload);
      const reader = new FileReader();
      reader.onload = function (e) {
        const dataURL = e.target.result;
        scope.$apply(function () {
          model.assign(scope, dataURL);
          if (typeof onUpload === 'function') {
            onUpload(scope, e);
          }
        });
      };

      element.bind('change', function () {
        reader.readAsDataURL(element[0].files[0]);
      });
    },
  }),
]);
