/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app.services').factory('TKApprovals', [
  '$q',
  'TKData',
  'TKOrgSettings',
  'TKDateUtil',
  function ($q, TKData, TKOrgSettings, TKDateUtil) {
    const DATE_RANGE_SPLITTER = ' - ';

    const TKApprovals = {};

    const cache = {
      // The cache is structured first by the `tkDataMethodName` ("getApprovals" and "getEveryonesTimeEntries")
      // then, within each of those namespaces, we save the promises returned by `TKData` by date range (weeks).
    };
    const getDateRangeKey = (dateRange) =>
      dateRange.map(TKDateUtil.toRubyDate).join(DATE_RANGE_SPLITTER);
    let pendingDeferred = null;

    // Whenever `TKApprovals.setApprovablesStatus` is called,
    // `invalidateCacheWithDates` is given all of the dates represented
    // in that set of approvables, and we scan the cache and
    // invalidate any date range that contains one of the dates.
    const invalidateCacheWithDates = function (dates) {
      _.each(dates, function (date) {
        date = date.getTime();
        _.each(cache, function (obj, namespace) {
          _.each(cache[namespace], function (val, key) {
            if (val) {
              const [rangeStart, rangeEnd] = Array.from(
                key.split(DATE_RANGE_SPLITTER).map(TKDateUtil.parseRubyDate)
              );
              if (rangeStart.getTime() <= date && date <= rangeEnd.getTime()) {
                cache[namespace][key] = null;
              }
            }
          });
        });
      });
    };

    const getChunkedCollection = function (
      tkDataMethodName,
      startDate,
      endDate
    ) {
      let getNextChunk;
      const CHUNK_SIZE_WEEK_COUNT = 1;
      const SEMI_STALE_THRESHOLD = 30 * 1000; // Thirty seconds
      const MAX_CACHE_AGE = 60 * 1000; // One minute

      const deferred = $q.defer();
      if (pendingDeferred) {
        // We only allow one pending request at a time.
        // When a new request is made while another is still pending
        // we reject the pending promise and halt the pending request
        // and then start the new request.
        pendingDeferred.reject();
        pendingDeferred.rejected = true;
      }
      pendingDeferred = deferred;
      cache[tkDataMethodName] = cache[tkDataMethodName] || {};
      const collection = [];

      // We fetch and cache data by full work weeks, even if slightly outside the actual date range.
      startDate = TKOrgSettings.firstWorkdayOfWeek(startDate);
      const dateChunks = TKDateUtil.getDateChunks(
        startDate,
        endDate,
        CHUNK_SIZE_WEEK_COUNT,
        'week'
      );
      (getNextChunk = function () {
        // We recursively fetch and cache each week's data, starting with the most recent week
        // as long as the promise is valid, and notify along the way via the promise.
        let freshPromise, promise;
        if (deferred.rejected) {
          return;
        }
        const chunk = dateChunks.pop();
        const dateRangeKey = getDateRangeKey(chunk);

        const cachedPromise = cache[tkDataMethodName][dateRangeKey];
        const cachedPromiseAge =
          _.now() -
          (cachedPromise != null ? cachedPromise.timestamp : undefined);
        const cachedPromiseIsGettingStale =
          cachedPromiseAge > SEMI_STALE_THRESHOLD;
        const cachedPromiseIsFreshEnough = cachedPromiseAge < MAX_CACHE_AGE;
        const getFreshPromise = () =>
          TKData[tkDataMethodName](chunk[0], chunk[1]);

        if (cachedPromiseIsGettingStale && !cachedPromise.refreshPending) {
          // Initiate cache refresh, but don't replace the cached promise
          // until the refresh completes.
          freshPromise = getFreshPromise();
          cachedPromise.refreshPending = true;
          const updateCache = function () {
            freshPromise.timestamp = _.now();
            cache[tkDataMethodName][dateRangeKey] = freshPromise;
            cachedPromise.refreshPending = false;
          };
          const cancelUpdate = function () {
            cachedPromise.refreshPending = false;
          };
          freshPromise.then(updateCache, cancelUpdate);
        }

        if (cachedPromiseIsFreshEnough) {
          // A cache refresh may be in progress, but we use what we have in hand
          // as long as it is not too old.
          promise = cachedPromise;
        } else {
          // If the cached promise is too old (or does not exist),
          // replace it immediately with a fresh promise.
          freshPromise = getFreshPromise();
          freshPromise.timestamp = _.now();
          cache[tkDataMethodName][dateRangeKey] = freshPromise;
          promise = freshPromise;
        }

        return promise.then(function (collectionChunk) {
          if (deferred.rejected) {
            return;
          }
          collection.push(...Array.from(collectionChunk || []));
          if (dateChunks.length) {
            deferred.notify(collection);
            getNextChunk();
          } else {
            deferred.resolve(collection);
            pendingDeferred = null;
          }
        });
      })();

      return deferred.promise;
    };

    TKApprovals.getApprovals = (startDate, endDate) =>
      getChunkedCollection('getApprovals', startDate, endDate);

    TKApprovals.getEveryonesTimeEntries = (startDate, endDate) =>
      getChunkedCollection('getEveryonesTimeEntries', startDate, endDate);

    TKApprovals.setApprovablesStatus = function (
      userId,
      approvables,
      status,
      unapprove,
      type
    ) {
      const approvablesDates = _.tk
        .pluckUnique(approvables, 'date')
        .map((date) => TKDateUtil.parseRubyDate(date));
      invalidateCacheWithDates(approvablesDates);
      return TKData.setApprovablesStatus(
        userId,
        approvables,
        status,
        unapprove,
        type
      );
    };

    return TKApprovals;
  },
]);
