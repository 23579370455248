angular.module('app').directive('tkDraggable', [
  '$document',
  '$timeout',
  function ($document, $timeout) {
    const distanceBetween = function (x1, y1, x2, y2) {
      const aSquared = Math.pow(x1 - x2, 2);
      const bSquared = Math.pow(y1 - y2, 2);
      const cSquared = aSquared + bSquared; // Pythagorean theorem
      return Math.sqrt(cSquared);
    };

    return {
      restrict: 'A',
      scope: {
        onDragstart: '&',
        onDrag: '&',
        onDragend: '&',
      },
      link(scope, el, attrs) {
        const MINIMUM_DRAG_THRESHOLD = 4;

        let dragging = false;
        let previousX = null;
        let previousY = null;

        // TODO Add support for touch devices

        const getData = function (evnt) {
          const { clientX, clientY } = evnt;
          const data = {
            x: clientX,
            y: clientY,
            dx: clientX - previousX,
            dy: clientY - previousY,
          };
          previousX = clientX;
          previousY = clientY;
          return data;
        };

        const startDragging = function (evnt) {
          dragging = true;
          scope.$apply(function () {
            scope.onDragstart(getData(evnt));
          });
        };

        const continueDragging = function (evnt) {
          scope.$apply(function () {
            scope.onDrag(getData(evnt));
          });
        };

        const drag = function (evnt) {
          evnt.preventDefault();
          if (dragging) {
            continueDragging(evnt);
          } else if (
            distanceBetween(previousX, previousY, evnt.clientX, evnt.clientY) >
            MINIMUM_DRAG_THRESHOLD
          ) {
            startDragging(evnt);
          }
        };

        var endDragging = function (evnt) {
          if (dragging) {
            dragging = false;
            scope.$apply(function () {
              scope.onDragend(getData(evnt));
            });
          }
          $document.unbind('mousemove', drag);
          $document.unbind('mouseup', endDragging);
        };

        el.on('mousedown', function (evnt) {
          previousX = evnt.clientX;
          previousY = evnt.clientY;
          $document.on('mousemove', drag);
          $document.on('mouseup', endDragging);
        });
      },
    };
  },
]);
