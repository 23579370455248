/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Render a bar version of a pie chart
// Params are a data set and the pixel width of the element
angular.module('app').directive('tkBarRatios', [
  '$log',
  '$q',
  ($log, $q) => ({
    restrict: 'E',
    templateUrl: 'ng-approvals/templates/barRatios',
    replace: true,

    scope: {
      borderWidth: '=?',
      width: '=?',
      spacing: '=?',
      data: '=',
      colors: '=',
      innerColors: '=?',
      maxDataWidth: '=?',
    },

    controller: function ($scope) {
      if ($scope.width == null) {
        $scope.width = 100;
      }
      if ($scope.spacing == null) {
        $scope.spacing = 1;
      }
      if ($scope.borderWidth == null) {
        $scope.borderWidth = 1;
      }
      if ($scope.innerColors == null) {
        $scope.innerColors = [];
      }

      // Comparison of only relevant members
      const outputsEqual = function (a, b) {
        if (a.length !== b.length) {
          return false;
        }

        let equal = true;
        _.each(a, function (data, i) {
          const data2 = b[i];
          if (
            data.left !== data2.left ||
            data.width !== data2.width ||
            data.color !== data2.color
          ) {
            equal = false;
          }
        });

        return equal;
      };

      return ($scope.bars = function () {
        // Calculate the total value of all data values
        const colors = _.filter(
          $scope.colors,
          (value, index) => $scope.data[index] > 0
        );
        const innerColors = _.filter(
          $scope.innerColors,
          (value, index) => $scope.data[index] > 0
        );
        let data = _.filter($scope.data, (value) => value > 0);

        const total = _.tk.sum(data);

        let availableWidth = $scope.maxDataWidth
          ? Math.round(($scope.width * total) / $scope.maxDataWidth)
          : $scope.width;
        // $scope.availableWidth = total
        availableWidth = availableWidth - (data.length - 1) * $scope.spacing;

        let widthTotal = 0;
        const widths = _.map(data, function (value) {
          value = Math.max(1, Math.floor((availableWidth * value) / total));
          widthTotal += value;
          return value;
        });

        const sortedWidths = widths.slice(0).sort((a, b) => b - a);

        let extras = availableWidth - widthTotal;

        let i = 0;
        // There may be extra pixels to fill due to rounding. Add them to largest items first.
        while (extras-- > 0) {
          sortedWidths[i++] += 1;
          i %= sortedWidths.length;
        }

        let left = 0;
        // Merge the widths, offsets and colors
        data = _.map(widths, function (width, index) {
          data = {
            left,
            width,
            color: colors[index],
            innerColor: innerColors[index],
          };

          left += width + $scope.spacing;
          return data;
        });

        // Only update the scope variable if necessary
        if (!$scope.output || !outputsEqual(data, $scope.output)) {
          $scope.output = data;
        }

        return $scope.output;
      });
    },
  }),
]);
