/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS201: Simplify complex destructure assignments
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkTimeTracker', [
  '$log',
  '$q',
  '$http',
  '$timeout',
  '$rootScope',
  'TKAnalytics',
  'TKData',
  'TKPermissions',
  'TKOrgSettings',
  'TKDateUtil',
  'TKTimeTrackerRowManager',
  'TKPopoverManager',
  'TKTimeTrackerTimerManager',
  'TKTimeEntryHelper',
  'TKAssignables',
  'TKCleanup',
  'TKPlaceholderRowManager',
  'TKErrorMessenger',
  (
    $log,
    $q,
    $http,
    $timeout,
    $rootScope,
    TKAnalytics,
    TKData,
    TKPermissions,
    TKOrgSettings,
    TKDateUtil,
    TKTimeTrackerRowManager,
    TKPopoverManager,
    TKTimeTrackerTimerManager,
    TKTimeEntryHelper,
    TKAssignables,
    TKCleanup,
    TKPlaceholderRowManager,
    TKErrorMessenger
  ) => ({
    restrict: 'E',
    templateUrl: 'ng-approvals/templates/timeTracker',

    scope: {
      userId: '=',
      enableApprovals: '=',
      currentStartDate: '=startDate',
      currentEndDate: '=endDate',
      currentDayCount: '=dayCount',
      mode: '@',
      approvalUserId: '=?',
      approvalData: '=?',
      submit: '=?',
    },

    controller: function ($scope) {
      window.clientEventLogger.push({
        eventTimestamp: Date.now(),
        eventData: {
          eventType: 'Viewed',
          featureArea: 'RM Time Tracker',
          objectName: 'Time Tracker',
          objectType: 'Frame',
          viewName: 'Time Tracker',
        },
      });
      const ONE_SECOND = 1000;
      const ONE_DAY = 24 * 60 * 60 * 1000;

      $scope.I18n = I18n;
      $scope.imgSrc = PROGRESS_INDICATOR;
      $scope.modeTimeEntry = $scope.mode === 'time-entry';
      $scope.modeTimesheetApproval = $scope.mode === 'approval-timesheet';

      if ($scope.submit == null) {
        $scope.submit = false;
      }

      $scope.timeTrackerState = {
        expandAll: false,
        expansionsByAssignableId: {},
      };

      $scope.pickerState = {};

      $scope.timeTrackerStateConfirmationState = {};

      $scope.emptyPhase = {
        phase_name: '[non-phase specific]',
      };

      $scope.emptyCategory = {
        category: I18n.t('lbl_add_blank_row'),
      };

      $scope.expanded = false;

      const emitError = function () {
        $scope.$emit('tk-time-tracker-error');
      };

      $scope.timeTrackerProjectPickerV2Enabled =
        gData.accountSettings.moduleEnabled('time_track_picker_v2');

      $scope.canEditUser = () => TKPermissions.canEditUser($scope.userId);

      $scope.toggleExpandAll = function () {
        $scope.expanded = !$scope.expanded;
        $scope.timeTrackerState.expandAll = $scope.expanded;
        $scope.timeTrackerState.expansionsByAssignableId = {};
        $scope.$broadcast('tk-expand-all', $scope.expanded);
        if ($scope.expanded) {
          TKAnalytics.timeEntry.expandAll();
        } else {
          TKAnalytics.timeEntry.collapseAll();
        }
      };

      $scope.clearRestoreSuggestionsEnabled = true;

      $scope.suggestionsCleared = true;

      $scope.toggleClearRestoreSuggestions = function () {
        clearOrRestoreSuggestions(true);
      };

      $scope.isShowingExceedsMaximumPopup = (date) =>
        ($scope.timeTrackerStateConfirmationState.exceedsMaximumPopupState !=
        null
          ? $scope.timeTrackerStateConfirmationState.exceedsMaximumPopupState.date.getTime()
          : undefined) === date.getTime();

      let timeEntriesQueryId = 0;

      // Build an array of projects for the current set of time entries
      const buildTimesheetData = function (timeEntries) {
        const {
          isSuggestion,
          isSuggestionClearing,
          isPlaceholder,
          isConfirmed,
        } = TKTimeEntryHelper;
        $scope.rowManager = TKTimeTrackerRowManager.createRowManager(
          $scope.dayCount > 1,
          $scope.timeEntryMode === 'itemized'
        );

        // This is for placeholder rows in day view
        // that reflect the real rows in week view
        const inNeedOfPlaceholders = {};

        _.each(timeEntries, function (timeEntry) {
          const project = $scope.projectsById[timeEntry.assignable_id];
          const leaveType = $scope.leaveTypesById[timeEntry.assignable_id];

          const assignableType =
            timeEntry.assignable_type === 'Project' ? 'project' : 'leave';
          const assignable =
            project ||
            leaveType ||
            (function () {
              if (assignableType === 'project') {
                return TKAssignables.getProject(timeEntry.assignable_id)
                  .$object;
              } else {
                return TKAssignables.getLeaveType(timeEntry.assignable_id)
                  .$object;
              }
            })();

          const placeholderKey = `${assignable.id} ${timeEntry.task}`;
          const timeEntryDate = TKDateUtil.parseRubyDate(timeEntry.date);
          const timeEntryIsFromThisWeekButNotThisDay =
            timeEntryDate < $scope.startDate || timeEntryDate > $scope.endDate;
          const timeEntryIsFromThisDay = !timeEntryIsFromThisWeekButNotThisDay;

          if (timeEntryIsFromThisWeekButNotThisDay && isConfirmed(timeEntry)) {
            // the `?=` is important here because we want `false` to take precedence
            if (inNeedOfPlaceholders[placeholderKey] == null) {
              inNeedOfPlaceholders[placeholderKey] = {
                assignableType,
                assignable,
                task: timeEntry.task,
              };
            }
          } else if (timeEntryIsFromThisDay) {
            if (!isSuggestionClearing(timeEntry)) {
              inNeedOfPlaceholders[placeholderKey] = false;
            }
            $scope.rowManager.addTimeEntry(
              timeEntry,
              assignable,
              assignableType
            );
          }
        });

        if (
          $scope.modeTimeEntry &&
          TKPermissions.userIsCurrentUser($scope.userId)
        ) {
          // This is for rows added via "Add rows from previous week"
          const persistingRows =
            TKPlaceholderRowManager.getPersistingRowsAsArray(
              $scope.currentStartDate
            );

          _.each(persistingRows, function (...args) {
            const [assignableId, task] = Array.from(args[0]);
            const placeholderKey = `${assignableId} ${task}`;

            const project = $scope.projectsById[assignableId];
            const leaveType = $scope.leaveTypesById[assignableId];

            const assignableType = project ? 'project' : 'leave';
            const assignable = project || leaveType;

            if (inNeedOfPlaceholders[placeholderKey] == null) {
              inNeedOfPlaceholders[placeholderKey] = {
                assignableType,
                assignable,
                task,
              };
            }
          });
        }

        _.each(inNeedOfPlaceholders, function (placeholderNeeded) {
          if (placeholderNeeded && placeholderNeeded.assignable) {
            const { task, assignable, assignableType } = placeholderNeeded;
            const placeholder = {
              date: TKDateUtil.toRubyDate($scope.startDate),
              assignable_id: assignable.id,
              task,
              placeholder: true,
            };
            $scope.rowManager.addTimeEntry(
              placeholder,
              assignable,
              assignableType
            );
          }
        });

        $scope.rowManager.calculateVisibility();

        $scope.projectsData = $scope.rowManager.rows;

        checkPresentTime();
      };

      var checkPresentTime = function () {
        $scope.isPresentTime = TKDateUtil.isWithinTimeRange(
          $scope.startDate,
          $scope.endDate
        );
      };

      // used by project picker for "Add rows from previous week"
      $scope.rebuildTimesheetData = function () {
        buildTimesheetData($scope.timeEntries);
      };

      $scope.clearingOrRestoring = false;

      // Set `suggestionsCleared` to true or false based on if there are suggestions to clear or not
      // Set `clearRestoreSuggestionsEnabled` to true or false if there are suggestions that can be cleared/restored
      // If param is set also call the appropriate clear/restore fn on rowManager
      // @param {boolean=} clear Optional destructive flag. Defaults to false.
      var clearOrRestoreSuggestions = function (clear) {
        if (clear == null) {
          clear = false;
        }
        const suggestions = $scope.rowManager.getSuggestionsToClear();
        if ($scope.clearingOrRestoring) {
          return;
        }
        if (clear) {
          $scope.clearingOrRestoring = true;
          if (suggestions >= 1) {
            $scope.rowManager
              .clearSuggestions($scope.userId, $scope.dates)
              .then(() => {
                $scope.suggestionsCleared = true;
                $scope.clearingOrRestoring = false;
                TKAnalytics.timeEntry.clearSuggestions();
              });
          } else {
            $scope.rowManager.restoreSuggestions($scope.dates).then(() => {
              $scope.suggestionsCleared = false;
              $scope.clearingOrRestoring = false;
              TKAnalytics.timeEntry.restoreSuggestions();
            });
          }
        } else {
          if (suggestions === null) {
            $scope.clearRestoreSuggestionsEnabled = false;
          } else if (suggestions >= 1) {
            $scope.clearRestoreSuggestionsEnabled = true;
            $scope.suggestionsCleared = false;
          } else {
            $scope.clearRestoreSuggestionsEnabled = true;
            $scope.suggestionsCleared = true;
          }
        }
      };

      $scope.$on('tk-suggestions-refresh', function () {
        clearOrRestoreSuggestions();
      });

      $rootScope.$on('timeEntryCreated', function ($event, timeEntry) {
        const idx = _.findIndex($scope.timeEntries, { id: timeEntry.id });
        if (idx === -1) {
          $scope.timeEntries.push(timeEntry);
        }
        if (idx !== -1) {
          $scope.timeEntries[idx] = timeEntry;
        }

        const isConfirmed = TKTimeEntryHelper.isConfirmed(timeEntry);
        if (isConfirmed) {
          TKAnalytics.timeEntry.create();
        }
        if ($scope.timeEntryMode === 'itemized' && isConfirmed) {
          if (timeEntry.task) {
            TKAnalytics.timeEntry.reportTimeWithCategory();
          } else {
            TKAnalytics.timeEntry.reportTimeWithNoCategory();
          }
        }
        $timeout(() => clearOrRestoreSuggestions(), 200);
      });

      $rootScope.$on('timeEntryDeleted', function ($event, timeEntry) {
        _.remove($scope.timeEntries, { id: timeEntry.id });
      });

      const hoursSummaryByDate = function (date) {
        const { orgSettings } = $scope;
        let confirmed = null;
        let scheduled = 0;
        let scheduledAndConfirmed = 0;
        _.each($scope.projectsData, function (projectData) {
          const data = projectData.timeEntriesByDate[date.toDateString()];
          if (data) {
            if (data.confirmedHours != null) {
              if (confirmed == null) {
                confirmed = 0;
              }
              confirmed += data.confirmedHours;
              if (data.scheduledHours) {
                scheduledAndConfirmed += data.scheduledHours;
              }
            } else if (data.scheduledHours != null) {
              scheduled += data.scheduledHours;
            }
          }
        });

        const summary = { confirmed: confirmed && !scheduled };

        const haveConfirmed = confirmed != null;
        summary.scheduledHours = scheduled;
        summary.confirmedHours = confirmed;
        summary.scheduledAndConfirmedHours = scheduledAndConfirmed;
        summary.hours = scheduled + confirmed || 0;
        summary.meetsMinimum =
          summary.hours.toFixed(2) >= orgSettings.minimumHoursReported;
        summary.exceedsMaximum = summary.hours > orgSettings.timeEntryMaxHours;
        summary.date = date;

        if (!orgSettings.isWorkday(date) && !summary.hours) {
          summary.hours = '';
          summary.meetsMinimum = true;
          summary.confirmed = false;
        }

        return summary;
      };

      $scope.getBarDataWidth = function () {
        let width = 0;
        _.each($scope.rowManager.rows, function (row) {
          if (row.isVisible()) {
            const data = row.rowSummary();
            width = Math.max(width, data.barDataWidth);
          }
        });
        return width;
      };

      $scope.updateAllRows = function () {
        _.invoke($scope.rowManager.rows, 'rowChanged');
      };

      // Retrieves the most recent projects/leaveTypes accessed/billed to by the current user.
      $scope.getRecentProjects = () =>
        TKAssignables.getRecentProjects($scope.userId).then(
          (recentProjects) =>
            ($scope.recentProjects = _.filter(
              recentProjects,
              (recentProject) => !recentProject.deleted_at
            ))
        );

      $scope.hourSummaries = function () {
        const summaries = [];
        summaries.confirmedSum = 0;
        summaries.scheduledSum = 0;
        let different = false;
        _.each($scope.dates, function (date, i) {
          summaries.push(hoursSummaryByDate(date));
          summaries.scheduledSum +=
            summaries[i].scheduledHours +
            summaries[i].scheduledAndConfirmedHours;
          summaries.confirmedSum += summaries[i].confirmedHours;
          if (
            !$scope.summaries ||
            !$scope.summaries[i] ||
            summaries[i].hours !== $scope.summaries[i].hours ||
            summaries[i].confirmed !== $scope.summaries[i].confirmed ||
            summaries[i].date !== $scope.summaries[i].date
          ) {
            different = true;
          }
        });

        if (
          different ||
          ($scope.summaries != null ? $scope.summaries.length : undefined) !==
            summaries.length
        ) {
          $scope.summaries = summaries;
        }

        return $scope.summaries;
      };

      const confirmedHoursByDate = (date) =>
        _.tk.sum(
          $scope.projectsData,
          (data) =>
            __guard__(
              data.timeEntriesByDate[date.toDateString()],
              (x) => x.confirmedHours
            ) || 0
        );

      // Get time entries for the current date range
      const getTimeEntries = function () {
        let weekEndDate, weekStartDate;
        const { orgSettings } = $scope;
        let dataHasBeenShown = false;
        const showData = function () {
          if (dataHasBeenShown) {
            return;
          }
          dataHasBeenShown = true;
          $scope.startDate = $scope.currentStartDate;
          $scope.endDate = $scope.currentEndDate;
          $scope.dayCount = $scope.currentDayCount;
          $scope.dates = [$scope.startDate];
          for (
            let i = 1, end = $scope.dayCount, asc = 1 <= end;
            asc ? i < end : i > end;
            asc ? i++ : i--
          ) {
            $scope.dates.push(TKDateUtil.addDays($scope.startDate, i));
          }
          buildTimesheetData($scope.timeEntries);
          // Call toggle function without destructive option
          // This updates the button state everytime the week/day is changed
          clearOrRestoreSuggestions();
          $scope.$emit('tk-time-tracker-loaded');
        };

        if ($scope.approvalData) {
          if ($scope.timeEntries == null) {
            $scope.timeEntries = $scope.approvalData.approvables;
          }
          projectsPromise().then(function (projects) {
            showData();
          }, emitError);
          return;
        }

        if (orgSettings) {
          // We always want the date range to be a full week, even in day view
          weekStartDate = orgSettings.firstWorkdayOfWeek(
            $scope.currentStartDate
          );
          weekEndDate = TKDateUtil.addDays(weekStartDate, 6);
        } else {
          weekStartDate = $scope.currentStartDate;
          weekEndDate = $scope.currentEndDate;
        }

        const requiredProjectsHaveBeenLoaded = function (projectIds) {
          for (var projectId of Array.from(projectIds)) {
            if (!$scope.projectsById[projectId]) {
              return false;
            }
          }
          return true;
        };

        const requiredLeaveTypesHaveBeenLoaded = (leaveTypeIds) =>
          TKAssignables.allLeaveTypesHaveBeenFetched;

        const requiredAssignablesHaveBeenLoaded = (projectIds, leaveTypeIds) =>
          requiredProjectsHaveBeenLoaded(projectIds) &&
          requiredLeaveTypesHaveBeenLoaded(leaveTypeIds);

        const getUniqueAssignableIds = (timeEntries) =>
          _.tk.pluckUnique(timeEntries, 'assignable_id');

        const assignableIsArchived = function (assignableId) {
          // assumes all required assignables have already been loaded
          const assignable =
            $scope.projectsById[assignableId] ||
            $scope.leaveTypesById[assignableId];
          return !assignable || assignable.deleted_at != null;
        };

        const queryId = ++timeEntriesQueryId;
        return TKData.getTimeEntries(
          $scope.userId,
          weekStartDate,
          weekEndDate,
          true,
          true
        ).then(function (timeEntries) {
          $scope.timeEntries = timeEntries;
          $scope.timeframeLocked = _.some(
            timeEntries,
            (timeEntry) => timeEntry.locked === true
          );
          const timeEntriesByType = _.groupBy(timeEntries, 'assignable_type');
          const placeholderAssignableIdsByType =
            TKPlaceholderRowManager.getPersistingAssignableIdsByType(
              $scope.currentStartDate
            );

          const projectIds = _.union(
            getUniqueAssignableIds(timeEntriesByType['Project']),
            placeholderAssignableIdsByType['Project']
          );
          const leaveTypeIds = _.union(
            getUniqueAssignableIds(timeEntriesByType['LeaveType']),
            placeholderAssignableIdsByType['LeaveType']
          );

          const showDataIfReady = function () {
            if (dataHasBeenShown) {
              return;
            }
            // Only store the most recent request. Without this check an older
            // query result could be stored in the $scope
            if (queryId !== timeEntriesQueryId) {
              return;
            }
            if (requiredAssignablesHaveBeenLoaded(projectIds, leaveTypeIds)) {
              showData();
            }
          };

          // Require that the projects be available too
          if (projectIds.length) {
            TKAssignables.getTheseProjectsNow(projectIds).then(showDataIfReady);
          }
          return projectsPromise().then(
            showDataIfReady,
            emitError,
            showDataIfReady
          );
        });
      };

      const settingsAndUserPromise = $q
        .all([TKOrgSettings.promise, TKData.getUser($scope.userId)])
        .then(function (...args) {
          const [orgSettings, user] = Array.from(args[0]);
          $scope.orgSettings = orgSettings;
          $scope.user = user;
          $scope.timeEntryMode = $scope.orgSettings.timeEntryMode();
          $scope.expandEnabled = $scope.timeEntryMode === 'itemized';
          // $scope.timeTrackerState.picker.useCategories = if $scope.timeEntryMode is 'itemized' then true else false
          $scope.loaded = true;

          if ($scope.timeEntryMode === 'itemized' && $scope.modeTimeEntry) {
            return ($scope.expandType = 'timeTracking');
          } else if ($scope.modeTimesheetApproval) {
            return ($scope.expandType = 'approvalDetail');
          }
        }, emitError);

      var projectsPromise = TKAssignables.promise;
      $scope.leaveTypes = TKAssignables.leaveTypes;
      $scope.leaveTypesById = TKAssignables.leaveTypesById;
      $scope.allProjects = TKAssignables.allProjects;
      $scope.projectsById = TKAssignables.projectsById;
      $scope.projects = TKAssignables.projects;
      $scope.phases = TKAssignables.phases;

      $scope.timeOptionsByDate = function (date, ignoreHours) {
        const { orgSettings } = $scope;
        const options = [];
        const remaining =
          orgSettings.timeEntryMaxHours +
          ignoreHours -
          confirmedHoursByDate(date);
        let h = 0;
        if (remaining < h) {
          options.push(h);
        }
        while (h <= remaining) {
          options.push(h);
          h += orgSettings.timeEntryStepHours;
        }
        return options;
      };

      // toggle the project picker only when clicking on the project picker
      // button - not its children.
      $scope.activateProjectPicker = function () {
        window.clientEventLogger.push({
          eventTimestamp: Date.now(),
          eventData: {
            eventType: 'Clicked',
            featureArea: 'RM Time Tracker',
            objectName: 'Report Time for Something Else Button',
            objectType: 'Button',
            viewName: 'Time Tracker',
          },
        });
        $scope.pickerState.isVisible = true;
      };

      // TODO Creates a time entry row in the UI.
      $scope.createTimeEntry = function (date) {
        if (date == null) {
          date = $scope.dates[0];
        }
        const { pickerState } = $scope;
        const { chosenProject } = pickerState;
        let chosenCategory = pickerState.chosenCategory
          ? pickerState.chosenCategory.category
          : '';
        const type = pickerState.chosenProjectType;

        // Chosen Category may be blank.
        if (chosenCategory === $scope.emptyCategory.category) {
          chosenCategory = '';
        }

        const timeEntry = {
          user_id: $scope.userId,
          date: TKDateUtil.toRubyDate(date),
          task: chosenCategory,
          assignable_id: chosenProject.id,
          hours: 0,
          placeholder: true,
        };

        const row = $scope.rowManager.createRow(chosenProject, type, true);
        let i = undefined;

        if ($scope.timeEntryMode === 'itemized') {
          // If row has a blank space for the chosen category, use it
          i = row.lastEmptyRowByTaskForDate(chosenCategory, date);
          if (i < 0) {
            $scope.rowManager.addTimeEntry(timeEntry, chosenProject, type);
            i = row.lastRowByTask(chosenCategory);
          }
        } else {
          $scope.rowManager.addTimeEntry(timeEntry, chosenProject, type);
        }

        if (row) {
          row.calculateVisibility();
        }

        $scope.startEdit(row, date, i);
        pickerState.isVisible = false;
      };

      $scope.deleteRow = function (row) {
        $scope.rowManager.deleteRow(row, $scope.userId, $scope.dates);
      };

      let submissionIsInProgress = false;
      $scope.approvalStatus = function () {
        if (submissionIsInProgress) {
          return 'submitting';
        }
        if ($scope.rowManager) {
          const status = $scope.rowManager.approvablesStatus();
          $scope.cachedApprovalStatus = status;
          return status.state;
        }

        return 'none';
      };

      // to give time for time entry creation when it's starting at the same time as a submit
      // TODO: this should set time out until the time entry post is completed
      $scope.safeSubmitApprovals = () => {
        setTimeout($scope.submitApprovals, ONE_SECOND);
        window.clientEventLogger.push({
          eventTimestamp: Date.now(),
          eventData: {
            eventType: 'Clicked',
            featureArea: 'RM Time Tracker',
            objectName: 'Submit Approvals Button',
            objectType: 'Button',
            viewName: 'Time Tracker',
          },
        });
      };

      // now debounce it so duplicate approvals can't be submitted by accident with a double-click
      $scope.debounceSubmitApprovals = _.debounce(
        $scope.safeSubmitApprovals,
        ONE_SECOND,
        { leading: true, trailing: false }
      );

      $scope.submitApprovals = function () {
        submissionIsInProgress = true;
        const filteredApprovables = _.filter(
          $scope.timeEntries,
          function (timeEntry) {
            const assignable =
              TKAssignables.projectsById[timeEntry.assignable_id] ||
              TKAssignables.leaveTypesById[timeEntry.assignable_id];
            const isNotArchived = assignable && !assignable.deleted_at;
            return (
              isNotArchived &&
              TKTimeEntryHelper.isConfirmed(timeEntry) &&
              $scope.rowManager.approvablesStatus([timeEntry]).state !==
                'approved'
            );
          }
        );
        const updateFilteredApprovables = function ({ data }) {
          submissionIsInProgress = false;
          _.each(filteredApprovables, function (approvable) {
            const approval = _.find(data, { approvable_id: approvable.id });
            approvable.approvals = approvable.approvals || { data: [] };
            approvable.approvals.data[0] = approval;
          });
        };
        const errorHandler = TKErrorMessenger.newHandler({
          broadcast: 'tk-submit-approval-fail',
        });
        TKData.setApprovablesStatus(
          $scope.userId,
          filteredApprovables,
          'pending',
          false,
          'TimeEntry'
        ).then(updateFilteredApprovables, errorHandler);
      };

      const retrySubmitApprovals = _.debounce(
        $scope.submitApprovals,
        5 * ONE_SECOND,
        { leading: true, trailing: false }
      );

      // NOTE map to track which date is currently being confirmed
      const confirmingApprovalsMap = {};
      // NOTE function wired into confirm button
      $scope.confirmApprovals = function (index, summary) {
        if (!$scope.confirmable(summary)) {
          return;
        }
        const date = $scope.dates[index];
        // NOTE return early if we are already approving for that date.
        if (confirmingApprovalsMap[date]) {
          return;
        }
        const createTimeEntryPromises = _.map(
          $scope.rowManager.rows,
          function (row) {
            let data;
            if (!$scope.editable(row, date)) {
              return $q.when({});
            }
            if ((data = row.timeEntriesByDate[date.toDateString()])) {
              const hours =
                data.confirmedHours == null ? data.scheduledHours : undefined;
              if (hours != null) {
                confirmingApprovalsMap[date] = true;
                // NOTE `row.setTimeEntry` returns a promise at some point
                return row.setTimeEntry(date, null, {
                  hours,
                  user_id: $scope.userId,
                });
              }
            }
            return $q.when({});
          }
        );

        $q.all(createTimeEntryPromises).then(function () {
          delete confirmingApprovalsMap[date];
          if (
            hoursSummaryByDate(date).exceedsMaximum &&
            $scope.confirmable(summary) &&
            $scope.canEditUser()
          ) {
            $scope.timeTrackerStateConfirmationState.exceedsMaximumPopupState =
              { date };
            return ($scope.timeTrackerStateConfirmationState.exceedsMaximumPopupVisible = true);
          }
        });
        $timeout(() => clearOrRestoreSuggestions(), 200);
        TKAnalytics.timeEntry.confirmTime();
      };

      $scope.confirmApprovals = _.debounce($scope.confirmApprovals, 300, {
        leading: true,
        trailing: false,
      });

      $scope.isPast = function (date) {
        const today = TKDateUtil.toStartOfDay(new Date());
        return today > date;
      };

      $scope.isTodayOrPast = function (date) {
        const today = TKDateUtil.toStartOfDay(new Date());
        return today >= date;
      };

      $scope.confirmable = function (summary) {
        const confirmationsEnabled =
          $scope.timeEntryMode !== 'itemized' ||
          !!$scope.orgSettings.allowBulkConfirmTimeEntries;
        if (
          !summary.confirmed &&
          summary.hours > 0 &&
          confirmationsEnabled &&
          $scope.isTodayOrPast(summary.date)
        ) {
          return true;
        } else {
          return false;
        }
      };

      // Gets the available categories for a given project.
      // @param {object} project The project object
      $scope.getProjectCategories = (project) =>
        TKData.getProjectCategories(project.id).$object;

      $scope.getProjectCategoriesPromise = (project) =>
        TKData.getProjectCategories(project.id);

      // Gets the available non-project categories for a given project.
      $scope.getAllCategories = () => $scope.orgSettings.budgetCategories;

      const localizedDecimal = I18n.lookup('number').format.separator;
      const localizedPrecision = I18n.lookup('number').format.precision;

      // Converts an hour string to a float.
      const hoursToFloat = function (hours) {
        if (_.isNumber(hours)) {
          return +hours.toFixed(localizedPrecision);
        }

        if (hours.indexOf(':') >= 0) {
          const hoursMins = hours.split(':');
          if (hoursMins.length === 2 && hoursMins[1] < 60) {
            return +(
              parseInt(hoursMins[0]) +
              parseInt(hoursMins[1]) / 60.0
            ).toFixed(localizedPrecision);
          } else {
            return NaN;
          }
        }

        return +parseFloat(hours.replace(localizedDecimal, '.')).toFixed(
          localizedPrecision
        );
      };

      // TODO refactor common logic out of the following method into
      // a shared method for finding next/prev editable cell
      $scope.editNextCell = function () {
        let editParams;
        let { date, row, i } = $scope.timeTrackerState.editState;
        let nextDate = TKDateUtil.addDays(date, 1);
        let nextRow = $scope.rowManager.nextVisibleRow(row);
        let canEdit = false;

        while (canEdit === false) {
          if (row === null) {
            $scope.endEdit(true);
            return;
          }
          if (nextDate.getTime() <= $scope.endDate.getTime()) {
            if (!row.isLocked(nextDate, i)) {
              editParams = {
                row,
                date: nextDate,
                i,
              };
              canEdit = true;
            } else {
              nextDate = TKDateUtil.addDays(nextDate, 1);
            }
          } else {
            nextDate = $scope.startDate;
            if ($scope.timeEntryMode === 'itemized') {
              if (i >= row.rows.length - 1) {
                row = nextRow;
                nextRow = $scope.rowManager.nextVisibleRow(row);
                i = 0;
              } else {
                i += 1;
              }
            } else {
              row = nextRow;
              nextRow = $scope.rowManager.nextVisibleRow(row);
            }
          }
        }

        $scope.endEdit(true);
        if (editParams) {
          $scope.startEdit(editParams.row, editParams.date, editParams.i);
        }
      };

      // TODO refactor common logic out of the following method into
      // a shared method for finding next/prev editable cell
      $scope.editPreviousCell = function () {
        let editParams;
        let { date, row, i } = $scope.timeTrackerState.editState;
        let previousDate = TKDateUtil.addDays(date, -1);
        let previousRow = $scope.rowManager.previousVisibleRow(row);
        let canEdit = false;

        while (canEdit === false) {
          if (row === null) {
            $scope.endEdit(true);
            return;
          }
          if (previousDate.getTime() >= $scope.startDate.getTime()) {
            if (!row.isLocked(previousDate, i)) {
              editParams = {
                row,
                date: previousDate,
                i,
              };
              canEdit = true;
            } else {
              previousDate = TKDateUtil.addDays(previousDate, -1);
            }
          } else {
            previousDate = $scope.endDate;
            if ($scope.timeEntryMode === 'itemized') {
              if (i < 1) {
                row = previousRow;
                previousRow = $scope.rowManager.previousVisibleRow(row);
                i = row === null ? 0 : row.rows.length - 1;
              } else {
                i -= 1;
              }
            } else {
              row = previousRow;
              previousRow = $scope.rowManager.previousVisibleRow(row);
            }
          }
        }

        $scope.endEdit(true);
        if (editParams) {
          $scope.startEdit(editParams.row, editParams.date, editParams.i);
        }
      };

      $scope.editNextCellFromNotes = function () {
        if ($scope.timeTrackerState.notesEditState != null) {
          const { row, date, i } = $scope.timeTrackerState.notesEditState;
          $scope.startEdit(row, date, i);
          $scope.timeTrackerState.notesEditState = null;
        }
      };

      // TODO refactor common logic out of the following method into
      // a shared method for finding next/prev editable cell
      $scope.editNextNotesFromCell = function () {
        let editParams;
        let { date, row, i } = $scope.timeTrackerState.editState;
        let nextRow = $scope.rowManager.nextVisibleRow(row);
        let nextIndex = i + 1;
        let canEdit = false;

        while (canEdit === false) {
          if ($scope.timeTrackerState.editState != null) {
            if (nextIndex <= row.rows.length - 1) {
              if (!row.isLocked(date, nextIndex)) {
                editParams = {
                  row,
                  date,
                  i: nextIndex,
                };
                canEdit = true;
              } else {
                nextIndex += 1;
              }
            } else if (nextRow) {
              if (nextRow.rows.length < 1) {
                if (!nextRow.expanded) {
                  nextRow.expanded = true;
                }
                nextRow.addTimeEntry({
                  date: TKDateUtil.toRubyDate(date),
                  placeholder: true,
                });
                row = nextRow;
                nextIndex = 0;
              } else {
                row = nextRow;
                nextRow = $scope.rowManager.nextVisibleRow(row);
                nextIndex = 0;
              }
            } else {
              $scope.endEdit(true);
              return;
            }
          }
        }

        if (editParams) {
          $scope.startEditNotes(editParams.row, editParams.date, editParams.i);
        }
      };

      // TODO refactor common logic out of the following method into
      // a shared method for finding next/prev editable cell
      $scope.editPreviousCellFromNotes = function () {
        let editParams;
        let { row, date, i } = $scope.timeTrackerState.notesEditState;
        let previousRow = $scope.rowManager.previousVisibleRow(row);
        let previousIndex = i - 1;
        let canEdit = false;

        while (canEdit === false) {
          if (previousIndex >= 0) {
            if (!row.isLocked(date, previousIndex)) {
              editParams = {
                row,
                date,
                i: previousIndex,
              };
              canEdit = true;
            } else {
              previousIndex -= 1;
            }
          } else if (previousRow) {
            if (previousRow.rows.length < 1) {
              if (!previousRow.expanded) {
                previousRow.expanded = true;
              }
              previousRow.addTimeEntry({
                date: TKDateUtil.toRubyDate(date),
                placeholder: true,
              });
              row = previousRow;
              previousIndex = previousRow.rows.length - 1;
            } else {
              row = previousRow;
              previousRow = $scope.rowManager.previousVisibleRow(row);
              previousIndex = row.rows.length - 1;
            }
          } else {
            $scope.timeTrackerState.notesEditState = null;
            return;
          }
        }

        if (editParams) {
          $scope.startEdit(editParams.row, editParams.date, editParams.i);
        }
      };

      $scope.editPreviousNotesFromCell = function () {
        if ($scope.timeTrackerState.editState != null) {
          const { row, date, i } = $scope.timeTrackerState.editState;
          $scope.startEditNotes(row, date, i);
        }
      };

      $scope.startEditNotes = function (row, date, i) {
        row.isEditingRow = true;
        $scope.timeTrackerState.notesEditState = {
          row,
          date,
          i,
          selected: row.rows[i].id,
        };
      };

      // This fn is specifically for dummy itemized row that doesn't have a timeEntry
      // TODO would like to get away from empty UI markup...
      $scope.startEditNotesNew = function (row, date, i) {
        const placeholder = {
          user_id: $scope.userId,
          date: TKDateUtil.toRubyDate(date),
          assignable_id: row.assignable.id,
          placeholder: true,
        };

        $scope.rowManager.addTimeEntry(placeholder, row.assignable, 'project');

        $scope.timeTrackerState.notesEditState = {
          row,
          date,
          i,
          selected: row.rows[i].id,
        };
      };

      const cleanup = TKCleanup.newCleanup($scope);
      // This is not ideal, but this is how I monitor the popup being hidden by the popover
      // manager
      cleanup(
        $scope.$watch(
          'timeTrackerState.itemizedPopupVisible',
          function (visible) {
            if (!visible) {
              return ($scope.timeTrackerState.categoryPickerState = null);
            }
          }
        )
      );

      cleanup(
        $scope.$watch(
          'timeTrackerState.dayviewPopupVisible',
          function (visible) {
            if (!visible) {
              return ($scope.timeTrackerState.dayviewPopupState = null);
            }
          }
        )
      );

      $scope.isCellCollapsed = function (row, date, i = null) {
        if (i != null && $scope.timeEntryMode === 'itemized') {
          row = row.rows[i];
          const timeEntries =
            row != null
              ? row.timeEntriesByDate[date.toDateString()]
              : undefined;
          return timeEntries && timeEntries.length > 1;
        }
        return false;
      };

      $scope.dayviewPopupGoToDayview = function (date) {
        delete $scope.timeTrackerState.dayviewPopupVisible;
        $scope.$emit('tk-time-tracker-day-view', date);
      };

      $scope.getDays = (n) => new Array(n);

      $scope.editable = (row, date, i = null) =>
        TKPermissions.canEditUser($scope.userId) &&
        $scope.modeTimeEntry &&
        !row.isLocked(date, i) &&
        !$scope.isCellCollapsed(row, date, i);

      // Params are the date the cell belongs to, the index of the time entry for this date and project
      // (itemized accounts only) and the task (itemized accounts only)
      $scope.startEdit = (function () {
        const showDayViewPopover = function (row, date, i) {
          if (!TKPopoverManager.showingPopover()) {
            $scope.timeTrackerState.dayviewPopupState = {
              row,
              date,
              assignable: row.assignable,
              i,
            };
            $scope.timeTrackerState.dayviewPopupVisible = true;
          }
        };

        const showItemizedPopover = function (row, date, i) {
          if (!TKPopoverManager.showingPopover()) {
            $scope
              .getProjectCategoriesPromise(row.assignable)
              .then(function () {
                $scope.$evalAsync(function () {
                  $scope.timeTrackerState.categoryPickerState = {
                    row,
                    date,
                    assignable: row.assignable,
                    i,
                  };
                  $scope.timeTrackerState.itemizedPopupVisible = true;
                });
              });
          }
        };

        const showHalfDayPopover = function (row, date, i) {
          if (!TKPopoverManager.showingPopover()) {
            $scope.timeTrackerState.editState = {
              type: 'halfDay',
              row,
              date,
              assignable: row.assignable,
              editHours: row.cellEditValue(date, i),
            };
            $scope.timeTrackerState.editState.editHoursInitial =
              $scope.timeTrackerState.editState.editHours;
            $scope.timeTrackerState.halfDayPopupVisible = true;
          }
        };

        const setEditStateIfLeaveType = function (row, date, i) {
          if ($scope.leaveTypesById[row.assignable.id]) {
            setEditState(row, date, i || 0);
          }
        };

        var setEditState = function (row, date, i) {
          let placeholder = null;

          // TODO refactor, fixes visual state issue
          // ensures cleared timeEntries dont trigger, not a long term fix
          if (row.hasTimeEntry(date, i)) {
            row.removePlaceholders(date);
          }

          if (
            $scope.timeEntryMode === 'itemized' &&
            !row.hasTimeEntry(date, i)
          ) {
            const childRow = row.rows[i] || {};
            placeholder = {
              user_id: $scope.userId,
              date: TKDateUtil.toRubyDate(date),
              task: childRow.task,
              assignable_id: row.assignable.id,
              hours: 0,
              placeholder: true,
              rowId: childRow.id,
            };
            const project = $scope.projectsById[placeholder.assignable_id];
            const leaveType = $scope.leaveTypesById[placeholder.assignable_id];
            if (project) {
              $scope.rowManager.addTimeEntry(
                placeholder,
                row.assignable,
                'project'
              );
            } else if (leaveType) {
              $scope.rowManager.addTimeEntry(
                placeholder,
                row.assignable,
                'leave'
              );
            }
            if (row) {
              row.calculateVisibility();
            }
          }

          // show regular 0 instead of value created by timer
          const rowCellEditValue = row.cellEditValue(date, i);
          const editHours = rowCellEditValue === 0.001 ? 0 : rowCellEditValue;

          $scope.timeTrackerState.editState = {
            type: 'hoursMinutes',
            row,
            date,
            weekdayIndex: Math.round(
              (date.getTime() - $scope.currentStartDate.getTime()) / ONE_DAY
            ),
            i,
            placeholder,
            editHours,
            editHoursInitial: editHours,
            editNotes: row.getNotes(date, i),
            assignable: row.assignable,
          };
          $timeout(function () {
            row.expanded = true;
            $scope.timeTrackerState.expansionsByAssignableId[
              row.assignable.id
            ] = true;
          });
        };

        const setReadOnlyState = function (row, date, i) {
          $scope.timeTrackerState.readOnlyState = {
            row,
            i,
            date,
            notes: row.getNotes(date, i),
            weekdayIndex: Math.round(
              (date.getTime() - $scope.currentStartDate.getTime()) / ONE_DAY
            ),
          };
        };

        return function (row, date, i = null, task) {
          if (!$scope.modeTimeEntry) {
            return;
          }

          if (!row) {
            // No parameters, start editing the first cell
            row = $scope.rowManager.nextVisibleRow();
            date = $scope.startDate;
            if ($scope.timeEntryMode === 'itemized') {
              i = 0;
            }
          }

          if (row.isLeave() && $scope.timeEntryMode === 'itemized') {
            i = 0;
          }

          if ($scope.isCellCollapsed(row, date, i)) {
            showDayViewPopover(row, date, i);
            return;
          }

          if ($scope.editable(row, date, i)) {
            $scope.timeTrackerState.readOnlyState = null;
          } else {
            setReadOnlyState(row, date, i);
            return;
          }

          if (
            $scope.timeEntryMode === 'itemized' &&
            (task != null || i == null)
          ) {
            setEditStateIfLeaveType(row, date, i);
            showItemizedPopover(row, date, i);
          } else if ($scope.timeEntryMode === 'halfDays') {
            showHalfDayPopover(row, date, i);
          } else {
            if (row.isEditingRow) {
              row.isEditingRow = false;
              $timeout.cancel(row.isEditingRowTimeout);
            }

            setEditState(row, date, i);
          }

          // guardian to return to redirect to sign in, if session timeout or network error
          // to prevent losing tracked time on a unresponsive page
          $http
            .get(`${window.API_BASE_URL}/users/${$scope.userId}`)
            .success(function (data, status, headers, config) {})
            .error((data, status, headers, config) => redirect('/'));
        };
      })();

      $scope.startEditIf = function (condition, row, date, i, task) {
        if (condition) {
          $scope.startEdit(row, date, i, task);
        }
        return condition;
      };

      $scope.creatingTimeEntry = {};

      $scope.endEdit = function (save, hours) {
        const { editState } = $scope.timeTrackerState;
        if (!editState) {
          return;
        }
        const { orgSettings } = $scope;
        const { date, row, i } = editState;
        $scope.timeTrackerState.editState = null;
        $scope.timeTrackerState.halfDayPopupVisible = false;

        if (editState.editHours <= orgSettings.timeEntryMaxHours) {
          if ($scope.creatingTimeEntry[date.toDateString()]) {
            return;
          }

          row.removePlaceholders(date);

          let existing = row.confirmedTimeEntriesByDate(date);

          if (
            editState.type === 'halfDay' &&
            existing.length > 0 &&
            hours === editState.editHoursInitial
          ) {
            return;
          }

          const notes =
            __guard__(
              row.rows[i] != null ? row.rows[i].notesInputModel : undefined,
              (x) => x.notes
            ) || '';
          const notesHaveChanged = (row.getNotes(date, i) || '') !== notes;
          const hoursHaveChanged =
            editState.editHours !== editState.editHoursInitial;

          if (editState.type === 'hoursMinutes') {
            if (!hoursHaveChanged && !notesHaveChanged) {
              return;
            }
            if (!editState.editHours && editState.editHoursInitial > 0) {
              hours = 0;
            } else {
              if (editState.editHours === undefined) {
                editState.editHours = '';
              }
              hours = hoursToFloat(editState.editHours);
            }
          } else if (
            editState.type === 'halfDay' &&
            editState.editHours !== hours
          ) {
            editState.editHours = hours;
          }

          const hasPlaceholder = _.find(existing, { placeholder: true });
          if ((hasPlaceholder != null ? hasPlaceholder.hours : undefined) > 0) {
            return;
          }

          if ((hours != null && !isNaN(hours)) || notes) {
            // if notes seemed to exist, its possible hours is NaN, set to 0
            let placeholder;
            if (isNaN(hours)) {
              hours = 0;
            }

            if ($scope.timeEntryMode === 'itemized') {
              const data = { hours, user_id: $scope.userId };

              if (i != null) {
                // Preserve the row id
                const childRow = row.rows[i];
                data.rowId = childRow.id;
                if (childRow.notesInputModel != null) {
                  data.notes = childRow.notesInputModel.notes;
                }
              }

              // Match up indices by date with indices in view...
              if (row.hasTimeEntry(date, i)) {
                // if theres a timer running for this timeEntry and we're trying to save 0 hours, delete hours prop so we don't loose the time entry
                const hasTimerRunningForTimeEntry =
                  TKTimeTrackerTimerManager.hasTimerRunning(
                    row.rows[i].timeEntriesByDate[date.toDateString()][0].id
                  );
                if (hasTimerRunningForTimeEntry && data.hours === 0) {
                  delete data.hours;
                }
                row.setTimeEntry(date, i, data);
              } else {
                // Create a new time entry
                $scope.creatingTimeEntry[date.toDateString()] = true;
                placeholder = {
                  date: TKDateUtil.toRubyDate(date),
                  task: row.rows[i].task,
                  placeholder: true,
                  hours: data.hours,
                };
                row.addTimeEntry(placeholder);
                row.isSavingPromise = row
                  .createTimeEntry($scope.userId, date, i, data)
                  .then(function () {
                    $scope.creatingTimeEntry[date.toDateString()] = false;
                    row.removePlaceholders(date);
                  });
              }
            } else {
              if (existing.length > 1) {
                row.deleteTimeEntriesForDate(date);
                existing = [];
              }

              if (existing.length === 1) {
                // Update an existing time entry if it's not a suggestion
                row.setTimeEntry(date, null, { hours });
              } else {
                $scope.creatingTimeEntry[date.toDateString()] = true;
                placeholder = {
                  date: TKDateUtil.toRubyDate(date),
                  placeholder: true,
                  hours,
                };
                row.addTimeEntry(placeholder);
                // Create a new time entry
                row
                  .createTimeEntry($scope.userId, date, null, { hours })
                  .then(function () {
                    $scope.creatingTimeEntry[date.toDateString()] = false;
                    row.removePlaceholders(date);
                  });
              }
            }
          }
        } else {
          // TODO refactor into popover? $timeout works but isn't great.
          // Scenario where blur event opens popover but click event is
          // closing it right away since most people click to blur input.
          $timeout(function () {
            $scope.timeTrackerState.exceedsMaximumPopupState = {
              row,
              date,
              assignable: row.assignable,
              i,
            };
            return ($scope.timeTrackerState.exceedsMaximumPopupVisible = true);
          }, 200);
        }

        return editState;
      };

      const refreshTimeEntries = function () {
        settingsAndUserPromise.then(getTimeEntries);
      };

      // Watch for date changes
      cleanup(
        $scope.$watch(
          'currentStartDate.getTime()+":"+currentDayCount',
          refreshTimeEntries
        )
      );

      cleanup(
        $scope.$watchCollection('approvalData.approvables', refreshTimeEntries)
      );

      cleanup($rootScope.$on('tk-create-time-entry-fail', refreshTimeEntries));

      cleanup($rootScope.$on('tk-update-time-entry-fail', refreshTimeEntries));

      cleanup($rootScope.$on('tk-delete-time-entry-fail', refreshTimeEntries));

      cleanup(
        $rootScope.$on('tk-submit-approval-fail', function () {
          settingsAndUserPromise.then(function () {
            getTimeEntries().then(retrySubmitApprovals);
          });
        })
      );

      return cleanup(
        $rootScope.$on('timeEntryUpdated', function (e, timeEntry) {
          const existing = _.find($scope.timeEntries, { id: timeEntry.id });
          if (existing && timeEntry) {
            _.assign(existing, timeEntry);
          }
        })
      );
    },
  }),
]);
