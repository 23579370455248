/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
((addCustomRestangularMethods) =>
  angular
    .module('app')
    .config([
      '$stateProvider',
      '$urlRouterProvider',
      '$httpProvider',
      '$provide',
      'stateHelperProvider',
      'RestangularProvider',
      '$locationProvider',

      function (
        $stateProvider,
        $urlRouterProvider,
        $httpProvider,
        $provide,
        stateHelperProvider,
        RestangularProvider,
        $locationProvider
      ) {
        // NOTE resolves an issue with the benchpress-client routing/history interactions
        // Solution taken from linked comment, thread describes related but not same issue
        // https://github.com/angular/angular.js/issues/4608#issuecomment-111399988
        $locationProvider.html5Mode({
          enabled: true,
          requireBase: false,
          rewriteLinks: false,
        });

        $urlRouterProvider.otherwise('/');

        stateHelperProvider.setNestedState({
          name: 'root',
          templateUrl: 'ng-approvals/templates/root',
          children: [
            {
              name: 'welcome',
              url: '/welcome',
              templateUrl: 'ng-approvals/templates/welcome',
            },
          ],
        });

        $httpProvider.interceptors.push('TKHttpLogger');
        $httpProvider.defaults.useXDomain = true;
        $httpProvider.defaults.withCredentials = true;
        $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-Token';
        $httpProvider.defaults.xsrfCookieName = $(
          'meta[name="csrf-token"]'
        ).attr('content');

        RestangularProvider.setBaseUrl(window.API_BASE_URL); // assumed to be set by benchpress-server

        RestangularProvider.addResponseInterceptor(function (
          dataFromServer,
          operation,
          what,
          url,
          response,
          deferred
        ) {
          let extractedData = dataFromServer;
          if (operation === 'getList') {
            extractedData = dataFromServer.data;
            extractedData.paging = dataFromServer.paging;
          }

          return extractedData;
        });

        // https://github.com/mgonto/restangular/issues/116 because of `REXML::ParseException` returned from server
        // Was directed to https://github.com/mgonto/restangular/issues/78#issuecomment-18687759
        RestangularProvider.addRequestInterceptor(function (elem, operation) {
          if (operation === 'remove') {
            return null;
          } else {
            return elem;
          }
        });

        RestangularProvider.setOnElemRestangularized(
          (
            changedElem,
            isCollection,
            route,
            Restangular // `addCustomRestangularMethods` is declared at the top of this file and defined in
          ) =>
            // the `run` block below. This is a hacky way of injecting `$q` into the config block.
            addCustomRestangularMethods(
              changedElem,
              isCollection,
              route,
              Restangular
            )
        );

        return this;
      },
    ])

    .run([
      '$q',
      function ($q) {
        addCustomRestangularMethods = function (
          changedElem,
          isCollection,
          route,
          Restangular
        ) {
          let getList;
          if (isCollection) {
            getList = function (params, headers, _null, page) {
              if (params == null) {
                params = {};
              }
              params.page = page;
              return changedElem.getList(params, headers);
            };
          } else {
            getList = function (subElement, params, headers, page) {
              if (params == null) {
                params = {};
              }
              params.page = page;
              return changedElem.getList(subElement, params, headers);
            };
          }

          const assignNonArrayProperties = function (object, propertiesObject) {
            for (var key in propertiesObject) {
              var val = propertiesObject[key];
              if (_.isNaN(+key)) {
                object[key] = val;
              }
            }
            return object;
          };

          // This method behaves the same way as `getList` except it
          // recursively fetches all pages and resolves the returned
          // promise when complete.
          const getAllPages = function (subElement, params, headers) {
            let getNextPage;
            const deferred = $q.defer();
            const $object = (deferred.promise.$object = []);
            (getNextPage = function (page) {
              getList(subElement, params, headers, page).then(
                function (dataFromGetList) {
                  const { paging } = dataFromGetList;
                  if (paging.page !== page) {
                    // This logs the array of duplicates that would be present if we added `dataFromGetList` to `$object`
                    // console.log _.intersection _.pluck(dataFromGetList, 'id'), _.pluck($object, 'id')
                    assignNonArrayProperties($object, dataFromGetList);
                    deferred.resolve($object);
                  } else if (paging.next) {
                    $object.push(...Array.from(dataFromGetList || []));
                    assignNonArrayProperties($object, dataFromGetList);
                    deferred.notify($object);
                    getNextPage(paging.page + 1);
                  } else {
                    $object.push(...Array.from(dataFromGetList || []));
                    assignNonArrayProperties($object, dataFromGetList);
                    deferred.resolve($object);
                  }
                },
                function (error) {
                  deferred.reject(error);
                }
              );
            })(1);
            return deferred.promise;
          };

          changedElem.getAllPages = getAllPages;
          return changedElem;
        };
      },
    ]))(null);
