angular.module('app').filter('tkCurrency', function () {
  const I18n = window.I18n || {
    toCurrency(number) {
      return (
        '$' +
        _.tk.chunkRight(`${number}`, 3).map(_.method('join', '')).join(',')
      );
    },
  };
  return function (number, options) {
    let prefix;
    if (number < 0) {
      prefix = '-';
      number = Math.abs(number);
    } else {
      prefix = '';
    }
    return prefix + I18n.toCurrency(number, options);
  };
});
