angular.module('app').filter('tkDate', function () {
  // available localized formats include 'default', 'long', and 'short'
  const formats = _.clone(I18n.lookup('date').formats);

  formats.dayName = '%A';
  formats.dayDate = '%e';
  formats.abbrDayName = '%a';
  formats.monthName = '%B';
  formats.abbrMonthName = '%b';

  return (dateString, format) =>
    I18n.strftime(new Date(dateString), formats[format || 'default'] || format);
});

// As reference for custom formats, the following is an excerpt from I18n.strftime
//   ...
//   var f = format;
//   f = f.replace("%a", options.abbr_day_names[weekDay]);
//   f = f.replace("%A", options.day_names[weekDay]);
//   f = f.replace("%b", options.abbr_month_names[month]);
//   f = f.replace("%B", options.month_names[month]);
//   f = f.replace("%d", padding(day));
//   f = f.replace("%e", day);
//   f = f.replace("%-d", day);
//   f = f.replace("%H", padding(hour));
//   f = f.replace("%-H", hour);
//   f = f.replace("%I", padding(hour12));
//   f = f.replace("%-I", hour12);
//   f = f.replace("%m", padding(month));
//   f = f.replace("%-m", month);
//   f = f.replace("%M", padding(mins));
//   f = f.replace("%-M", mins);
//   f = f.replace("%p", options.meridian[meridian]);
//   f = f.replace("%S", padding(secs));
//   f = f.replace("%-S", secs);
//   f = f.replace("%w", weekDay);
//   f = f.replace("%y", padding(year));
//   f = f.replace("%-y", padding(year).replace(/^0+/, ""));
//   f = f.replace("%Y", year);
//   f = f.replace("%z", timezoneoffset);

//   return f;
