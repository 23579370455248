/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app.services').factory('TKCollections', [
  '$rootScope',
  'Restangular',
  'TKSession',
  function ($rootScope, Restangular, TKSession) {
    const dataFactory = {};

    // TODO make a more flexible version of getCollections() to accommodate params
    dataFactory.collections = () => Restangular.all('collections');

    dataFactory.getCollections = () => Restangular.all('collections').getList();

    dataFactory.getCollection = (collectionId) =>
      Restangular.one('collections', collectionId).get();

    dataFactory.getCollectionMembersCombined = (collectionId) =>
      Restangular.one('collections', collectionId)
        .getList('members', { fields: 'collectible' })
        .then(function (members) {
          const combinedMembers = [];
          _.each(members, function (member) {
            const combinedMember = member.collectible.data;
            combinedMember.priority = +member.priority; // could be null if membership not saved properly..
            combinedMember.membershipId = member.id;
            return combinedMembers.push(combinedMember);
          });
          return combinedMembers;
        });

    dataFactory.createCollection = (collectionObj) =>
      Restangular.all('collections').post(collectionObj);

    dataFactory.updateCollection = (
      collectionId,
      newName // using customPUT because somehow the request payload includes
    ) =>
      // other stuff that the API rejects. doing this sends only what is specified
      Restangular.one('collections', collectionId).customPUT({ name: newName });

    dataFactory.deleteCollection = function (collection) {
      const { id } = collection;
      return Restangular.one('collections', id)
        .remove()
        .then(function () {
          $rootScope.$emit('collectionDeleted', id);
          return id;
        });
    };

    dataFactory.createCollectionMembership = (
      parentCollectionId,
      collectibleId,
      collectibleType,
      collectiblePriority
    ) =>
      Restangular.one('collections', parentCollectionId).post('members', {
        collectible_id: collectibleId,
        collectible_type: collectibleType,
        priority: collectiblePriority,
      });

    dataFactory.updateCollectionMembership = (
      collectionId,
      membershipId,
      newPriority
    ) =>
      Restangular.one('collections', collectionId)
        .one('members', membershipId)
        .customPUT({ priority: newPriority });

    dataFactory.deleteCollectionMembership = (collectionId, membershipId) =>
      Restangular.one('collections', collectionId)
        .one('members', membershipId)
        .remove();

    // upgrading restangular will allow us to use the .service() alias very nice
    return dataFactory;
  },
]);
