/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Convert a ruby date to a javascript date
angular
  .module('app')
  .filter('tkRubyDate', [
    'TKDateUtil',
    (TKDateUtil) => (date) => TKDateUtil.parseRubyDate(date),
  ]);
