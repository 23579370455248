/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app.services').factory('TKApprovalFilterManager', [
  '$log',
  function ($log) {
    const manager = {};

    // A Filter manages filter details
    class Filter {
      constructor(options, getData) {
        this.getData = getData;
        _.extend(this, options);
        this.dataLoaded = !this.getData;
        if (this.options == null) {
          this.options = [];
        }

        // Make sure multiselect has an array for its selections
        if (this.type === 'multiselect') {
          if (this.value == null) {
            this.value = null;
          }
        }
      }

      clearValue() {
        this.value = null;
      }

      getValue() {
        if (this.type === 'multiselect') {
          if (this.value == null) {
            return I18n.t('lbl_all');
          }

          if (!this.value.length) {
            return I18n.t('lbl_none');
          }

          const bits = _.reduce(
            this.value,
            (bits, option) => {
              if (option) {
                option = this.getOption(option);
              }
              if (option) {
                bits.push(option);
              }
              return bits;
            },
            []
          );
          return bits.join(', ');
        } else {
          if (this.getValueLabel) {
            return this.getValueLabel();
          }
        }
        return this.value;
      }

      getOption(option) {
        if (this.getOptionLabel) {
          return this.getOptionLabel(option);
        } else {
          return option;
        }
      }

      loadData() {
        if (!this.dataLoaded) {
          this.getData(this.options).then(() => {
            this.dataLoaded = true;
            if (this.deserializeOnLoad) {
              this.deserialize(this.deserializeOnLoad);
            }
          });
        }
      }

      setAllOptions(fromList) {
        _.each(fromList || this.options, (option) => {
          this.multiSelectAdd(option);
        });
      }

      multiSelectAdd(option) {
        // If value is null, then this option is already added, because null means All
        if (this.type === 'multiselect' && this.value != null) {
          const index = this.value.indexOf(option);
          if (index < 0) {
            this.value.push(option);
          }
          if (this.value.length === this.options.length) {
            // All options are selected so set to null
            this.value = null;
          }
        }
      }

      multiSelectRemove(option) {
        if (this.value == null) {
          this.value = _.clone(this.options);
        }
        const index = this.value.indexOf(option);
        if (index >= 0) {
          this.value.splice(index, 1);
        }
      }

      setOption($event, option, value, clear) {
        if (!clear) {
          $event.stopPropagation();
        }
        // $event.preventDefault()
        if (value) {
          if (clear) {
            this.value = [];
          }
          this.multiSelectAdd(option);
        } else {
          this.multiSelectRemove(option);
        }
      }

      optionSelected(option) {
        // Option
        if (option && this.value) {
          return this.value.indexOf(option) >= 0;
        }
        // Empty option means all (no filter)
        return this.value == null;
      }

      clearOptions($event, fromList) {
        if ($event) {
          $event.stopPropagation();
        }
        if (fromList) {
          _.each(fromList, (option) => {
            this.multiSelectRemove(option);
          });
        } else {
          if (this.value == null) {
            this.value = [];
          }
          this.value.splice(0, this.value.length);
        }
      }

      // For deserializing it is necessary to look up options by id
      optionLookupById(id) {
        return _.find(this.options, { id }) || null;
      }

      serialize() {
        // Extract ids if these are objects with ids, otherwise return the objects themselves
        if (this.value == null) {
          return null;
        }

        if (this.type === 'multiselect') {
          return _.map(this.value, (val) => val.id || val);
        } else {
          return (this.value != null ? this.value.id : undefined) || this.value;
        }
      }

      deserialize(value) {
        if (!this.dataLoaded) {
          this.deserializeOnLoad = value;
          return;
        }

        if (value == null) {
          this.value = null;
          return;
        }

        if (this.type === 'dateRange' && _.isObject(value)) {
          value.start = new Date(value.start);
          value.end = new Date(value.end);
          this.value = value;
        } else if (this.type === 'multiselect') {
          this.value = _.map(value, (val) => this.optionLookupById(val) || val);
        } else {
          this.value = this.optionLookupById(value) || value;
        }
      }
    }

    // A FilterGroup has a name and can have many filters
    class FilterGroup {
      constructor(label) {
        this.label = label;
        this.filters = [];
      }

      addFilter(filter) {
        this.filters.push(filter);
      }
    }

    // A FilterSet is a collection of FilterGroups
    class FilterSet {
      constructor(groups, name) {
        this.groups = groups;
        this.name = name;
        this.filters = {};
        if (this.name == null) {
          this.name = '';
        }

        _.each(this.groups, (group) => {
          _.each(group.filters, (filter) => {
            if (this.filters[filter.name]) {
              throw `Error, found duplicate filters named ${filter.name}`;
            }
            this.filters[filter.name] = filter;
          });
        });
      }

      getFilter(name) {
        return this.filters[name];
      }

      serialize() {
        const obj = {
          name: this.name,
          values: {},
        };
        _.each(this.filters, function (filter) {
          const value = filter.serialize();
          if (value != null) {
            obj.values[filter.name] = value;
          }
        });

        return JSON.stringify(obj);
      }

      deserialize(obj) {
        obj = JSON.parse(obj);
        this.name = obj.name;
        _.each(this.filters, function (filter) {
          filter.value = null;
        });

        _.each(obj.values, (value, name) => {
          // Apply the value to the filter
          const filter = this.filters[name];
          if (!filter) {
            $log.error(`User settings have value for missing filter ${name}`);
          } else {
            filter.deserialize(value);
          }
        });
      }

      clearFiltersForGroup(group) {
        _.invoke(group.filters, 'clearValue');
      }
    }

    manager.createFilterSet = function (filterGroups) {
      const filterSet = new FilterSet(filterGroups);
      return filterSet;
    };

    manager.createFilterGroup = function (label) {
      const filterGroup = new FilterGroup(label);
      return filterGroup;
    };

    manager.createFilter = function (optionsObj, getDataFn) {
      if (getDataFn == null) {
        getDataFn = false;
      }
      const filter = new Filter(optionsObj, getDataFn);
      return filter;
    };

    return manager;
  },
]);
