angular.module('app').directive('tkApprovablesheets', [
  '$rootScope',
  '$log',
  '$q',
  'TKData',
  'TKDateUtil',
  'TKOrgSettings',
  'TKCleanup',
  ($rootScope, $log, $q, TKData, TKDateUtil, TKOrgSettings, TKCleanup) => ({
    restrict: 'E',
    templateUrl: 'ng-approvals/templates/approvablesheets',
    replace: true,

    scope: {
      userId: '=',
      approvablesheets: '=',
      filterSet: '=',
      mode: '@',
    },

    controller: function ($scope) {
      const VISIBLE_SHEET_COUNT_INCREMENT = 10;

      $scope.I18n = I18n;

      const orgSettings = null;
      $scope.modeSubmit = $scope.mode === 'submit';
      const approvalFilter = $scope.filterSet.getFilter('approvalState');
      $scope.visibleSheetCount = 0;

      ($scope.increaseVisibleSheetCount = function () {
        $scope.visibleSheetCount += VISIBLE_SHEET_COUNT_INCREMENT;
        if (
          $scope.approvablesheets.length - $scope.visibleSheetCount <
          VISIBLE_SHEET_COUNT_INCREMENT
        ) {
          $scope.visibleSheetCount = $scope.approvablesheets.length;
        }
      })();
      $scope.canShowMoreSheets = () =>
        $scope.approvablesheets.length > $scope.visibleSheetCount;
      $scope.showsPending = () =>
        approvalFilter &&
        approvalFilter.value === I18n.t('lbl_pending_approval');
      $scope.showsApproved = () =>
        approvalFilter && approvalFilter.value === I18n.t('lbl_approved');
      $scope.showsUnsubmitted = () =>
        !approvalFilter || approvalFilter.value === I18n.t('lbl_unsubmitted');
      $scope.showsRejected = () =>
        approvalFilter.value === I18n.t('lbl_rejected');

      const cleanup = TKCleanup.newCleanup($scope);

      cleanup(
        $rootScope.$on('tk-approvablesheet-submitted', function () {
          ++$scope.visibleSheetCount;
        })
      );

      var unwatch = $scope.$watch('approvablesheets.length', function () {
        const sheetCount = $scope.approvablesheets.length;
        if ($scope.visibleSheetCount >= VISIBLE_SHEET_COUNT_INCREMENT) {
          unwatch();
        } else if ($scope.visibleSheetCount < sheetCount) {
          $scope.visibleSheetCount = Math.min(
            VISIBLE_SHEET_COUNT_INCREMENT,
            sheetCount
          );
        }
      });

      cleanup(unwatch);
    },
  }),
]);
