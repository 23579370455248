angular.module('app.services', ['angular-data.DSCacheFactory', 'restangular']);
angular.module('app.filters', []);
angular.module('app', [
  'ui.router',
  'ui.router.stateHelper',
  'app.services',
  'app.filters',
  'infinite-scroll',
  'datePicker',
  'a8m.group-by',
  'ngAnimate',
]);
