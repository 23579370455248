angular.module('app').directive('tkPane', [
  '$log',
  '$q',
  'TKData',
  'TKOrgSettings',
  'TKDateUtil',
  ($log, $q, TKData, TKOrgSettings, TKDateUtil) => ({
    restrict: 'E',
    transclude: true,
    template: '<div class="tk-pane" ng-transclude></div>',
  }),
]);
