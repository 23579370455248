/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// This service provides a library of date modification and query methods

angular.module('app.services').factory('TKDateUtil', [
  '$log',

  function ($log) {
    const util = {};

    const _rubyDateRegex = new RegExp(/(\d*)-(\d*)-(\d*)T(\d*):(\d*):(\d*)/);

    util.addDays = function (d, n) {
      d = new Date(d.getTime());
      d.setDate(d.getDate() + n);
      return d;
    };

    util.toStartOfDay = function (d) {
      d = new Date(d.getTime());
      d.setHours(0, 0, 0, 0);
      return d;
    };

    util.isWithinTimeRange = function (startDate, endDate) {
      let middle;
      return (
        startDate <= (middle = util.toStartOfDay(new Date())) &&
        middle <= endDate
      );
    };

    util.min = (...dates) => _.min(dates);

    util.max = (...dates) => _.max(dates);

    util.daysInMonth = (d) =>
      new Date(d.getYear(), d.getMonth() + 1, 0).getDate();

    util.toStartOfMonth = function (d) {
      d = util.toStartOfDay(d);
      d.setDate(1);
      return d;
    };

    util.toEndOfMonth = function (d) {
      d = util.toStartOfDay(d);
      d.setDate(util.daysInMonth(d));
      return d;
    };

    util.toStartOfWeek = function (d, day) {
      d = util.toStartOfDay(d);
      day = day || 0;
      while (d.getDay() !== day) {
        d.setDate(d.getDate() - 1);
      }
      return d;
    };

    util.toEndOfWeek = function (d, day) {
      d = util.toStartOfDay(d);
      day = day || 6;
      while (d.getDay() !== day) {
        d.setDate(d.getDate() + 1);
      }
      return d;
    };

    util.parseRubyDateTime = function (date, includeTime) {
      let d;
      const matches = _rubyDateRegex.exec(date);
      if ((matches != null ? matches.length : undefined) === 7) {
        d = new Date(
          matches[2] +
            '/' +
            matches[3] +
            '/' +
            matches[1] +
            ' ' +
            matches[4] +
            ':' +
            matches[5] +
            ':' +
            matches[6] +
            ' GMT'
        );
      } else {
        d = new Date(date);
      }

      if (!includeTime) {
        d = util.toStartOfDay(d);
      }

      return d;
    };

    util.parseRubyDate = function (date, skipValidation) {
      if (date) {
        const nums = date.split(/\D/);
        --nums[1];
        let d = new Date(nums[0], nums[1], nums[2]);
        if (!skipValidation && isNaN(d.getTime())) {
          d = parseRubyDateTime(date);
        }
        return d;
      }
    };

    util.toRubyDate = (date) =>
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    //date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()

    util.toHours = (timeInMs) => timeInMs / (1000 * 60 * 60);

    util.shortDateString = (date) =>
      I18n.strftime(date, I18n.lookup('date').formats.short);

    util.dateRangeString = (dateRange) =>
      util.shortDateString(dateRange.start) +
      ' - ' +
      util.shortDateString(dateRange.end);

    util.getDateChunks = function (startDate, endDate, chunkSize, unit) {
      const chunks = [];

      let chunkStart = startDate;
      if (unit === 'calendar week') {
        chunkStart = util.toStartOfWeek(startDate);
      }

      if (_.contains(unit, 'week')) {
        chunkSize = chunkSize * 7;
      }

      chunkSize -= 1;

      let chunkEnd = util.addDays(chunkStart, chunkSize);
      chunks.push([chunkStart, chunkEnd]);

      while (chunkEnd < endDate) {
        chunkStart = util.addDays(chunkEnd, 1);
        chunkEnd = util.addDays(chunkStart, chunkSize);
        chunks.push([chunkStart, chunkEnd]);
      }

      return chunks;
    };

    return util;
  },
]);
