/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkPopover', [
  '$parse',
  '$timeout',
  'TKPopoverManager',
  function ($parse, $timeout, TKPopoverManager) {
    const TOP = 'n';
    const BOTTOM = 's';
    const LEFT = 'w';
    const RIGHT = 'e';
    const CENTER = 'c';

    const DEFAULT_FLOW_ORDER = [
      TOP + CENTER,
      RIGHT + CENTER,
      LEFT + CENTER,
      BOTTOM + CENTER,
      TOP + LEFT,
      BOTTOM + LEFT,
      TOP + RIGHT,
      BOTTOM + RIGHT,
      RIGHT + TOP,
      LEFT + TOP,
      RIGHT + BOTTOM,
      LEFT + BOTTOM,
    ];

    const ANCHOR_CLASS_NAME_MAP = {};
    ANCHOR_CLASS_NAME_MAP[TOP] = 'top';
    ANCHOR_CLASS_NAME_MAP[BOTTOM] = 'bottom';
    ANCHOR_CLASS_NAME_MAP[RIGHT] = 'right';
    ANCHOR_CLASS_NAME_MAP[LEFT] = 'left';

    const CORNER_FRACTION_MAP = {};
    CORNER_FRACTION_MAP[TOP] = CORNER_FRACTION_MAP[LEFT] = 0;
    CORNER_FRACTION_MAP[BOTTOM] = CORNER_FRACTION_MAP[RIGHT] = 1;
    CORNER_FRACTION_MAP[CENTER] = 1 / 2;

    let $window = $(window);

    const addToDom = function (popover) {
      const template = `<div class='tk-popover' style='opacity:0;'> \
<div class='tk-arrow'></div> \
<div class='tk-popover-content'></div> \
</div>`;
      popover.el = $(template);
      popover.el.find('.tk-popover-content').append(popover.getContent());
      popover.parentEl.append(popover.el);
    };

    const positionAndShowPopover = function (popover) {
      // position
      const anchors = popover.getAnchors();
      const forceReturn = anchors.length === 1;
      const [offsets, anchor] = Array.from(
        getOffsetsAndAnchor(popover, anchors, forceReturn)
      );
      const outerWidth = popover.el.outerWidth();
      const widthAddition = +popover.getWidthAddition() || 0;
      const css = {
        width: outerWidth + widthAddition + 'px', // this is necessary to prevent unwanted text wrapping
        opacity: 1,
      };

      popover.el
        .offset(offsets)
        .css(css)
        .addClass(getBootstrapClassName(anchor));
      // show
      popover.el.show();
    };

    var getBootstrapClassName = function (anchor) {
      if (!anchor) {
        throw 'no anchor provided';
      }
      return ANCHOR_CLASS_NAME_MAP[getPrimaryAnchorPosition(anchor)];
    };

    var getOffsetsAndAnchor = function (popover, anchors, forceReturn) {
      const { el, parentEl } = popover;
      anchors = anchors || popover.getAnchors();
      // TODO figure out why subtracting the offset context is needed sometimes, but not always
      const offsetContext = { top: 0, left: 0 }; //parentEl.offsetParent().offset()
      const [prTop, prLeft, prWidth, prHeight] = Array.from(
        getDimensions(parentEl)
      );
      const popHeight = el.outerHeight();
      const popWidth = el.outerWidth();
      const offsets = {
        top: getOffsetTop(
          anchors[0],
          prTop,
          prHeight,
          popHeight,
          offsetContext.top
        ),
        left: getOffsetLeft(
          anchors[0],
          prLeft,
          prWidth,
          popWidth,
          offsetContext.left
        ),
      };
      if (
        isWithinViewport(
          offsets,
          popHeight,
          popWidth,
          offsetContext,
          popover.getViewport()
        ) ||
        forceReturn
      ) {
        return [offsets, anchors[0]];
      } else if (anchors.length === 1) {
        return getOffsetsAndAnchor(
          popover,
          [getMostReasonableAnchor(prTop, prLeft)],
          true
        );
      } else {
        return getOffsetsAndAnchor(popover, anchors.slice(1));
      }
    };

    var getMostReasonableAnchor = function (prTop, prLeft) {
      const centerOfScreenX = $window.width() / 2 + $window.scrollLeft();
      const centerOfScreenY = $window.height() / 2 + $window.scrollTop();
      if (prTop < centerOfScreenY && prLeft < centerOfScreenX) {
        return BOTTOM + RIGHT;
      } else if (prTop > centerOfScreenY && prLeft < centerOfScreenX) {
        return TOP + RIGHT;
      } else if (prTop < centerOfScreenY && prLeft > centerOfScreenX) {
        return BOTTOM + LEFT;
      } else {
        return TOP + LEFT;
      }
    };

    var getOffsetTop = function (
      anchor,
      prTop,
      prHeight,
      popHeight,
      offsetContextTop
    ) {
      let top;
      const primaryAnchorPosition = getPrimaryAnchorPosition(anchor);
      if (primaryAnchorPosition === RIGHT || primaryAnchorPosition === LEFT) {
        const fraction = getCornerFraction(anchor);
        top = prTop + prHeight * fraction - popHeight / 2;
      } else if (primaryAnchorPosition === TOP) {
        top = prTop - popHeight;
      } else if (primaryAnchorPosition === BOTTOM) {
        top = prTop + prHeight;
      }
      return Math.max(0, top) - offsetContextTop;
    };

    var getOffsetLeft = function (
      anchor,
      prLeft,
      prWidth,
      popWidth,
      offsetContextLeft
    ) {
      let left;
      const primaryAnchorPosition = getPrimaryAnchorPosition(anchor);
      if (primaryAnchorPosition === TOP || primaryAnchorPosition === BOTTOM) {
        const fraction = getCornerFraction(anchor);
        left = prLeft + prWidth * fraction - popWidth / 2;
      } else if (primaryAnchorPosition === RIGHT) {
        left = prLeft + prWidth;
      } else if (primaryAnchorPosition === LEFT) {
        left = prLeft - popWidth;
      }
      return Math.max(0, left) - offsetContextLeft;
    };

    var isWithinViewport = function (
      { top, left },
      popHeight,
      popWidth,
      offsetContext,
      viewport
    ) {
      top += offsetContext.top;
      left += offsetContext.left;
      const bottom = top + popHeight;
      const right = left + popWidth;

      const viewportOffset = (!(viewport[0] instanceof Window) &&
        viewport.offset()) || { top: 0, left: 0 };
      const viewportTop = viewportOffset.top + $window.scrollTop();
      const viewportLeft = viewportOffset.left + $window.scrollLeft();
      const viewportBottom = viewportTop + viewport.height();
      const viewportRight = viewportLeft + viewport.width();

      return (
        top > viewportTop &&
        left > viewportLeft &&
        bottom < viewportBottom &&
        right < viewportRight
      );
    };

    var getPrimaryAnchorPosition = (anchor) => anchor.charAt(0);

    const getSecondaryAnchorPosition = (anchor) => anchor.charAt(1);

    var getCornerFraction = (anchor) =>
      CORNER_FRACTION_MAP[getSecondaryAnchorPosition(anchor)];

    var getDimensions = function (el, screenSpace) {
      if (screenSpace == null) {
        screenSpace = false;
      }
      $window = $(window);
      let { top, left } = el.offset();
      if (screenSpace) {
        const scrollTop = $window.scrollTop();
        const scrollLeft = $window.scrollLeft();
        top = top - scrollTop;
        left = left - scrollLeft;
      }
      const width = el.outerWidth();
      const height = el.outerHeight();

      return [top, left, width, height];
    };

    const anchorsWithDefaults = (anchors) =>
      (anchors || []).concat(_.difference(DEFAULT_FLOW_ORDER, anchors));

    return {
      restrict: 'AE',
      transclude: 'element',
      // CONTROL PARAMS
      // visibleModel [required] : the boolean model that controls the visibility of the popover.
      // visibleIf: optional conditional expression, in addition to visibleModel, to control popover visibility.
      // anchors: the preferred precedence of anchors, e.g. "sc sw wc".
      // forceAnchor: if truthy, the first anchor will be used, and no attempt will be made to find a better fitting anchor.
      // viewport: jquery selector of the containing element to stay within the bounds of. defaults to window.
      // afterRender: expression to execute after the popover has rendered.
      // widthAddition: number of pixels to add to the width of the popover.
      link(scope, iElement, iAttrs, controller, transclude) {
        let afterRender, isVisible;
        const visibleModel = $parse(iAttrs.visibleModel || iAttrs.visible);

        if (iAttrs.visibleIf) {
          const visibleIf = $parse(iAttrs.visibleIf);
          isVisible = () => visibleIf(scope, {}) && visibleModel(scope, {});
        } else {
          isVisible = () => visibleModel(scope, {});
        }

        if (iAttrs.afterRender) {
          afterRender = $parse(iAttrs.afterRender);
        } else {
          afterRender = _.noop;
        }

        const getWidthAddition = $parse(iAttrs.widthAddition);

        var popover = {
          id: _.uniqueId(),
          visible: isVisible(),
          parentEl: iElement.parent(),
          getWidthAddition() {
            return getWidthAddition(scope, {});
          },
          getViewport() {
            return (popover.viewport =
              popover.viewport || iAttrs.viewport
                ? iElement.parents(iAttrs.viewport)
                : $window);
          },
          getAnchors() {
            return (popover.anchors =
              popover.anchors ||
              (function () {
                const anchors = anchorsWithDefaults(
                  iAttrs.anchors != null ? iAttrs.anchors.split(' ') : undefined
                );
                if (iAttrs.forceAnchor && $parse(iAttrs.forceAnchor)(scope)) {
                  return [anchors[0]];
                } else {
                  return anchors;
                }
              })());
          },
          getContent() {
            let content = null;
            transclude(scope, function (clone) {
              content = clone;
            });
            return content;
          },
        };

        const apiForManager = {
          id: popover.id,
          remove() {
            popover.el.remove();
            popover.visible = false;
            visibleModel.assign(scope, false);
            _.defer(function () {
              // `scope.$apply()` is deferred because `remove` is already called from
              // within a $digest loop, but for some reason `visibleModel.assign scope, false`
              // does not get noticed by the scope unless `scope.$apply` is called afterwards.
              // In other words, when `isVisible()` changes back to `true`, the $watch listener
              // will not register any change unless `scope.$apply` has been called previously.
              // TODO figure out why this is happening.
              scope.$apply();
            });
          },
          hasClick(evnt) {
            const { clientX, clientY } = evnt;
            const [top, left, width, height] = Array.from(
              getDimensions(popover.el, true)
            );
            const isWithinBox =
              clientX > left &&
              clientX < left + width &&
              clientY > top &&
              clientY < top + height;
            return isWithinBox;
          },
        };

        const makePopoverVisible = function () {
          $timeout(function () {
            addToDom(popover);
            $timeout(function () {
              positionAndShowPopover(popover);
              afterRender(scope, { elements: [popover.el] });
            });
            TKPopoverManager.registerPopover(apiForManager);
          });
          popover.visible = true;
        };

        const unwatch = scope.$watch(isVisible, function (visible) {
          if (visible !== popover.visible) {
            if (visible) {
              makePopoverVisible();
            } else {
              // TODO: for some reason TKPopoverManager does not exist when clicking
              // inside the popover. Added a guardian to prevent errors
              // when this occurs. This should not be necessary.
              if (TKPopoverManager) {
                TKPopoverManager.removePopover(popover.id);
              }
            }
          }
        });

        popover.parentEl.on('$destroy', function () {
          unwatch();
        });

        if (popover.visible) {
          makePopoverVisible();
        }
      },
    };
  },
]);
