angular.module('app.services').factory('TKLegacyApi', [
  '$q',
  '$http',
  function ($q, $http) {
    const BASE_URL = window.SERVICE_BASE_URL;

    const getUsers = () =>
      $http.get(`${BASE_URL}/users`, { withCredentials: true });

    const getSubscription = () =>
      $http.get(`${BASE_URL}/organizations/subscription`, {
        withCredentials: true,
      });

    const updateSubscription = (params) => {
      const token = $('meta[name="csrf-token"]').attr('content');
      return $http.put(
        `${BASE_URL}/organizations/subscription/update`,
        params,
        {
          headers: { 'X-CSRF-Token': token },
          withCredentials: true,
        }
      );
    };

    const getPlans = () =>
      $http.get(`${BASE_URL}/plans`, { withCredentials: true });

    return {
      getUsers,
      getSubscription,
      updateSubscription,
      getPlans,
    };
  },
]);
