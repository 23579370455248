angular.module('app').directive('tkRadioButton', [
  () => ({
    restrict: 'AE',
    templateUrl: 'ng-common/templates/radioButton',
    replace: true,

    scope: {
      selected: '&',
    },

    link(scope, el) {},
  }),
]);
