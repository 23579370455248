/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// A class to manager time entries for a single assignable
const Cls = (this.TimeTrackerRow = class TimeTrackerRow {
  static initClass() {
    this.prototype.isLockedBecauseProjectLockout = (function () {
      const NO_LOCKOUT = -1;
      const COMPLETE_LOCKOUT = 0;
      const isOlderThanLockoutWindow = function (date, lockout, TKDateUtil) {
        const today = TKDateUtil.toStartOfDay(new Date());
        const minimumDate = TKDateUtil.addDays(today, -lockout);
        return date < minimumDate;
      };

      return function (date) {
        const lockout = this.assignable.timeentry_lockout;
        if (lockout === NO_LOCKOUT) {
          return false;
        } else if (lockout === COMPLETE_LOCKOUT) {
          return true;
        } else if (lockout > 0) {
          return isOlderThanLockoutWindow(date, lockout, this.TKDateUtil);
        }
      };
    })();
  }

  constructor(
    TKDateUtil,
    TKData,
    TKTimeEntryHelper,
    TKErrorMessenger,
    log,
    q,
    assignable,
    collapseRows,
    itemized
  ) {
    this.TKDateUtil = TKDateUtil;
    this.TKData = TKData;
    this.TKTimeEntryHelper = TKTimeEntryHelper;
    this.TKErrorMessenger = TKErrorMessenger;
    this.log = log;
    this.q = q;
    this.assignable = assignable;
    this.collapseRows = collapseRows;
    this.itemized = itemized;
    this.timeEntriesByDate = {};
    this.confirmedHours = 0;
    this.scheduledHours = 0;
    this.scheduledHoursTotal = 0;
    this.placeholder = false;
    this.visible = false;
    this.rows = [];
    this.approvalRows = [];
  }

  isProject() {
    return false;
  }

  isLeave() {
    return false;
  }

  sortValue() {
    const toLowerCase = (string) =>
      (string != null ? string.toLowerCase() : undefined) || '';
    const { client, name, phase_name } = this.assignable;
    return [client, name, phase_name].map(toLowerCase).join('');
  }

  summaryData(date) {
    const data = this.timeEntriesByDate[date.toDateString()];
    const summary = {
      suggestedCount: 0,
      suggestedHours: 0,
      confirmedZeroCount: 0,
      confirmedCount: 0,
      confirmedHours: 0,
      placeholderCount: 0,
    };

    if (data) {
      _.each(data.timeEntries, function (timeEntry) {
        if (timeEntry.hours === 0) {
          ++summary.confirmedZeroCount;
        } else if (timeEntry.hours > 0) {
          ++summary.confirmedCount;
          summary.confirmedHours += timeEntry.hours;
        } else if (timeEntry.scheduled_hours) {
          summary.suggestedHours += timeEntry.scheduled_hours;
          ++summary.suggestedCount;
        }
        if (timeEntry.placeholder) {
          return ++summary.placeholderCount;
        }
      });
    }

    summary.tooltip =
      `${I18n.t('msg_suggested_count')} = ${summary.suggestedCount}/${
        summary.suggestedHours
      }.\n` +
      `${I18n.t('lbl_zeros')} = ${summary.confirmedZeroCount}.\n${I18n.t(
        'msg_confirmed_count'
      )} = ${summary.confirmedCount}/${summary.confirmedHours}`;
    if (summary.placeholderCount) {
      summary.tooltip += `\nshowing ${summary.placeholderCount} placeholders`;
    }

    return summary;
  }

  rowSummary(i = null) {
    let confirmed, scheduled;
    if (!i) {
      confirmed = this.confirmedHours || 0;
      scheduled = this.scheduledHoursTotal || 0;
    } else {
      confirmed = 0;
      scheduled = 0;

      _.each(this.rows[i].timeEntriesByDate, function (timeEntry) {
        confirmed += timeEntry.hours || 0;
        return (scheduled += timeEntry.scheduled_hours || 0);
      });
    }

    const barValues = [0, 0, 0, 0];
    if (this.incurred <= this.expectedIncurred) {
      barValues[0] = this.incurred;
      barValues[1] = this.expectedIncurred - this.incurred;
    } else if (this.incurred > this.expectedIncurred) {
      barValues[0] = this.expectedIncurred;
      barValues[2] = this.incurred - this.expectedIncurred;
    }
    barValues[3] = this.futureScheduled;

    return {
      confirmed,
      scheduled,
      total: scheduled,
      incurred: this.incurred,
      futureScheduled: this.futureScheduled,
      expectedIncurred: this.expectedIncurred,
      barValues,
      barDataWidth: barValues[0] + barValues[1] + barValues[2] + barValues[3],
    };
  }

  isLockedBecauseApproved(date, i = null) {
    let timeEntries;
    let locked = false;

    if (date) {
      if (this.itemized && i == null) {
        // For itemized mode, approval status should not lock the top row
        return false;
      }

      this.approvalLocksCache = this.approvalLocksCache || {}; // This helps avoid all the repeated loops
      if (this.approvalLocksCache[date.toDateString() + i] != null) {
        return this.approvalLocksCache[date.toDateString() + i];
      }

      timeEntries = this.getTimeEntries(date, i);
      _.each(timeEntries, function (timeEntry) {
        if (
          __guard__(
            timeEntry.approvals != null
              ? timeEntry.approvals.data[0]
              : undefined,
            (x) => x.status
          ) === 'approved'
        ) {
          locked = true;
          return false;
        }
      });
    } else {
      _.each(this.timeEntriesByDate, (data, dateString) =>
        _.each(data.timeEntries, function (timeEntry) {
          if (
            __guard__(
              timeEntry.approvals != null
                ? timeEntry.approvals.data[0]
                : undefined,
              (x) => x.status
            ) === 'approved'
          ) {
            locked = true;
            return false;
          }
        })
      );
    }

    this.approvalLocksCache[date.toDateString() + i] = locked;
    return locked;
  }

  isLockedBecauseProjectArchived() {
    return !!this.assignable.deleted_at;
  }

  isLockedButNotApproved(date, i) {
    return this.isLocked(date, i) && !this.isLockedBecauseApproved(date, i);
  }

  isLocked(date, i = null) {
    return (
      this.isLockedBecauseProjectArchived() ||
      this.isLockedBecauseProjectLockout(date) ||
      this.isLockedBecauseApproved(date, i)
    );
  }

  getLockedTitleMessage(
    date,
    i,
    lockedBecauseExistingItemizedEntryWithBlankCategoryButOrgDoesNotAllowBlankCategories
  ) {
    let message;
    const lockedByArchived = this.isLockedBecauseProjectArchived();
    const lockedByLockout = this.isLockedBecauseProjectLockout(date);
    const lockedByApproval = this.isLockedBecauseApproved(date, i);

    if (lockedByArchived) {
      message = I18n.t('msg_archived_project');
    } else if (lockedByLockout && lockedByApproval) {
      message = I18n.t('msg_time_entry_approved');
    } else if (lockedByLockout) {
      message = I18n.t('msg_time_entries_locked');
    } else if (lockedByApproval) {
      message = I18n.t('msg_time_entry_approved_locked');
    } else if (
      lockedBecauseExistingItemizedEntryWithBlankCategoryButOrgDoesNotAllowBlankCategories
    ) {
      return I18n.t('msg_categories_required');
    }

    if (message) {
      return message + I18n.t('msg_contact_admin');
    } else {
      return null;
    }
  }

  confirmedTimeEntriesByDate(date) {
    const data = this.timeEntriesByDate[date.toDateString()];
    const confirmed = [];

    if (data) {
      _.each(data.timeEntries, function (timeEntry) {
        if (timeEntry.hours != null) {
          return confirmed.push(timeEntry);
        }
      });
    }

    return confirmed;
  }

  calculateRowTime(date) {
    const data = this.timeEntriesByDate[date.toDateString()];
    if (data) {
      data.confirmedHours = null;
      data.scheduledHours = null;
      return _.each(data.timeEntries, function (timeEntry) {
        if (timeEntry.hours != null) {
          if (data.confirmedHours == null) {
            data.confirmedHours = 0;
          }
          data.confirmedHours += timeEntry.hours;
        }
        if (timeEntry.scheduled_hours != null) {
          if (data.scheduledHours == null) {
            data.scheduledHours = 0;
          }
          return (data.scheduledHours += timeEntry.scheduled_hours);
        }
      });
    }
  }

  updateApprovalRows() {
    const { isConfirmed, isPlaceholder } = this.TKTimeEntryHelper;
    const includeTimeEntry = (timeEntry) =>
      isConfirmed(timeEntry) || isPlaceholder(timeEntry);

    const rows = [];

    _.each(this.timeEntriesByDate, function (data, date) {
      _.each(data.timeEntries, function (timeEntry) {
        if (includeTimeEntry(timeEntry)) {
          rows.push({ timeEntry });
        }
      });
    });

    rows.sort(function (a, b) {
      a = a.timeEntry;
      b = b.timeEntry;
      const atime = a.jsDate.getTime();
      const btime = b.jsDate.getTime();
      if (atime !== btime) {
        return atime - btime;
      }

      if (a.task < b.task) {
        return -1;
      } else if (a.task > b.task) {
        return 1;
      }

      return 0;
    });

    return (this.approvalRows = rows);
  }

  // When time entry collections have changed, recalculate the rows
  updateTimeTrackingRows() {
    const { isConfirmed, isPlaceholder } = this.TKTimeEntryHelper;
    const includeTimeEntry = (timeEntry) =>
      isConfirmed(timeEntry) || isPlaceholder(timeEntry);

    // Calculate how many rows are needed for each task (including null for taskless time entries)
    const rowsByTask = {};

    _.each(this.timeEntriesByDate, (data, date) => {
      const countsByTask = {};

      _.each(data.timeEntries, (timeEntry) => {
        const task = timeEntry.task || null;
        if (
          (isPlaceholder(timeEntry) && timeEntry.hours > 0) ||
          !includeTimeEntry(timeEntry)
        ) {
          return;
        }
        if (countsByTask[task] == null) {
          countsByTask[task] = 0;
        }
        countsByTask[task]++;
        if (this.collapseRows) {
          if (rowsByTask[task] == null) {
            rowsByTask[task] = 1;
          }
        } else {
          rowsByTask[task] = Math.max(
            countsByTask[task],
            rowsByTask[task] || 0
          );
        }
      });
    });

    // Remove any existing rows that need removing, subtract any existing rows from the required rows so that
    // rowsByTask will end up being the set of new rows to add
    _.each(this.rows, (row) => {
      const task = row.task || null;
      if (rowsByTask[task]) {
        --rowsByTask[task];
      }
      // Clear the time entries in this row ready for re-population
      row.timeEntriesByDate = {};
    });

    // add the rows from rowsbytask with empty timeEntries
    _.each(rowsByTask, (count, task) => {
      if (task === 'null') {
        task = null;
      }
      while (count--) {
        this.rows.push({
          task,
          id: _.uniqueId(),
          timeEntriesByDate: {},
        });
      }
    });

    // Sort itemized rows by task
    this.rows.sort(function (rowA, rowB) {
      let a, b;
      if (rowA.task === rowB.task) {
        // This is needed to ensure deterministic sorting.
        // Otherwise day view rows with the same task will jump around randomly.
        a = rowA.id;
        b = rowB.id;
      } else {
        a = (rowA.task || '').toLowerCase();
        b = (rowB.task || '').toLowerCase();
      }
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });

    // The remaining code in this function only applies to itemized.
    if (!this.itemized) {
      return;
    }

    // Build a lookup of rows by id for inserting time entries into rows that they were previously
    // associated with. This solves issues with sparse data and multiple rows per task
    const rowsById = _.indexBy(this.rows, 'id');

    // Slot each existing time entry into a row
    _.each(this.timeEntriesByDate, (data, date) => {
      _.each(data.timeEntries, (timeEntry) => {
        if (!includeTimeEntry(timeEntry)) {
          return;
        }
        if (timeEntry.rowId && rowsById[timeEntry.rowId]) {
          // This time entry already belongs to an existing row - make sure it goes back in the same
          // one for consistency
          const row = rowsById[timeEntry.rowId];
          if (row.timeEntriesByDate[date] == null) {
            row.timeEntriesByDate[date] = [];
          }
          row.timeEntriesByDate[date].push(timeEntry);
          timeEntry = null;
        } else {
          // Find the first row that matches the task and that doesn't have a time entry
          _.each(this.rows, (row) => {
            if (timeEntry) {
              const timeEntryTask = timeEntry.task || null;
              const rowTask = row.task || null;
              if (rowTask === timeEntryTask) {
                if (row.timeEntriesByDate[date] == null) {
                  row.timeEntriesByDate[date] = [];
                }
                if (
                  (!this.collapseRows &&
                    row.timeEntriesByDate[date].length === 0) ||
                  this.collapseRows
                ) {
                  row.timeEntriesByDate[date].push(timeEntry);
                  timeEntry.rowId = row.id;
                  timeEntry = null;
                }
              }
            }
          });
        }

        if (timeEntry) {
          throw 'Unexpected timeEntry';
        }
      });
    });

    this.hasRowWithTask = !!_.find(this.rows, 'task');
  }

  addTimeEntry(timeEntry) {
    timeEntry.jsDate = this.TKDateUtil.parseRubyDate(timeEntry.date);
    const key = timeEntry.jsDate.toDateString();
    if (this.timeEntriesByDate[key] == null) {
      this.timeEntriesByDate[key] = {
        date: timeEntry.jsDate,
        confirmedHours: null,
        scheduledHours: null,
        timeEntries: [],
      };
    }

    const data = this.timeEntriesByDate[key];

    data.timeEntries.push(timeEntry);

    this.calculateRowTime(timeEntry.jsDate);
    return this.rowChanged();
  }

  removeTimeEntry(timeEntry) {
    const data = this.timeEntriesByDate[timeEntry.jsDate.toDateString()];
    _.pull(data.timeEntries, timeEntry);
    this.calculateRowTime(timeEntry.jsDate);
    return this.rowChanged();
  }

  removePlaceholders(date, deferRowChanged) {
    const data = this.timeEntriesByDate[date.toDateString()];
    if (data) {
      _.remove(
        data.timeEntries,
        (timeEntry) => timeEntry.placeholder || timeEntry.temp
      );
      this.calculateRowTime(date);
      if (!deferRowChanged) {
        return this.rowChanged();
      }
    }
  }

  // Create new time entries and remove placeholder time entries for a date
  // @param {object} date object to remove placeholders from and create new time entry for
  saveTemps(date) {
    const deferred = this.q.defer();
    const data = this.timeEntriesByDate[date.toDateString()];
    if (data) {
      _.each(data.timeEntries, (timeEntry, i) => {
        if (timeEntry.temp) {
          this.createTimeEntry(timeEntry.user_id, date, null, {
            hours: 0,
          }).then(() => deferred.resolve());
        }
      });
    }
    return deferred.promise;
  }

  // Clear suggestions by creating a time entry that is a suggestion with 0 hours
  // @param {number} userId used to create new time entry for a user
  // @param {array} dates array of date objects to 'clear' suggestions from
  clearSuggestions(userId, dates) {
    const datesToSave = [];
    const deferred = this.q.defer();
    const promises = [];

    _.each(dates, (date) => {
      if (this.isLockedBecauseProjectLockout(date)) {
        return;
      }
      const summary = this.summaryData(date);
      if (
        summary.confirmedHours === 0 &&
        summary.confirmedZeroCount === 0 &&
        summary.suggestedCount !== 0
      ) {
        datesToSave.push(date);
        this.createTimeEntry(userId, date, null, { hours: 0, temp: true });
      }
    });

    _.each(datesToSave, (date) => {
      promises.push(this.saveTemps(date));
    });

    this.q.all(promises).then(() => deferred.resolve());

    return deferred.promise;
  }

  newErrorHandler(operation, timeEntry) {
    return this.TKErrorMessenger.newHandler({
      broadcast: {
        eventName: `tk-${operation}-time-entry-fail`,
        data: timeEntry,
      },
    });
  }

  // TODO write real doc block
  // to restore suggestions we simply need to delete any 0 hour time entry that is not a suggestion
  restoreSuggestions(dates) {
    const { isSuggestionClearing } = this.TKTimeEntryHelper;
    const promises = [];

    _.each(dates, (date) => {
      let data;
      if (this.isLockedBecauseProjectLockout(date)) {
        return;
      }
      this.removePlaceholders(date);
      if ((data = _.clone(this.getTimeEntries(date)))) {
        _.each(data, (timeEntry) => {
          if (timeEntry != null && isSuggestionClearing(timeEntry)) {
            this.removeTimeEntry(timeEntry);
            const errorHandler = this.newErrorHandler('delete', timeEntry);
            promises.push(
              this.TKData.deleteTimeEntry(timeEntry).then(_.noop, errorHandler)
            );
            this.calculateVisibility();
          }
        });
      }
    });

    return this.q.all(promises);
  }

  deleteRow(i, userId, dates) {
    const hasMultipleRows = this.rows.length > 1;
    const row = this.rows[i];
    const datesToClearSuggestionsFor = [];
    _.each(this.timeEntriesByDate, (timeEntriesObject, date) => {
      let { scheduledHours, timeEntries } = timeEntriesObject;
      const hasScheduledTime = scheduledHours > 0;
      if (hasMultipleRows || hasScheduledTime) {
        timeEntries = row.timeEntriesByDate[date];
      } else {
        timeEntries = _.clone(timeEntries);
      }
      _.each(timeEntries, (timeEntry) => {
        if (
          !timeEntry.is_suggestion &&
          __guard__(
            timeEntry.approvals != null
              ? timeEntry.approvals.data[0]
              : undefined,
            (x) => x.status
          ) !== 'approved'
        ) {
          if (hasScheduledTime) {
            datesToClearSuggestionsFor.push(timeEntry.jsDate);
          }
          this.removeTimeEntry(timeEntry);
          const errorHandler = this.newErrorHandler('delete', timeEntry);
          this.TKData.deleteTimeEntry(timeEntry).then(_.noop, errorHandler);
        }
      });
    });
    this.rows.splice(i, 1);
    this.rowChanged();
    this.clearSuggestions(userId, datesToClearSuggestionsFor);
    this.notesInputModel = null;
  }

  calculateTime() {
    const futureStartTime = this.TKDateUtil.toStartOfDay(new Date()).getTime();

    this.confirmedHours = null;
    this.scheduledHours = null;
    this.scheduledHoursTotal = null;
    this.placeholder = null;
    this.incurred = 0;
    this.futureScheduled = 0;
    this.expectedIncurred = 0;

    // incurred = confirmed
    // expectedIncurred = past scheduled time
    // future scheduled = future scheduled time
    return _.each(this.timeEntriesByDate, (timeEntryData) => {
      if (timeEntryData.confirmedHours != null) {
        if (this.confirmedHours == null) {
          this.confirmedHours = 0;
        }
        this.confirmedHours += timeEntryData.confirmedHours;
        this.incurred += timeEntryData.confirmedHours;
      } else if (timeEntryData.scheduledHours != null) {
        if (this.scheduledHours == null) {
          this.scheduledHours = 0;
        }
        this.scheduledHours += timeEntryData.scheduledHours;
        if (timeEntryData.date.getTime() >= futureStartTime) {
          // Scheduled time in the future
          this.futureScheduled += timeEntryData.scheduledHours;
        }
      }
      // else
      //   # Consider past scheduled but unconfirmed time to be incurred
      //   @incurred += timeEntryData.scheduledHours

      if (timeEntryData.scheduledHours != null) {
        if (this.scheduledHoursTotal == null) {
          this.scheduledHoursTotal = 0;
        }
        this.scheduledHoursTotal += timeEntryData.scheduledHours;
        if (
          timeEntryData.date.getTime() < futureStartTime ||
          timeEntryData.confirmedHours != null
        ) {
          this.expectedIncurred += timeEntryData.scheduledHours;
        }
      }

      return _.each(timeEntryData.timeEntries, (timeEntry) => {
        if (timeEntry.placeholder) {
          return (this.placeholder = true);
        }
      });
    });
  }

  rowChanged() {
    this.calculateTime();
    this.updateTimeTrackingRows();
    this.updateApprovalRows();
    this.approvalLocksCache = null;
  }

  isVisible() {
    return this.visible != null;
  }

  calculateVisibility() {
    this.visible =
      this.visible ||
      this.confirmedHours != null ||
      this.scheduledHours != null ||
      this.placeholder;
  }

  // Returns multiple values:
  // - a time entry if an index is passed, or none if null is passed for the index
  // - the number of confirmed hours for the time entry or the total for a null index
  // - the number of scheduled hours for the time entry or the total for a null index
  // - the set of data for the given data
  cellData(date, i) {
    const data = this.timeEntriesByDate[date.toDateString()];
    let confirmed = 0;
    let scheduled = 0;
    let timeEntry = null;
    if (data) {
      if (!this.itemized && data.timeEntries.length === 1 && i == null) {
        // For non-itemized cases, the top level cell data is the first time entry
        i = 0;
      }

      if (i != null) {
        timeEntry = data.timeEntries[i];
        confirmed = timeEntry != null ? timeEntry.hours : undefined;
        scheduled = timeEntry != null ? timeEntry.scheduled_hours : undefined;
      } else {
        confirmed = data != null ? data.confirmedHours : undefined;
        scheduled = data != null ? data.scheduledHours : undefined;
      }

      if (confirmed == null) {
        confirmed = null;
      }
    }

    return [timeEntry, confirmed, scheduled || 0, data];
  }

  hasTimeEntry(date, i) {
    if (i != null) {
      return (
        __guard__(
          this.rows[i] != null
            ? this.rows[i].timeEntriesByDate[date.toDateString()]
            : undefined,
          (x) => x.length
        ) > 0
      );
    }

    return (
      __guard__(
        this.timeEntriesByDate[date.toDateString()],
        (x1) => x1.length
      ) > 0
    );
  }

  getTimeEntries(date, i = null) {
    if (i != null) {
      return this.rows[i] != null
        ? this.rows[i].timeEntriesByDate[date.toDateString()]
        : undefined;
    }

    return __guard__(
      this.timeEntriesByDate[date.toDateString()],
      (x) => x.timeEntries
    );
  }

  lastEmptyRowByTaskForDate(task, date) {
    let i = this.rows.length;
    task = task || null;

    while (--i >= 0) {
      var row = this.rows[i];
      if ((!row.task && !task) || row.task === task) {
        if (this.collapseRows) {
          return i;
        } else {
          // if hours are zero or undefined, this row is considered empty
          var timeEntries = row.timeEntriesByDate[date.toDateString()];
          if (timeEntries && timeEntries.length > 0) {
            var timeEntry = timeEntries[0];
            if (timeEntries.length !== 1) {
              throw 'Unexpected!';
            }

            if (!timeEntry || (!timeEntry.hours && !timeEntry.notes)) {
              return i;
            }
          }
        }
      }
    }

    return i;
  }

  lastRowByTask(task) {
    let i = this.rows.length;
    task = task || null;

    while (--i >= 0) {
      var row = this.rows[i];
      if (!row.task && !task) {
        return i;
      }
      if (row.task === task) {
        return i;
      }
    }

    return i;
  }

  cellEditValue(date, i) {
    let confirmed, scheduled;
    let timeEntry = __guard__(
      this.rows[i] != null
        ? this.rows[i].timeEntriesByDate[date.toDateString()]
        : undefined,
      (x) => x[0]
    );
    if (timeEntry) {
      confirmed = timeEntry.hours;
      scheduled = timeEntry.scheduled_hours;
    } else {
      [timeEntry, confirmed, scheduled] = Array.from(this.cellData(date));
    }

    if (confirmed == null) {
      return scheduled;
    }
    if (confirmed > 0) {
      return confirmed;
    }
    return '';
  }

  // Returns an array of the dates for which time entries were deleted
  deleteAllTimeEntries() {
    const dates = [];
    _.each(this.timeEntriesByDate, (data, date) => {
      const toDelete = [];
      _.each(data.timeEntries, (timeEntry, i) => {
        // don't delete suggestions or approved time entries
        if (
          !timeEntry.is_suggestion &&
          __guard__(
            timeEntry.approvals != null
              ? timeEntry.approvals.data[0]
              : undefined,
            (x) => x.status
          ) !== 'approved'
        ) {
          toDelete.push(i);
        }
      });

      _.each(toDelete.reverse(), (i) => {
        const timeEntry = data.timeEntries[i];
        // add entries' dates for deletion
        dates.push(timeEntry.jsDate);

        const errorHandler = this.newErrorHandler('delete', timeEntry);
        this.TKData.deleteTimeEntry(timeEntry).then(_.noop, errorHandler);
        this.removeTimeEntry(timeEntry);
      });
    });
    return _.unique(dates);
  }

  // Deletes all time entries for a given date and returns a promise for completion
  deleteTimeEntriesForDate(date) {
    const data = this.timeEntriesByDate[date.toDateString()];
    const promises = [];
    _.each(data.timeEntries, (timeEntry) => {
      if (!timeEntry.is_suggestion) {
        const errorHandler = this.newErrorHandler('delete', timeEntry);
        promises.push(
          this.TKData.deleteTimeEntry(timeEntry).then(_.noop, errorHandler)
        );
        return delete this.timeEntriesByDate[date.toDateString()];
      }
    });

    return this.q.all(promises).then(() => {
      return this.rowChanged();
    });
  }

  setTimeEntry(date, i, fields) {
    // Do not set time entry if no change is made
    if (
      fields.hours ==
        this.timeEntriesByDate[date.toDateString()].confirmedHours &&
      fields.notes == this.timeEntriesByDate[date.toDateString()].notes
    ) {
      return;
    }
    let timeEntry;
    const data = this.timeEntriesByDate[date.toDateString()];
    if (i != null) {
      timeEntry = __guard__(
        this.rows[i].timeEntriesByDate[date.toDateString()],
        (x) => x[0]
      );
    } else {
      timeEntry = null;
      // Prefer to replace an existing confirmed entry
      _.each(data.timeEntries, function (te) {
        if (!te.is_suggestion) {
          timeEntry = te;
        }
      });

      // Otherwise start with the suggestion
      if (!timeEntry) {
        timeEntry = data.timeEntries[0];
      }
    }

    if (timeEntry.is_suggestion) {
      // Create a new time entry based on this suggestion but without deleting the suggestion
      timeEntry = {
        task: timeEntry.task,
        assignable_id: timeEntry.assignable_id,
      };
      _.assign(timeEntry, fields);

      return this.createTimeEntry(timeEntry.user_id, date, i, timeEntry);
    } else {
      _.assign(timeEntry, fields);

      this.calculateRowTime(date);

      if (timeEntry.placeholder) {
        const newTimeEntry = _.pick(
          timeEntry,
          'hours',
          'assignable_id',
          'date',
          'notes',
          'task'
        );
        return this.createTimeEntry(
          timeEntry.user_id,
          date,
          i,
          newTimeEntry
        ).then(() => {
          this.removePlaceholders(date);
        });
        return;
      } else {
        const resolve = (timeEntry) => {
          this.rowChanged();
          return timeEntry;
        };
        const errorHandler = this.newErrorHandler('update', timeEntry);
        // Update the existing time entry
        return this.TKData.updateTimeEntry(timeEntry).then(
          resolve,
          errorHandler
        );
      }
    }
  }

  createTimeEntry(userId, date, i, fields) {
    let task = null;
    if (i != null) {
      ({ task } = this.rows[i]);
    }

    // Set default hours
    if (fields.hours == null) {
      fields.hours = fields.scheduled_hours || 0;
    }

    const timeEntry = {
      user_id: userId,
      assignable_id: this.assignable.id,
      date: this.TKDateUtil.toRubyDate(date),
      task,
      notes:
        i != null
          ? this.rows[i].notesInputModel != null
            ? this.rows[i].notesInputModel.notes
            : undefined
          : undefined,
    };

    _.assign(timeEntry, fields);

    const deferred = this.q.defer();

    if (timeEntry.placeholder || timeEntry.temp) {
      this.addTimeEntry(timeEntry);
      deferred.resolve(timeEntry);
    } else {
      // Preserve row id
      const { rowId } = timeEntry;
      const resolve = (timeEntry) => {
        if (rowId != null) {
          timeEntry.rowId = rowId;
        }
        this.removePlaceholders(date);
        this.addTimeEntry(timeEntry);
        deferred.resolve(timeEntry);
      };
      const errorHandler = this.newErrorHandler('create', timeEntry);
      this.TKData.createTimeEntry(timeEntry).then(resolve, errorHandler);
    }

    return deferred.promise;
  }

  getNotesInputModel(i) {
    // This function only applies to notes in dayview.
    if (!this.itemized) {
      return;
    }
    if (this.notesInputModel) {
      const row = this.rows[i];
      if (!row) {
        throw 'row not found';
      }
      return row.notesInputModel || (row.notesInputModel = {});
    }

    this.notesInputModel = {};
    const dateKeys = _.keys(this.timeEntriesByDate);
    const possiblyInDayView = dateKeys.length === 1;
    if (possiblyInDayView) {
      const dateString = dateKeys[0];
      _.each(this.rows, function (row) {
        row.notesInputModel = {
          notes: __guard__(
            row.timeEntriesByDate[dateString] != null
              ? row.timeEntriesByDate[dateString][0]
              : undefined,
            (x) => x.notes
          ),
        };
      });
    }
    return this.rows[i] != null ? this.rows[i].notesInputModel : undefined;
  }

  getNotes(date, i) {
    if (!this.itemized || !this.rows[i]) {
      return;
    }
    const timeEntriesWithNotes = _.filter(
      this.rows[i].timeEntriesByDate[date.toDateString()],
      (timeEntry) => timeEntry.notes
    );
    if (timeEntriesWithNotes.length === 1) {
      return timeEntriesWithNotes[0].notes;
    }
  }
});
Cls.initClass();

this.TimeTrackerProjectRow = class TimeTrackerProjectRow extends (
  this.TimeTrackerRow
) {
  isProject() {
    return true;
  }
  getProject() {
    return this.assignable;
  }
  getProjectType() {
    return this.assignable.project_state;
  }
};

this.TimeTrackerLeaveRow = class TimeTrackerLeaveRow extends (
  this.TimeTrackerRow
) {
  isLeave() {
    return true;
  }
  getLeaveType() {
    return this.assignable;
  }
};

angular.module('app.services').factory('TKTimeTrackerRowManager', [
  '$log',
  '$q',
  'TKData',
  'TKDateUtil',
  'TKApprovables',
  'TKTimeEntryHelper',
  'TKErrorMessenger',
  function (
    $log,
    $q,
    TKData,
    TKDateUtil,
    TKApprovables,
    TKTimeEntryHelper,
    TKErrorMessenger
  ) {
    const manager = {};

    // A class to manager time tracker rows, representing all of the data used by a time tracker module
    class TimeTrackerRowManager {
      constructor(collapseRows, itemized) {
        this.collapseRows = collapseRows;
        this.itemized = itemized;
        this.rows = [];
      }

      addTimeEntry(timeEntry, assignable, type) {
        let row = _.find(
          this.rows,
          (row) => row.assignable.id === assignable.id
        );

        if (!row) {
          if (type === 'project') {
            row = new TimeTrackerProjectRow(
              TKDateUtil,
              TKData,
              TKTimeEntryHelper,
              TKErrorMessenger,
              $log,
              $q,
              assignable,
              this.collapseRows,
              this.itemized
            );
          } else {
            row = new TimeTrackerLeaveRow(
              TKDateUtil,
              TKData,
              TKTimeEntryHelper,
              TKErrorMessenger,
              $log,
              $q,
              assignable,
              this.collapseRows,
              this.itemized
            );
          }
          this.rows.push(row);
          this.sortRows();
        }

        row.addTimeEntry(timeEntry);
      }

      createRow(assignable, type, expanded) {
        let row = _.find(
          this.rows,
          (row) => row.assignable.id === assignable.id
        );

        if (!row) {
          if (type === 'project') {
            row = new TimeTrackerProjectRow(
              TKDateUtil,
              TKData,
              TKTimeEntryHelper,
              TKErrorMessenger,
              $log,
              $q,
              assignable,
              this.collapseRows,
              this.itemized
            );
          } else {
            row = new TimeTrackerLeaveRow(
              TKDateUtil,
              TKData,
              TKTimeEntryHelper,
              TKErrorMessenger,
              $log,
              $q,
              assignable,
              this.collapseRows,
              this.itemized
            );
          }
          this.rows.push(row);
          this.sortRows();
        }

        row.placeholder = true;
        if (expanded != null) {
          row.expanded = expanded;
        }
        return row;
      }

      deleteRow(row, userId, dates) {
        row.deleteAllTimeEntries();
        row.clearSuggestions(userId, dates);
        // dont remove the row from view, unless the time was scheduled
        if (row.scheduledHoursTotal == null) {
          _.pull(this.rows, row);
        }
      }

      sortRows() {
        this.rows.sort(function (a, b) {
          if (a.sortValue() > b.sortValue()) {
            return 1;
          }
          if (a.sortValue() < b.sortValue()) {
            return -1;
          }
          return 0;
        });
      }

      allTimeEntries(includedPlaceholders, includeSuggestions) {
        const entries = [];

        _.each(this.rows, (row) =>
          _.each(row.timeEntriesByDate, (data, date) =>
            _.each(data.timeEntries, function (timeEntry) {
              if (includeSuggestions || timeEntry.hours != null) {
                if (includedPlaceholders || !timeEntry.placeholder) {
                  return entries.push(timeEntry);
                }
              }
            })
          )
        );

        return entries;
      }

      // Returns the number of suggestion timeEntries found
      // Returns `null` if none are found
      getSuggestionsToClear() {
        let clearingSuggestions, confirmedClearingSuggestion;
        const { isSuggestion, isSuggestionClearing, isConfirmed } =
          TKTimeEntryHelper;
        let suggestions =
          (clearingSuggestions =
          confirmedClearingSuggestion =
            null);
        _.each(this.rows, function (row) {
          _.each(row.timeEntriesByDate, function (timeEntryDate) {
            let hasConfirmed, hasSuggestionClearing;
            if (row.isLockedBecauseProjectLockout(timeEntryDate.date)) {
              return;
            }
            let hasSuggestion = (hasSuggestionClearing = hasConfirmed = null);
            _.each(timeEntryDate.timeEntries, function (timeEntry) {
              if (isSuggestion(timeEntry)) {
                hasSuggestion = true;
              }
              if (isSuggestionClearing(timeEntry)) {
                hasSuggestionClearing = true;
              }
              if (isConfirmed(timeEntry)) {
                hasConfirmed = true;
              }
            });
            if (hasSuggestion) {
              suggestions += 1;
            }
            if (hasSuggestion && hasSuggestionClearing) {
              clearingSuggestions += 1;
            }
            if (hasSuggestion && hasConfirmed) {
              confirmedClearingSuggestion += 1;
            }
          });
        });

        const nothingToClearOrRestore = () =>
          suggestions === confirmedClearingSuggestion;

        const entriesToRestore = () => clearingSuggestions <= suggestions;

        const entriesToClear = function () {
          const clearedSuggestions =
            clearingSuggestions + confirmedClearingSuggestion;
          return suggestions > clearedSuggestions;
        };

        if (nothingToClearOrRestore()) {
          return null;
        }
        if (entriesToClear()) {
          return 1;
        }
        if (entriesToRestore()) {
          return 0;
        }
      }

      clearSuggestions(userId, dates) {
        const deferred = $q.defer();
        const promises = _.invoke(this.rows, 'clearSuggestions', userId, dates);
        $q.all(promises).then(() => deferred.resolve());
        return deferred.promise;
      }

      restoreSuggestions(dates) {
        const deferred = $q.defer();
        const promises = _.invoke(this.rows, 'restoreSuggestions', dates);
        $q.all(promises).then(() => deferred.resolve());
        return deferred.promise;
      }

      calculateVisibility() {
        _.invoke(this.rows, 'calculateVisibility');
      }

      nextVisibleRow(row) {
        let i = this.rows.indexOf(row);

        while (++i < this.rows.length) {
          if (this.rows[i].isVisible()) {
            return this.rows[i];
          }
        }

        return null;
      }

      previousVisibleRow(row) {
        let i = this.rows.indexOf(row);

        while (--i >= 0) {
          if (this.rows[i].isVisible()) {
            return this.rows[i];
          }
        }

        return null;
      }

      approvablesStatus(approvables) {
        approvables = approvables || this.allTimeEntries();
        return TKApprovables.approvablesStatus(approvables);
      }
    }

    manager.createRowManager = (collapseRows, itemized) =>
      new TimeTrackerRowManager(collapseRows, itemized);

    return manager;
  },
]);
