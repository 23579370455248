/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkExpenseItem', [
  '$log',
  '$q',
  '$filter',
  '$parse',
  'TKData',
  'TKDateUtil',
  ($log, $q, $filter, $parse, TKData, TKDateUtil) => ({
    restrict: 'E',
    require: '^tkExpenseTracker',
    replace: true,
    templateUrl: 'ng-approvals/templates/expenseTracker/expenseItem',

    controller: function ($scope) {
      $scope.state = {};

      $scope.state.editing = false;

      $scope.getExpenseStatusMessage = function () {
        let message;
        const lockedByArchived = isLockedBecauseProjectArchived();
        const lockedByApproval = $scope.isLockedBecauseApproved();

        if (lockedByArchived) {
          message = 'This project has been archived. ';
        } else if (lockedByApproval) {
          message = 'This expense item has been approved and locked. ';
        }

        if (message) {
          return (
            message +
            'Please contact your administrator if you need to make changes.'
          );
        } else {
          return null;
        }
      };

      var isLockedBecauseProjectArchived = function () {
        if ($scope.assignablesById) {
          return (
            $scope.assignablesById[$scope.expense.assignable_id] &&
            !!$scope.assignablesById[$scope.expense.assignable_id].deleted_at
          );
        }
      };

      $scope.isLockedButNotApproved = () =>
        isLockedBecauseProjectArchived() && !$scope.isLockedBecauseApproved();

      $scope.isLockedBecauseApproved = function () {
        let approved = false;
        _.each(
          $scope.expense.approvals != null
            ? $scope.expense.approvals.data
            : undefined,
          function (approval) {
            if (approval && approval.status === 'approved') {
              approved = true;
            }
          }
        );
        return approved;
      };

      $scope.isEditable = () => {
        restricted =
          $scope.assignablesById[$scope.expense.assignable_id] &&
          $scope.assignablesById[$scope.expense.assignable_id].restricted;
        return (
          $scope.$parent.canEdit() &&
          !restricted &&
          !($scope.isLockedBecauseApproved() || $scope.isLockedButNotApproved())
        );
      };

      $scope.startEdit = function ($event) {
        if ($scope.isEditable()) {
          $scope.state.editing = true;
        }
      };

      $scope.expenseAction = function ($action, expense) {
        if ($action === 'close') {
          $scope.state.editing = false;
        } else if ($action === 'delete') {
          $scope.state.editing = false;
          $scope.deleteExpense(expense);
        }
      };
    },
  }),
]);
