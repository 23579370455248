/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkMeasureDimensionsInto', [
  '$parse',
  '$timeout',
  ($parse, $timeout) => ({
    restrict: 'A',

    link(scope, el, attrs) {
      let assignDimensions;
      const model = $parse(attrs.tkMeasureDimensionsInto);

      const measureEvents =
        attrs.measureDimensionsOn != null
          ? attrs.measureDimensionsOn.split(' ')
          : undefined;
      const getDimensions = function () {
        const { top, left } = el.offset();
        const height = el.outerHeight();
        const width = el.outerWidth();
        return {
          top,
          left,
          height,
          width,
        };
      };

      (assignDimensions = function () {
        // TODO Consider _not_ using a timeout
        $timeout(function () {
          model.assign(scope, getDimensions());
        });
      })();

      if (measureEvents) {
        for (var measureEvent of Array.from(measureEvents)) {
          scope.$on(measureEvent, assignDimensions);
        }
      }
    },
  }),
]);
