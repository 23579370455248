/*
 * decaffeinate suggestions:
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app.services').factory('TKTimesheetFactory', [
  '$log',
  '$q',
  'TKDateUtil',
  'TKData',
  'TKAssignables',
  'TKUsers',
  function ($log, $q, TKDateUtil, TKData, TKAssignables, TKUsers) {
    class Timesheet {
      constructor(startDate, user) {
        this.startDate = startDate;
        this.user = user;
        this.endDate = TKDateUtil.addDays(this.startDate, 7 - 1);
        this.approvables = [];
        this.startTime = this.startDate.getTime();
        this.endTime = this.endDate.getTime();
      }

      add(timeEntry) {
        if (!_.contains(this.approvables, timeEntry)) {
          this.approvables.push(timeEntry);
        }
      }

      type() {
        return 'timesheet';
      }

      overlapsDateRange(start, end) {
        if (start && end) {
          if (
            end.getTime() <= this.startTime ||
            start.getTime() >= this.endTime
          ) {
            return false;
          }
        }
        return true;
      }
    }

    class ExpenseSheet {
      constructor(user) {
        this.user = user;
        this.approvables = [];
      }

      add(expense) {
        if (!_.contains(this.approvables, expense)) {
          this.approvables.push(expense);
        }
      }

      type() {
        return 'expensesheet';
      }
    }

    const factory = {};

    factory.filterApprovables = function (approvables, filterSet) {
      const approvalFilter =
        __guard__(filterSet.getFilter('approvalState'), (x) => x.value) ||
        'Unsubmitted';
      approvables = (() => {
        switch (approvalFilter) {
          case 'Pending Approval':
            return _.filter(
              approvables,
              (timeEntry) =>
                (timeEntry.approvals.data[0] != null
                  ? timeEntry.approvals.data[0].status
                  : undefined) === 'pending'
            );
          case 'Approved':
            return _.filter(
              approvables,
              (timeEntry) =>
                (timeEntry.approvals.data[0] != null
                  ? timeEntry.approvals.data[0].status
                  : undefined) === 'approved'
            );
          case 'Unsubmitted':
            return (
              _.filter(
                approvables,
                (timeEntry) => !timeEntry.approvals.data[0]
              ) ||
              (timeEntry.approvals.data[0] != null
                ? timeEntry.approvals.data[0].status
                : undefined) === 'unapproved'
            );
          case 'Rejected':
            return _.filter(
              approvables,
              (timeEntry) =>
                (timeEntry.approvals.data[0] != null
                  ? timeEntry.approvals.data[0].status
                  : undefined) === 'rejected'
            );
        }
      })();

      if (
        __guard__(filterSet.getFilter('discipline'), (x1) => x1.value) != null
      ) {
        approvables = _.filter(approvables, (approvable) =>
          _.some(filterSet.getFilter('discipline').value, {
            user_ids: [approvable.user_id],
          })
        );
      }

      if (__guard__(filterSet.getFilter('project'), (x2) => x2.value) != null) {
        approvables = _.filter(approvables, function (approvable) {
          if (approvable.assignable_type === 'Project') {
            return _.some(filterSet.getFilter('project').value, {
              id: approvable.assignable_id,
            });
          }
          return true;
        });
      }

      if (
        __guard__(filterSet.getFilter('projectTag'), (x3) => x3.value) != null
      ) {
        const projectsWithTags = _.reduce(
          filterSet.getFilter('projectTag').value,
          (projectsWithTags, tag) =>
            projectsWithTags.concat(TKAssignables.projectsByTagId[tag.id]),
          []
        );
        approvables = _.filter(approvables, function (approvable) {
          if (approvable.assignable_type === 'Project') {
            return _.some(projectsWithTags, { id: approvable.assignable_id });
          }
          return true;
        });
      }

      if (__guard__(filterSet.getFilter('client'), (x4) => x4.value) != null) {
        approvables = _.filter(approvables, function (approvable) {
          if (approvable.assignable_type === 'Project') {
            return _.some(filterSet.getFilter('client').value, {
              project_ids: [approvable.assignable_id],
            });
          }
          return true;
        });
      }

      if (
        __guard__(filterSet.getFilter('phaseName'), (x5) => x5.value) != null
      ) {
        approvables = _.filter(approvables, function (approvable) {
          if (approvable.assignable_type === 'Project') {
            return _.some(filterSet.getFilter('phaseName').value, {
              project_ids: [approvable.assignable_id],
            });
          }
          return true;
        });
      }

      if (
        __guard__(filterSet.getFilter('leaveType'), (x6) => x6.value) != null
      ) {
        approvables = _.filter(approvables, function (approvable) {
          if (approvable.assignable_type === 'LeaveType') {
            return _.some(filterSet.getFilter('leaveType').value, {
              id: approvable.assignable_id,
            });
          }
          return true;
        });
      }

      if (
        __guard__(filterSet.getFilter('teamMember'), (x7) => x7.value) != null
      ) {
        approvables = _.filter(approvables, (approvable) =>
          _.some(filterSet.getFilter('teamMember').value, {
            id: approvable.user_id,
          })
        );
      }

      if (
        __guard__(filterSet.getFilter('peopleTag'), (x8) => x8.value) != null
      ) {
        const usersWithTags = _.reduce(
          filterSet.getFilter('peopleTag').value,
          (usersWithTags, tag) =>
            usersWithTags.concat(TKUsers.usersByTagId[tag.id]),
          []
        );
        approvables = _.filter(approvables, (approvable) =>
          _.some(usersWithTags, { id: approvable.user_id })
        );
      }

      return approvables;
    };

    factory.createExpensesheets = function (originalExpenses, filterSet) {
      const expensesheetsByUser = {};
      const expensesheets = [];
      let originalExpenseCount = originalExpenses.length;

      expensesheets.refresh = function (forceRefresh) {
        if (originalExpenses.length === originalExpenseCount && !forceRefresh) {
          return;
        }
        originalExpenseCount = originalExpenses.length;

        // NOTE get current filter setting, if object, we are using custom date range
        const dates = filterSet.getFilter('dateRange').value;
        const usingCustomDateRange = typeof dates === 'object';

        const expenses = factory.filterApprovables(originalExpenses, filterSet);
        _.each(expenses, function (expense) {
          // NOTE if we are using custom date range, restrict expenses
          if (usingCustomDateRange) {
            const date = TKDateUtil.parseRubyDate(expense.date);
            if (!(date >= dates.start) || !(date <= dates.end)) {
              return;
            }
          }

          const user = TKData.getUser(expense.user_id).$object;

          let expensesheet = expensesheetsByUser[expense.user_id];

          if (!expensesheet) {
            expensesheet = new ExpenseSheet(user);
            expensesheetsByUser[expense.user_id] = expensesheet;
            expensesheets.push(expensesheet);
          }

          expensesheet.add(expense);
        });
      };

      expensesheets.refresh(true);

      return expensesheets;
    };

    factory.createTimesheets = function (
      originalTimeEntries,
      filterSet,
      orgSettings
    ) {
      const timesheetsByKey = {};
      const timesheets = [];
      let originalTimeEntryCount = originalTimeEntries.length;

      timesheets.refresh = function (forceRefresh) {
        if (
          originalTimeEntries.length === originalTimeEntryCount &&
          !forceRefresh
        ) {
          return;
        }
        originalTimeEntryCount = originalTimeEntries.length;

        const timeEntries = factory.filterApprovables(
          originalTimeEntries,
          filterSet
        );

        _.each(timeEntries, function (timeEntry) {
          // timeEntry.jsDate = TKDateUtil.parseRubyDate(timeEntry.date)
          // date = TKDateUtil.toStartOfWeek timeEntry.jsDate, orgSettings.firstWorkingDay
          // TODO:
          timeEntry.jsDate = TKDateUtil.parseRubyDate(timeEntry.date);
          const timesheetDate = TKDateUtil.toStartOfWeek(
            timeEntry.jsDate,
            orgSettings.firstWorkingDay
          );
          // groupId = if groupByUser then timeEntry.user_id else timeEntry.assignable_id
          const user = TKData.getUser(timeEntry.user_id).$object;
          const key = `${timesheetDate.getTime()}:${timeEntry.user_id}`;

          let timesheet = timesheetsByKey[key];

          if (!timesheet) {
            timesheet = new Timesheet(timesheetDate, user);
            timesheetsByKey[key] = timesheet;
            timesheets.push(timesheet);
          }

          timesheet.add(timeEntry);
        });
      };

      timesheets.refresh(true);

      return timesheets;
    };

    return factory;
  },
]);
