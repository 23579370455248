/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app.services').factory('TKAssignables', [
  '$q',
  '$rootScope',
  'TKData',
  'TKDateUtil',
  function ($q, $rootScope, TKData, TKDateUtil) {
    const TKAssignables = {};

    const toLowerCase = (string) =>
      (string != null ? string.toLowerCase() : undefined) || '';

    const getSortAttr = function (assignable) {
      const { client, name, phase_name } = assignable;
      return [client, name, phase_name].map(toLowerCase).join('');
    };

    const sortProjects = function () {
      for (var collection of [
        'allProjects',
        'projects',
        'phases',
        'leaveTypes',
      ]) {
        TKAssignables[collection].sort(
          (a, b) => getSortAttr(a) > getSortAttr(b)
        );
      }
    };

    const sortRecentProjects = function () {
      TKAssignables.recentProjects.sort(
        (a, b) => getSortAttr(a) > getSortAttr(b)
      );
    };

    TKAssignables.allProjectsHaveBeenFetched = false;
    TKAssignables.allLeaveTypesHaveBeenFetched = false;
    TKAssignables.allAssignablesHaveBeenFetched = false;

    TKAssignables.allAssignables = []; // does not include archived
    TKAssignables.allAssignablesById = {}; // includes archived projects (but not archived leave types)
    TKAssignables.leaveTypesById = {};
    TKAssignables.leaveTypes = [];
    TKAssignables.allProjects = [];
    TKAssignables.projectsById = {}; // includes archived
    TKAssignables.phasesByParentId = {};
    TKAssignables.projectsByTagId = {};
    TKAssignables.tagsById = {};
    TKAssignables.projects = []; // does not include archived
    TKAssignables.phases = []; // does not include archived
    TKAssignables.tags = [];
    const deferredTKAssignablesPromise = $q.defer();

    const processMoreProjects = function (projects) {
      _.each(projects, function (project) {
        const alreadyProcessed = TKAssignables.projectsById[project.id];
        if (!alreadyProcessed) {
          TKAssignables.allAssignablesById[project.id] = project;
          TKAssignables.projectsById[project.id] = project;
          // The reason we are not adding archived projects to
          // these collections is so that archived projects do not
          // show up in the project picker. This is a sub-optimal
          // solution since it is somewhat confusing to have archived
          // projects contained in the 'byId' collections but not
          // the arrays.
          if (project.deleted_at != null) {
            return;
          }
          if (project.parent_id) {
            TKAssignables.phases.push(project);
            (TKAssignables.phasesByParentId[project.parent_id] =
              TKAssignables.phasesByParentId[project.parent_id] || []).push(
              project
            );
          } else {
            TKAssignables.projects.push(project);
            if (
              __guard__(
                project.tags != null ? project.tags.data : undefined,
                (x) => x.length
              )
            ) {
              _.each(project.tags.data, function (tag) {
                if (!_.find(TKAssignables.tags, { id: tag.id })) {
                  TKAssignables.tags.push(tag);
                }
                TKAssignables.tagsById[tag.id] = tag;
                (TKAssignables.projectsByTagId[tag.id] = []).push(project);
              });
            }
          }
        }
      });
      sortProjects();
      deferredTKAssignablesPromise.notify([
        TKAssignables.leaveTypes,
        TKAssignables.allProjects,
      ]);
      return projects;
    };

    const processMoreAssignables = function (assignables) {
      _.each(assignables, function (assignable) {
        const alreadyProcessed =
          TKAssignables.projectsById[assignable.id] ||
          TKAssignables.leaveTypesById[assignable.id];
        if (!alreadyProcessed) {
          if (assignable.type === 'LeaveType') {
            TKAssignables.allAssignablesById[assignable.id] = assignable;
            TKAssignables.leaveTypesById[assignable.id] = assignable;
            TKAssignables.leaveTypes.push(assignable);
          } else {
            TKAssignables.allAssignablesById[assignable.id] = assignable;
            TKAssignables.projectsById[assignable.id] = assignable;
            if (assignable.parent_id) {
              TKAssignables.phases.push(assignable);
              (TKAssignables.phasesByParentId[assignable.parent_id] = []).push(
                assignable
              );
            } else {
              TKAssignables.projects.push(assignable);
              if (
                __guard__(
                  assignable.tags != null ? assignable.tags.data : undefined,
                  (x) => x.length
                )
              ) {
                _.each(assignable.tags.data, function (tag) {
                  if (!_.find(TKAssignables.tags, { id: tag.id })) {
                    TKAssignables.tags.push(tag);
                  }
                  TKAssignables.tagsById[tag.id] = tag;
                  (TKAssignables.projectsByTagId[tag.id] = []).push(assignable);
                });
              }
            }
          }
        }
      });
      sortProjects();
      return assignables;
    };

    TKAssignables.getTheseProjectsNow = function (projectIds) {
      const promises = projectIds.map(function (projectId) {
        if (TKAssignables.projectsById[projectId]) {
          return $q.when(TKAssignables.projectsById[projectId]);
        } else {
          return TKData.projects.get(projectId).then(
            function (result) {
              return result;
            },
            function (err) {
              if (err.status == 404 && window.whoami.user_type_id == 5) {
                return {
                  id: projectId,
                  restricted: true,
                  name: 'Restricted Project',
                };
              }
            }
          );
        }
      });

      return $q.all(promises).then(processMoreProjects);
    };

    const processLeaveTypes = function (leaveTypes) {
      _.each(leaveTypes, function (leaveType) {
        TKAssignables.allAssignablesById[leaveType.id] = leaveType;
        TKAssignables.leaveTypesById[leaveType.id] = leaveType;
      });
      TKAssignables.allLeaveTypesHaveBeenFetched = true;
      deferredTKAssignablesPromise.notify([
        TKAssignables.leaveTypes,
        TKAssignables.allProjects,
      ]);
      return leaveTypes;
    };

    TKAssignables.getTheseLeaveTypesNow = function (leaveTypeIds) {
      const promises = leaveTypeIds.map(function (leaveTypeId) {
        if (TKAssignables.leaveTypesById[leaveTypeId]) {
          return $q.when(TKAssignables.leaveTypesById[leaveTypeId]);
        } else {
          return TKData.leaveTypes.get(leaveTypeId);
        }
      });

      return $q.all(promises).then(processLeaveTypes);
    };

    TKAssignables.getProject = function (projectId) {
      // This method is useful if we need the assignable $object from TKData
      let promise;
      if (TKAssignables.projectsById[projectId]) {
        promise = $q.when(TKAssignables.projectsById[projectId]);
        promise.$object = TKAssignables.projectsById[projectId];
      } else {
        promise = TKData.projects.get(projectId);
        const { $object } = promise;
        promise = promise.then(function (project) {
          processMoreProjects([project]);
          return project;
        });
        promise.$object = $object;
      }
      return promise;
    };

    TKAssignables.getLeaveType = function (leaveTypeId) {
      // This method is useful if we need the assignable $object from TKData
      let promise;
      if (TKAssignables.leaveTypesById[leaveTypeId]) {
        promise = $q.when(TKAssignables.leaveTypesById[leaveTypeId]);
        promise.$object = TKAssignables.leaveTypesById[leaveTypeId];
      } else {
        promise = TKData.leaveTypes.get(leaveTypeId);
        const { $object } = promise;
        promise = promise.then(function (leaveType) {
          processLeaveTypes([leaveType]);
          return leaveType;
        });
        promise.$object = $object;
      }
      return promise;
    };

    TKAssignables.getAssignableSearchResults = function (input) {
      const searchResultPromise = TKData.getAssignableSearchResults(input);
      return searchResultPromise.then((results) =>
        processMoreAssignables(results.data)
      );
    };

    TKAssignables.promise = function () {
      let leaveTypesPromise = TKData.getLeaveTypes();
      TKAssignables.leaveTypes = leaveTypesPromise.$object;

      leaveTypesPromise = leaveTypesPromise.then(processLeaveTypes);

      let realProjectsPromise = TKData.getRealProjects();
      // allProjects includes phases
      TKAssignables.allProjects = realProjectsPromise.$object; // does not include archived

      realProjectsPromise = realProjectsPromise
        .then(processMoreProjects, null, processMoreProjects)
        .then(function (projects) {
          TKAssignables.allProjectsHaveBeenFetched = true;
          return projects;
        });

      const processOrphanedPhases = function () {
        // contractors who are assigned to a phase but not the parent project do not have permission to get
        // the parent project from the API, so this allows us to include them in the project picker anyway.
        TKAssignables.orphanedPhases = _.filter(
          TKAssignables.phases,
          ({ parent_id }) => !TKAssignables.projectsById[parent_id]
        );
        _.each(TKAssignables.orphanedPhases, function (phase) {
          if (TKAssignables.projectsById[phase.parent_id]) {
            return;
          }
          const proxy = {
            name: phase.name,
            id: phase.parent_id,
            project_state: phase.project_state,
            is_proxy: true,
            can_i: phase.can_i || {},
          };
          TKAssignables.projectsById[phase.parent_id] = proxy;
          TKAssignables.projects.push(proxy);
        });
      };

      const addPhasesToProjectsByTagId = function () {
        _.each(TKAssignables.tags, function (tag) {
          const phases = _.reduce(
            TKAssignables.projectsByTagId[tag.id],
            (phases, project) =>
              phases.concat(TKAssignables.phasesByParentId[project.id] || []),
            []
          );
          TKAssignables.projectsByTagId[tag.id].push(
            ...Array.from(phases || [])
          );
        });
      };

      $q.all([leaveTypesPromise, realProjectsPromise]).then(function () {
        if (!TKAssignables.allAssignablesHaveBeenFetched) {
          sortProjects();
          TKAssignables.allAssignables.push(
            ...Array.from(TKAssignables.allProjects || [])
          );
          TKAssignables.allAssignables.push(
            ...Array.from(TKAssignables.leaveTypes || [])
          );
          processOrphanedPhases();
          addPhasesToProjectsByTagId();
          TKAssignables.allAssignablesHaveBeenFetched = true;
        }
        deferredTKAssignablesPromise.resolve([
          TKAssignables.leaveTypes,
          TKAssignables.allProjects,
        ]);
      });

      return deferredTKAssignablesPromise.promise;
    };

    TKAssignables.getRecentProjects = function (userId) {
      if (!TKAssignables.recentProjectsPromise) {
        const deferredRecentProjectsPromise = $q.defer();
        TKAssignables.recentProjectsPromise =
          deferredRecentProjectsPromise.promise;

        const end = new Date();
        const start = TKDateUtil.addDays(end, -30);

        TKData.getTimeEntries(userId, start, end, true, false).then(function (
          timeEntries
        ) {
          let projectIds = _.tk.pluckUnique(timeEntries, 'assignable_id');
          const getParentIdOrId = (id) =>
            (TKAssignables.allAssignablesById[id] != null
              ? TKAssignables.allAssignablesById[id].parent_id
              : undefined) || id;
          // This takes too long when there are many projects
          // TODO take advantage of notifications from `projectsPromise`
          TKAssignables.promise().then(function () {
            const projectsAndLeaveTypes = TKAssignables.projects.concat(
              TKAssignables.leaveTypes
            );
            projectIds = projectIds.map(getParentIdOrId);
            const isRecent = (assignable) =>
              _.contains(projectIds, assignable.id);

            TKAssignables.recentProjects = _.filter(
              projectsAndLeaveTypes,
              isRecent
            );
            sortRecentProjects();
            deferredRecentProjectsPromise.resolve(TKAssignables.recentProjects);
          });
        });
      }

      return TKAssignables.recentProjectsPromise;
    };

    $rootScope.$on('timeEntryCreated', function ($event, timeEntry) {
      const { recentProjects } = TKAssignables;
      if (recentProjects) {
        let assignable =
          TKAssignables.allAssignablesById[timeEntry.assignable_id];
        if (assignable.parent_id) {
          assignable = TKAssignables.projectsById[assignable.parent_id];
        }
        const alreadyPresent = _.contains(recentProjects, assignable);
        if (!alreadyPresent) {
          const i = _.sortedIndex(recentProjects, assignable, getSortAttr);
          recentProjects.splice(i, 0, assignable);
        }
      }
    });

    return TKAssignables;
  },
]);

/**
 * This is used across the approvals files...
 */
function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null
    ? transform(value)
    : undefined;
}
