/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

// This will at some point either support day and week views, or be split into two controllers for those two cases
angular.module('app').directive('tkTimeTrackerRow', [
  '$rootScope',
  '$log',
  '$q',
  '$http',
  '$timeout',
  '$window',
  'TKData',
  'TKOrgSettings',
  'TKDateUtil',
  'TKPopoverManager',
  'TKTimeTrackerTimerManager',
  'TKCleanup',
  'TKTimeEntryHelper',
  'TKAnalytics',
  'TKPlaceholderRowManager',
  (
    $rootScope,
    $log,
    $q,
    $http,
    $timeout,
    $window,
    TKData,
    TKOrgSettings,
    TKDateUtil,
    TKPopoverManager,
    TKTimeTrackerTimerManager,
    TKCleanup,
    TKTimeEntryHelper,
    TKAnalytics,
    TKPlaceholderRowManager
  ) => ({
    restrict: 'E',
    templateUrl: 'ng-approvals/templates/timeTrackerRow',
    replace: false,
    require: '^tkTimeTracker',

    controller: function ($scope) {
      // For storing timer event listener de-register'er
      let onStopTimerHandle = undefined;

      $scope.I18n = I18n;

      if ($scope.timeTrackerState.expandAll) {
        $scope.timeTrackerState.expansionsByAssignableId[
          $scope.data.assignable.id
        ] = true;
        $scope.data.expanded = true;
      } else if (
        $scope.timeTrackerState.expansionsByAssignableId[
          $scope.data.assignable.id
        ]
      ) {
        $scope.data.expanded = true;
      }

      $scope.rowState = {
        timers: {},
      };

      const barValues = [0, 0, 0, 0];
      $scope.getBarValues = function () {
        const data = $scope.data.rowSummary();
        _.each(data.barValues, (value, i) => (barValues[i] = value));
        return barValues;
        barValues[0] = data.incurred;
        const diff = data.incurred - data.expectedIncurred;
        if (diff > 0) {
          barValues[1] = 0;
          barValues[2] = diff;
        } else {
          barValues[1] = -diff;
          barValues[2] = 0;
        }
        barValues[3] = data.futureScheduled;
        return barValues;
      };

      $scope.barColors = ['#24A2EB', '#24A2EB', '#005B82', '#84D5F7'];
      $scope.barInnerColors = [null, '#fff'];

      const formatLegendValue = function (val) {
        if (val % 1 !== 0) {
          return Math.abs(val.toFixed(2));
        } else {
          return val;
        }
      };

      $scope.getLegendValues = function () {
        const data = $scope.data.rowSummary();

        const legendValues = {
          scheduled: `${formatLegendValue(data.scheduled)} hours scheduled`,
          remaining: `${formatLegendValue(
            data.futureScheduled
          )} hours remaining`,
          entered: `${formatLegendValue(data.confirmed)} hours entered`,
        };

        // TODO name this better
        let confirmed = data.expectedIncurred - data.incurred;
        if (confirmed % 1 !== 0) {
          confirmed = confirmed.toFixed(2);
        }
        if (confirmed < 0) {
          legendValues.confirmed = `${Math.abs(
            confirmed
          )} hours more than scheduled`;
          legendValues.confirmedClass = 'more';
        } else if (confirmed === 0) {
          legendValues.confirmed = 0; // TODO get messaging?
        } else {
          legendValues.confirmed = `${Math.abs(
            confirmed
          )} hours less than scheduled`;
          legendValues.confirmedClass = 'less';
        }

        return legendValues;
      };

      let showLegendTooltipTimeout = undefined;
      $scope.isShowingLegendTooltip = false;

      $scope.showLegendTooltip = function () {
        showLegendTooltipTimeout = $timeout(
          () => ($scope.isShowingLegendTooltip = true),
          500
        );
      };

      $scope.hideLegendTooltip = function () {
        $timeout.cancel(showLegendTooltipTimeout);
        $scope.isShowingLegendTooltip = false;
      };

      $scope.approvalFlagIncludeAll = function () {
        let all = true;
        _.each($scope.approvalData.approvables, function (timeEntry) {
          if (
            timeEntry.assignable_id === $scope.data.assignable.id &&
            !$scope.approvalData.submitIds[timeEntry.id]
          ) {
            all = false;
          }
        });

        return all;
      };

      $scope.approvalFlagChanged = function (checked) {
        _.each($scope.approvalData.approvables, function (timeEntry) {
          if (timeEntry.assignable_id === $scope.data.assignable.id) {
            $scope.approvalData.submitIds[timeEntry.id] = checked;
          }
        });
      };

      const cleanup = TKCleanup.newCleanup($scope);
      // $scope.$watch "data.approve", (newValue) ->
      //   $scope.$emit 'tk-assignable-check-state', $scope.data.assignable.id, newValue

      cleanup(
        $scope.$on('tk-expand-all', function (event, expanded) {
          $scope.timeTrackerState.expansionsByAssignableId[
            $scope.data.assignable.id
          ] = expanded;
          $scope.data.expanded = expanded;
        })
      );

      $scope.toggleExpand = function () {
        if (
          $scope.timeEntryMode !== 'itemized' ||
          $scope.modeTimesheetApproval ||
          !$scope.canEditUser() ||
          $scope.data.assignable.restricted
        ) {
          return;
        }
        const expanded = !$scope.data.expanded;
        $scope.timeTrackerState.expansionsByAssignableId[
          $scope.data.assignable.id
        ] = expanded;
        $scope.data.expanded = expanded;
      };

      $scope.visibleRows = function () {
        if ($scope.data.expanded) {
          return $scope.data.rows;
        } else {
          let rows = [];
          _.each($scope.rowState.timers, function (timer, index) {
            if (timer && timer.timerActive) {
              ({ rows } = $scope.data);
              $scope.toggleExpand();
            }
          });
          return rows;
        }
      };

      TKOrgSettings.promise.then(
        (orgSettings) => ($scope.orgSettings = orgSettings)
      );

      $scope.isShowingDayviewPopup = (date, i = null) =>
        $scope.timeEntryMode === 'itemized' &&
        ($scope.timeTrackerState.dayviewPopupState != null
          ? $scope.timeTrackerState.dayviewPopupState.date.getTime()
          : undefined) === date.getTime() &&
        ($scope.timeTrackerState.dayviewPopupState != null
          ? $scope.timeTrackerState.dayviewPopupState.i
          : undefined) === i &&
        ($scope.timeTrackerState.dayviewPopupState != null
          ? $scope.timeTrackerState.dayviewPopupState.assignable
          : undefined) === $scope.data.assignable;

      $scope.isShowingCategoryPopup = (date) =>
        $scope.timeEntryMode === 'itemized' &&
        ($scope.timeTrackerState.categoryPickerState != null
          ? $scope.timeTrackerState.categoryPickerState.date.getTime()
          : undefined) === date.getTime() &&
        ($scope.timeTrackerState.categoryPickerState != null
          ? $scope.timeTrackerState.categoryPickerState.assignable
          : undefined) === $scope.data.assignable;

      $scope.isShowingExceedsMaximumPopup = (date, i = null) =>
        ($scope.timeTrackerState.exceedsMaximumPopupState != null
          ? $scope.timeTrackerState.exceedsMaximumPopupState.date.getTime()
          : undefined) === date.getTime() &&
        (($scope.timeTrackerState.exceedsMaximumPopupState != null
          ? $scope.timeTrackerState.exceedsMaximumPopupState.i
          : undefined) === i ||
          $scope.timeEntryMode === 'halfDays') &&
        ($scope.timeTrackerState.exceedsMaximumPopupState != null
          ? $scope.timeTrackerState.exceedsMaximumPopupState.assignable
          : undefined) === $scope.data.assignable;

      $scope.isInHourMinuteEditMode = function (date, i = null) {
        const { editState } = $scope.timeTrackerState;
        return (
          editState &&
          $scope.timeEntryMode !== 'halfDays' &&
          editState.date.getTime() === date.getTime() &&
          editState.i === i &&
          editState.assignable === $scope.data.assignable
        );
      };

      $scope.isInHalfDayEditMode = function (date) {
        const { editState } = $scope.timeTrackerState;
        return (
          editState &&
          editState.date.getTime() === date.getTime() &&
          editState.assignable === $scope.data.assignable
        );
      };

      $scope.isInEditMode = (date, i = null) =>
        $scope.isInHourMinuteEditMode(date, i) ||
        $scope.isInHalfDayEditMode(date, i);

      $scope.isEditingItemizedRow = function (date) {
        const { editState } = $scope.timeTrackerState;
        return (
          editState &&
          editState.i != null &&
          editState.date.getTime() === date.getTime() &&
          editState.assignable === $scope.data.assignable &&
          $scope.timeEntryMode === 'itemized'
        );
      };

      $scope.isShowingNotesOnThisRow = function (row, $index) {
        const { editState, readOnlyState } = $scope.timeTrackerState;
        return _.some(
          [editState, readOnlyState],
          (state) => state && state.row === row && state.i === $index
        );
      };

      $scope.blankCategoryTimeIsAllowed = (orgSettings, data) =>
        orgSettings.allowBulkConfirmTimeEntries || data.isLeave();

      $scope.addRow = function (date, category) {
        $scope.timeTrackerState.categoryPickerState = null;

        // If row has a blank space for the chosen category, use it
        let i = $scope.data.lastEmptyRowByTaskForDate(category.category, date);

        if (i < 0) {
          $scope.data.createTimeEntry($scope.userId, date, null, {
            placeholder: true,
            task: category.category || null,
          });
          i = $scope.data.lastEmptyRowByTaskForDate(category.category, date);
        }

        return $timeout(() => $scope.startEdit($scope.data, date, i));
      };

      $scope.dayviewPopupCancel = function () {
        delete $scope.timeTrackerState.dayviewPopupVisible;
      };

      $scope.deleteRowCancel = function () {
        delete $scope.rowState.deleteRowConfirmationVisible;
      };

      const removeFromPlaceholderRows = function (assignableId, task) {
        TKPlaceholderRowManager.deleteRow($scope.dates[0], assignableId, task);
      };

      $scope.deleteRow = function (i = null, userConfirmed, task) {
        if (TKPopoverManager.showingPopover() && !userConfirmed) {
          return;
        }
        const confirmed = true;
        if (!userConfirmed) {
          var focusInterval = setInterval(function () {
            const deleteButtonPopupElem =
              document.querySelectorAll('#deleteButtonPopup');
            if (deleteButtonPopupElem.length > 0) {
              deleteButtonPopupElem[0].focus();
              clearInterval(focusInterval);
            }
          }, 100);

          setTimeout(function () {
            clearInterval(focusInterval);
          }, 1000);

          $scope.rowState.deleteRowConfirmationVisible = true;
          $scope.rowState.deleteRowConfirmationIndex = i;
          return;
        }

        delete $scope.rowState.deleteRowConfirmationVisible;

        // Delete immediately
        // TODO kill timers for _any_ time entries being deleted..
        if (i != null) {
          $scope.cancelTimer(i); // cancel any related timers if they're running.
          $scope.data.deleteRow(i, $scope.userId, $scope.dates);
        } else {
          $scope.$parent.deleteRow($scope.data);
        }

        removeFromPlaceholderRows($scope.data.assignable.id, task);

        $scope.$emit('tk-suggestions-refresh');
      };

      $scope.endEditNotes = function (date, i, notes, row) {
        if (row.isEditingRow) {
          row.isEditingRowTimeout = $timeout(function () {
            $scope.saveNotes(date, i, notes);
            window.clientEventLogger &&
              window.clientEventLogger.push({
                eventTimestamp: Date.now(),
                eventData: {
                  eventType: 'Inserted',
                  featureArea: 'RM Time Tracker',
                  description:
                    'A user has entered a time entry note in the day view',
                  objectName: 'Time entry note',
                  objectType: 'Input',
                  viewName: 'Time tracker day view',
                },
              });
          }, 250);
        }
        // reset notes edit state
        $scope.timeTrackerState.notesEditState = null;
      };

      // called on blur and from tab, notesEditKeyBinding
      $scope.saveNotes = function (date, i, notes) {
        const deferred = $q.defer();
        ($scope.data.isSavingNotesPromise =
          $scope.data.isSavingNotesPromise || $q.when()).then(function () {
          const row = $scope.data.rows[i];
          const timeEntry = __guard__(
            row.timeEntriesByDate[date.toDateString()],
            (x) => x[0]
          );

          const timeEntryIsConfirmed =
            timeEntry && TKTimeEntryHelper.isConfirmed(timeEntry);
          const notesHaveChanged =
            timeEntryIsConfirmed && timeEntry.notes !== notes;

          if (timeEntryIsConfirmed && notesHaveChanged) {
            $scope.data.setTimeEntry(date, i, { notes }).then(function () {
              deferred.resolve();
            });
          } else if (timeEntryIsConfirmed && !notesHaveChanged) {
            deferred.resolve();
          } else if (notes) {
            $scope.data.isSavingNotesPromise = $scope.data
              .createTimeEntry($scope.userId, date, i, { notes })
              .then(function () {
                deferred.resolve();
              });
          } else {
            deferred.resolve();
          }
        });

        return deferred.promise;
      };

      $scope.getProjectCategories = () =>
        $scope.$parent.getProjectCategories($scope.data.assignable);

      $scope.cellClass = function (date, i = null) {
        let cellClass;
        const [timeEntry, confirmed, scheduled] = Array.from(
          $scope.data.cellData(date, i)
        );

        if (i != null) {
          cellClass = 'empty';
          if (!$scope.editable($scope.data, date, i)) {
            cellClass += ' read-only';
            if ($scope.modeTimeEntry && !$scope.data.isLocked(date, i)) {
              cellClass += ' interactive';
            }
          }
          return cellClass;
        }

        const editingItemized = $scope.isEditingItemizedRow(date);
        let showSuggestionForItemized = false;
        if (editingItemized) {
          const summary = $scope.data.summaryData(date);
          showSuggestionForItemized =
            summary.confirmedZeroCount === 1 && scheduled;
        }

        cellClass = '';

        if (
          confirmed ||
          (confirmed == null && scheduled) ||
          showSuggestionForItemized
        ) {
          if ($scope.isInHourMinuteEditMode(date, i)) {
            return 'editing ';
          }

          if (confirmed > 0) {
            cellClass += 'confirmed ';
          }

          cellClass += 'has-gradient ';
          if ($scope.data.isLeave()) {
            cellClass += 'grad-orange ';
          } else if ($scope.data.getProjectType() === I18n.t('lbl_internal')) {
            cellClass += 'grad-purple ';
          } else if ($scope.data.getProjectType() === I18n.t('lbl_tentative')) {
            cellClass += 'grad-gray ';
          } else {
            cellClass += 'grad-blue ';
          }

          cellClass;
        } else {
          cellClass = 'empty ';
        }

        if ($scope.timeEntryMode === 'itemized') {
          cellClass += 'read-only ';
          if ($scope.modeTimeEntry && !$scope.data.isLocked(date, i)) {
            cellClass += ' interactive';
          }
        }

        return cellClass;
      };

      $scope.confirmedHoursForAssignable = function (date) {
        const [timeEntry, confirmed, scheduled] = Array.from(
          $scope.data.cellData(date)
        );
        return confirmed;
      };

      $scope.cellText = function (date, i = null) {
        if (i != null) {
          const row = $scope.data.rows[i];
          const timeEntries = row.timeEntriesByDate[date.toDateString()];

          if (!(timeEntries != null ? timeEntries.length : undefined)) {
            return '';
          }

          let hours = 0;
          let hasZeroWithNotes = false;

          _.each(timeEntries, function (timeEntry) {
            if (timeEntry.hours) {
              hours += timeEntry.hours;
            } else if (timeEntry.notes) {
              hasZeroWithNotes = true;
            }
          });

          return hours || (hasZeroWithNotes ? 0 : '');
        } else {
          const [timeEntry, confirmed, scheduled] = Array.from(
            $scope.data.cellData(date, i)
          );
          // if $scope.timeEntryMode == "itemized"
          //   return confirmed || scheduled || ""
          if (confirmed === 0) {
            const editingItemized = $scope.isEditingItemizedRow(date);
            let showSuggestionForItemized = false;
            if (editingItemized) {
              const summary = $scope.data.summaryData(date);
              showSuggestionForItemized =
                summary.confirmedZeroCount === 1 && scheduled;
            }

            if (showSuggestionForItemized) {
              return scheduled;
            }
            return '';
          } else {
            return confirmed || scheduled || '';
          }
        }
      };

      $scope.getWeekdayIndex = function (row, $index) {
        const { editState, readOnlyState } = $scope.timeTrackerState;
        if (readOnlyState) {
          return readOnlyState.weekdayIndex;
        } else if (editState) {
          return editState.weekdayIndex;
        }
      };

      const localizedDecimal = I18n.lookup('number').format.separator;
      const localizedPrecision = I18n.lookup('number').format.precision;
      const callPreventDefault = function (event) {
        event.preventDefault();
      };
      const allowedKeyCodes = __range__(0, 47, true).concat(
        __range__(91, 187, true)
      ); // non-character keys like shift, tab, alt, etc.
      const decimalPattern = /\,|\.|\:/;

      $scope.hourEditKeyBinding = {
        allowDefaultIf({ altKey, ctrlKey, metaKey }) {
          return altKey || ctrlKey || metaKey;
        },
        all(event) {
          if (event.isDefaultPrevented()) {
            return;
          }
          const { altKey, ctrlKey, metaKey } = event;
          const isAllowedKey =
            altKey ||
            ctrlKey ||
            metaKey ||
            _.contains(allowedKeyCodes, event.which);
          if (isAllowedKey) {
            return;
          }
          const { selectionStart, selectionEnd, value } = event.target;
          const textIsSelected = selectionStart !== selectionEnd;
          if (textIsSelected) {
            return;
          }
          const charsAfterDecimal = value.split(decimalPattern)[1] || [];
          const maxDecimalPlacesReached =
            charsAfterDecimal.length === localizedPrecision;
          const decimalIndex = _.findIndex(value.split(''), (char) =>
            char.match(decimalPattern)
          );
          const isEditingDecimalValue = selectionStart > decimalIndex;
          const tooManyDecimalPlaces =
            maxDecimalPlacesReached && isEditingDecimalValue;
          if (tooManyDecimalPlaces) {
            event.preventDefault();
          }
        },
        13() {
          // Enter
          var focusInterval = setInterval(function () {
            const timeGreaterPopupElem = document.querySelectorAll(
              '#timeGreaterPopupContainer'
            );
            if (timeGreaterPopupElem.length > 0) {
              timeGreaterPopupElem[0].focus();
              clearInterval(focusInterval);
            }
          }, 100);

          setTimeout(function () {
            clearInterval(focusInterval);
          }, 1000);

          $scope.$apply(function () {
            $scope.endEdit(true);
          });
        },
        27() {
          // Escape
          var focusInterval = setInterval(function () {
            const timeGreaterPopupElem =
              document.querySelectorAll('#summaryHoursId');
            if (timeGreaterPopupElem.length > 0) {
              timeGreaterPopupElem[0].focus();
              clearInterval(focusInterval);
            }
          }, 100);

          setTimeout(function () {
            clearInterval(focusInterval);
          }, 1000);

          $scope.$apply(function () {
            $scope.endEdit(false);
          });
        },
        9(event) {
          // Tab key pressed
          if ($scope.dayCount === 1 && $scope.timeEntryMode === 'itemized') {
            $scope.$apply(function () {
              if (event.shiftKey) {
                $scope.editPreviousNotesFromCell();
              } else {
                $scope.editNextNotesFromCell();
              }
            });
          } else {
            $scope.$apply(function () {
              if (event.shiftKey) {
                $scope.editPreviousCell();
              } else {
                $scope.editNextCell();
              }
            });
          }
        },
        40() {
          // Down
          if ($scope.dayCount === 7) {
            $scope.$apply(function () {
              if ($scope.timeTrackerState.editState != null) {
                $scope.timeTrackerState.editState.isEditingNotes = true;
              }
            });
          }
        },
        // comma
        188: [
          'default',
          localizedDecimal === ',' ? _.noop : callPreventDefault,
        ],
        // colon
        186: [
          'default',
          function (event) {
            if (!event.shiftKey) {
              event.preventDefault();
            }
          },
        ],
        // period
        190: 'default',
      };

      for (var keyCode of Array.from(
        allowedKeyCodes.concat([48, 49, 50, 51, 52, 53, 54, 55, 56, 57])
      )) {
        // 0 through 9
        $scope.hourEditKeyBinding[keyCode] =
          $scope.hourEditKeyBinding[keyCode] || 'default';
      }

      $scope.notesEditKeyBinding = {
        // enter
        // TODO blur input to intiate save
        13() {
          $scope.$apply(function () {
            $scope.timeTrackerState.notesEditState = null;
          });
        },
        // escape
        // TODO clear and blur input
        27() {
          $scope.$apply(function () {
            $scope.timeTrackerState.notesEditState = null;
          });
        },
        // tab
        9(event) {
          $scope.$apply(function () {
            const { date, i } = $scope.timeTrackerState.notesEditState;
            if (event.shiftKey) {
              $scope.editPreviousCellFromNotes();
            } else {
              $scope.editNextCellFromNotes();
            }
          });
        },
      };

      // highlights a time tracker row
      // @param {object} row object (from data.rows)
      $scope.highlightRow = function (row) {
        if ($scope.timeTrackerState.highlightedRow) {
          $scope.timeTrackerState.highlightedRow.highlighted = false;
        }
        row.highlighted = true;
        $scope.timeTrackerState.highlightedRow = row;
      };

      // Unhighlights a time tracker row (denoting that it is not timing)
      // @param {object} row The current row object.
      $scope.unHighlightRow = function (row) {
        if ($scope.timeTrackerState.highlightedRow === row) {
          $scope.timeTrackerState.highlightedRow.highlighted = false;
        }
      };

      $scope.isTimerRunning = false;

      // Convinence function to find if a row with a given id is present
      const hasRowWithId = (rowId) =>
        _.find($scope.data.rows, (row) => row.id === rowId);

      // Visually sets the row and timer to "active" state.
      // @param {number} [rowKey=0] The key of the task/category being billed.
      // rowKey currently will be 0 in hoursMinutes or row.id in itemized
      const setTimerActive = function (rowKey) {
        if (rowKey == null) {
          rowKey = 0;
        }
        if (
          $scope.timeEntryMode === 'itemized' &&
          hasRowWithId(rowKey) === undefined
        ) {
          $log.warn('Row with id ' + rowKey + ' not found. Stopping.');
          return;
        }

        $scope.isTimerRunning = true;
        $scope.rowState.timers[rowKey] = $scope.rowState.timers[rowKey] || {};
        $scope.rowState.timers[rowKey].timerText = I18n.t('lbl_add_00:00');
        $scope.rowState.timers[rowKey].timerActive = true;
      };

      // Visually sets the row and timer to the "inactive" state.
      // @param {number} [rowKey=0] The key of the task/category being billed.
      const setTimerInactive = function (rowKey) {
        if (rowKey == null) {
          rowKey = 0;
        }
        if (
          $scope.timeEntryMode === 'itemized' &&
          hasRowWithId(rowKey) === undefined
        ) {
          $log.warn('Row with id ' + rowKey + ' not found. Stopping.');
          return;
        }

        $scope.isTimerRunning = false;
        $scope.rowState.timers[rowKey] = $scope.rowState.timers[rowKey] || {};
        $scope.rowState.timers[rowKey].timerText = false;
        $scope.rowState.timers[rowKey].timerActive = false;
      };

      let stopTimerText = undefined;

      const stopUpdatingTimerText = function () {
        if (stopTimerText != null) {
          clearInterval(stopTimerText);
          stopTimerText = undefined;
        }
      };

      const startUpdatingTimerText = function (timeEntry, rowKey) {
        if (rowKey == null) {
          rowKey = 0;
        }
        const updateTimerText = function () {
          const elapsed = Date.now() - startedAt;
          let hours = Math.floor(elapsed / (1000 * 60 * 60));
          if (hours < 10) {
            hours = `0${hours}`;
          }
          let minutes = Math.floor((elapsed / (1000 * 60 * 60) - hours) * 60);
          if (minutes < 10) {
            minutes = `0${minutes}`;
          }
          const newText = I18n.t('lbl_add_hours:minutes', {
            hours: hours,
            minutes: minutes,
          });
          const oldText = $scope.rowState.timers[rowKey].timerText;
          if (newText !== oldText) {
            $scope.$evalAsync(function () {
              $scope.rowState.timers[rowKey].timerText = newText;
            });
          }
        };

        if (TKTimeTrackerTimerManager.hasTimerRunning(timeEntry.id)) {
          var { startedAt } = TKTimeTrackerTimerManager.getRunningTimer();
          updateTimerText();
        }

        // Not using $interval because of memory leak issue.
        // Tried passing `false` for the `invokeApply` argument, but
        // that did not solve the issue.
        stopTimerText = setInterval(updateTimerText, 1000);
      };

      // make sure that the interval is destroyed too
      cleanup(stopUpdatingTimerText);

      const formatElapsedTime = function (elapsedTimeInMs) {
        const time = new Date(elapsedTimeInMs);
        const base = new Date(0);
        const hours = Math.floor(elapsedTimeInMs / (1000 * 60 * 60));
        const minutes = +(
          (time.getMinutes() - base.getMinutes()) /
          60
        ).toPrecision(2);
        return hours + minutes;
      };

      const preventDuplicateHandlers = ((
        hash // NOTE This function fixes a bug where timers were adding double or triple
      ) =>
        // the amount they should be when switching between timers because
        // each switch would create another duplicate handler.
        function (key, deregister) {
          if (hash[key]) {
            hash[key]();
          }
          hash[key] = deregister;
        })({});

      const registerTimerStopHandler = function (timeEntry, rowKey) {
        if (rowKey == null) {
          rowKey = 0;
        }
        onStopTimerHandle = $rootScope.$on(
          'tk-timer-stopped',
          function (e, data) {
            if (data.timeEntryId !== timeEntry.id) {
              return;
            }
            setTimerInactive(rowKey);
            stopUpdatingTimerText();
            $scope.data.expanded = true;
            timeEntry.hours += formatElapsedTime(data.elapsed);
            timeEntry.hours = +timeEntry.hours.toFixed(
              I18n.lookup('number').format.precision
            );
            if (timeEntry.hours === 0) {
              timeEntry.hours = 0.001;
            }
            const resolve = (te) => {
              $scope.data.calculateRowTime($scope.startDate);
              $scope.data.rowChanged();
              return te;
            };
            TKData.updateTimeEntry(timeEntry).then(resolve);
          }
        );
        preventDuplicateHandlers(timeEntry.id, onStopTimerHandle);
      };

      // This fn is specifically for dummy itemized row that doesn't have a timeEntry
      // TODO would like to get away from empty UI markup...
      $scope.toggleTimerNew = function (date, rowIndex) {
        const placeholder = {
          user_id: $scope.userId,
          date: TKDateUtil.toRubyDate(date),
          placeholder: true,
        };

        $scope.rowManager.addTimeEntry(
          placeholder,
          $scope.data.assignable,
          'project'
        );

        $scope.toggleTimer(date, rowIndex);
      };

      // Starts or stops a timer to bill time towards a category.
      // If started, the timer will be stopped and the accrued time will be applied
      // to the current row/category's time.
      // If the timer is stopped, the timer will be started.
      // @param {string} date date string for the day the timer is associated with.
      // @param {number} [rowIndex=0] the index of the task/category being billed.
      $scope.toggleTimer = function (date, rowIndex) {
        // guardian to return to redirect to sign in, if session timeout or network error
        // to prevent losing tracked time on a unresponsive page
        if (rowIndex == null) {
          rowIndex = undefined;
        }
        $http
          .get(`${window.API_BASE_URL}/users/${$scope.userId}`)
          .success(function (data, status, headers, config) {})
          .error((data, status, headers, config) => redirect('/'));

        if ($scope.data.isEditingRow) {
          $scope.data.isEditingRow = false;
          $timeout.cancel($scope.data.isEditingRowTimeout);
        }

        ($scope.data.isSavingPromise =
          $scope.data.isSavingPromise || $q.when()).then(function () {
          let numTimeEntries, timeEntry;
          let rowKey = 0;
          $scope.data.removePlaceholders(date);
          // display the locked popup if row is locked
          if ($scope.data.isLocked(date, rowIndex)) {
            $scope.rowState.showLockedTimerPopover = true;
            return;
          }

          // determine if this row has a timeEntry associated with it. If not, then
          // create a new timeEntry and start a timer.
          if ($scope.timeEntryMode === 'itemized') {
            const rowTiming = $scope.data.rows[rowIndex];
            rowKey = rowTiming.id;
            timeEntry =
              rowTiming.timeEntriesByDate[$scope.startDate.toDateString()];
            if (timeEntry instanceof Array === true) {
              numTimeEntries = timeEntry.length; // not sure if needed
              timeEntry = timeEntry[0];
            } else {
              timeEntry = undefined;
            }
          } else {
            timeEntry =
              $scope.data.timeEntriesByDate[$scope.startDate.toDateString()];
            timeEntry.timeEntries = _.remove(
              timeEntry.timeEntries,
              (te) => te.is_suggestion === false
            );
            if (timeEntry.timeEntries instanceof Array === true) {
              numTimeEntries = timeEntry.timeEntries.length;
              timeEntry = timeEntry.timeEntries[0];
            } else {
              timeEntry = undefined;
            }
          }

          // TODO handle timer update if running when we create a new combined timeEntry
          // Scenario occurs when switching from itemized to another time tracking mode
          // If in non-itemized mode, and multiple time entries exist, create a new
          // time entry with the sum of all the time.
          if ($scope.timeEntryMode !== 'itemized' && numTimeEntries > 1) {
            $scope.data.deleteTimeEntriesForDate($scope.startDate);
            $scope.data
              .createTimeEntry($scope.userId, $scope.startDate, rowIndex, {
                // startTime is saved in JSON as a string, it must be converted back to an int.
                hours: $scope.data.confirmedHours,
              })
              .then(function (timeEntry) {
                registerTimerStopHandler(timeEntry);
                TKTimeTrackerTimerManager.startTimer(
                  timeEntry.id,
                  timeEntry.date
                );
                setTimerActive();
                return startUpdatingTimerText(timeEntry);
              });
            return;
          }

          // Itemized and non-itemized mode: Create a time entry for this row if one
          // does not already exist.
          if (timeEntry === undefined) {
            setTimerActive(rowKey);
            $scope.data
              .createTimeEntry($scope.userId, $scope.startDate, rowIndex, {
                // startTime is saved in JSON as a string, it must be converted back to an int.
                hours: 0.001,
              })
              .then(
                function (timeEntry) {
                  registerTimerStopHandler(timeEntry, rowKey);
                  TKTimeTrackerTimerManager.startTimer(
                    timeEntry.id,
                    timeEntry.date
                  );
                  return startUpdatingTimerText(timeEntry, rowKey);
                },
                (response) => setTimerInactive(rowKey)
              );
            return;
          }

          if (numTimeEntries === 0) {
            throw 'could not find the time entry';
          }
          if (numTimeEntries > 1) {
            throw 'too many time entries';
          }

          if (TKTimeTrackerTimerManager.hasTimerRunning(timeEntry.id)) {
            setTimerInactive(rowKey);
            stopUpdatingTimerText();
            $scope.data.expanded = true;
            if (onStopTimerHandle) {
              onStopTimerHandle();
            } // deregister
            onStopTimerHandle = undefined;
            timeEntry.hours += formatElapsedTime(
              TKTimeTrackerTimerManager.stopTimer(timeEntry.id)
            );
            timeEntry.hours = +timeEntry.hours.toFixed(
              I18n.lookup('number').format.precision
            );
            if (timeEntry.hours === 0) {
              timeEntry.hours = 0.001;
            }
            const resolve = (te) => {
              $scope.data.calculateRowTime($scope.startDate);
              $scope.data.rowChanged();
              return te;
            };
            TKData.updateTimeEntry(timeEntry).then(resolve);
          } else {
            registerTimerStopHandler(timeEntry, rowKey);
            TKTimeTrackerTimerManager.startTimer(timeEntry.id, timeEntry.date);
            setTimerActive(rowKey);
            startUpdatingTimerText(timeEntry, rowKey);
          }
        });
      };

      // Cancels the the row timer without adding the accrued time to the
      // time tracking cell.
      // @param {object} The current row
      // @param {number} The current task's index. For instance, there can be
      //        multiple task categories billed to a single row (which is a
      //        project or project/phase.)
      $scope.cancelTimer = function (rowIndex) {
        if (rowIndex == null) {
          rowIndex = 0;
        }
        TKAnalytics.timeEntry.clearTimer();
        const timeEntry = $scope.getTimeEntry(rowIndex);
        let rowKey = 0;
        if ($scope.timeEntryMode === 'itemized') {
          const rowTiming = $scope.data.rows[rowIndex];
          rowKey = rowTiming.id;
        }
        if (
          TKTimeTrackerTimerManager.hasTimerRunning(
            timeEntry != null ? timeEntry.id : undefined
          )
        ) {
          TKTimeTrackerTimerManager.stopTimer(timeEntry.id);
          setTimerInactive(rowKey);
          stopUpdatingTimerText();
        }
        if ((timeEntry != null ? timeEntry.hours : undefined) === 0.001) {
          $scope.data.removeTimeEntry(timeEntry);
          TKData.deleteTimeEntry(timeEntry);
        }
      };

      // TODO somehow account for weekview
      // Gets the timeEntry of a given row. Works in day view for itemized and non-itemized modes.
      // @param {number} rowIndex The index of the row that contains the timeEntry you want.
      $scope.getTimeEntry = function (rowIndex) {
        let timeEntry = undefined;

        // itemized mode
        if ($scope.timeEntryMode === 'itemized') {
          // guardian
          if (
            rowIndex === undefined ||
            $scope.data.rows[rowIndex] === undefined
          ) {
            $log.warn(
              'getTimeEntryId: Row with index ' +
                rowIndex +
                ' not found. Stopping.'
            );
            return;
          }

          timeEntry = __guard__(
            $scope.data.rows[rowIndex].timeEntriesByDate[
              new Date($scope.startDate).toDateString()
            ],
            (x) => x[0]
          );

          // non-itemized mode
        } else {
          // more than one time entry here
          let timeEntries = __guard__(
            $scope.data.timeEntriesByDate[
              new Date($scope.startDate).toDateString()
            ],
            (x1) => x1.timeEntries
          );
          timeEntries = _.remove(
            timeEntries,
            (te) => te.is_suggestion === false
          );
          timeEntry = timeEntries[0];
        }
        return timeEntry;
      };

      // Redirect to a project page
      // NOTE $scope.projectsById comes from `tkTimeTracker`
      // @param {object} assignable Assignable object, will either be project or phase
      // @return {undefined}
      $scope.goToProjectPage = function (assignable) {
        const id = assignable.parent_id
          ? $scope.projectsById[assignable.parent_id].id
          : assignable.id;
        $window.location.href = `${window.APP_ENDPOINT}/viewproject?id=${id}`;
      };

      // init -----------------------------------------------------------------

      // visually activate running timer
      if ($scope.dayCount === 1 && $scope.modeTimeEntry) {
        const { data } = $scope;
        if ($scope.timeEntryMode === 'itemized') {
          return _.each(data.rows, function (row, rowIndex) {
            let timeEntry = $scope.getTimeEntry(rowIndex);
            if (TKTimeTrackerTimerManager.hasTimerRunning(timeEntry.id)) {
              // find timeEntry
              if (
                data.rows[rowIndex].timeEntriesByDate[
                  $scope.startDate.toDateString()
                ][0].id === timeEntry.id
              ) {
                timeEntry =
                  data.rows[rowIndex].timeEntriesByDate[
                    $scope.startDate.toDateString()
                  ][0];
              }
              if (
                timeEntry.approvals.data.length >= 1 &&
                timeEntry.approvals.data[0].status === 'approved'
              ) {
                setTimerInactive(row.id);
                TKTimeTrackerTimerManager.stopTimer(timeEntry.id);
                return;
              }
              registerTimerStopHandler(timeEntry, row.id);
              setTimerActive(row.id);
              startUpdatingTimerText(timeEntry, row.id);
            }
          });
        } else {
          const { timeEntries } =
            data.timeEntriesByDate[$scope.startDate.toDateString()];
          return _.each(timeEntries, function (timeEntry, rowIndex) {
            if (TKTimeTrackerTimerManager.hasTimerRunning(timeEntry.id)) {
              registerTimerStopHandler(timeEntry);
              setTimerActive();
              startUpdatingTimerText(timeEntry);
            }
          });
        }
      }
    },
  }),
]);

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
