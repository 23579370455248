/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').directive('tkDatePicker', [
  '$log',
  ($log) => ({
    restrict: 'AE',
    transclude: 'true',
    templateUrl: 'ng-common/templates/datePicker',

    scope: {
      model: '=',
      todayButton: '&?',
      onChange: '&?',
    },

    controller: function ($scope, $element) {
      if ($scope.todayButton() == null) {
        $scope.todayButton = () => true;
      }

      $scope.I18n = I18n;

      $scope.today = () => new Date();

      $scope.toToday = function (event) {
        event.stopPropagation();
        $scope.model = $scope.today();
        return typeof $scope.onChange === 'function'
          ? $scope.onChange()
          : undefined;
      };

      return $scope.$on('setDate', function (event, model, view, inputEvent) {
        inputEvent.stopPropagation();
        return typeof $scope.onChange === 'function'
          ? $scope.onChange()
          : undefined;
      });
    },
  }),
]);
