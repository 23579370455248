angular.module('app').filter('tkLocalizedDate', function () {
  return function (dateString, format) {
    if (format == 'week') {
      var options = { weekday: 'short' };
    } else if (format == 'month') {
      var options = { month: 'short', day: '2-digit' };
    } else if (format == 'dayHeader') {
      var options = { weekday: 'long', month: 'long', day: 'numeric' };
    } else if (format == 'yearMonth') {
      var options = { year: 'numeric', month: 'long' };
    } else if (format == 'expenseDate') {
      var options = { year: 'numeric', month: 'short', day: '2-digit' };
    } else if (format == 'weekDayCalendar') {
      var options = { weekday: 'narrow' };
    } else if (format == 'setToTodayLabel') {
      var options = { month: 'short', day: 'numeric', year: 'numeric' };
    } else {
      var options = { weekday: 'long', month: 'short', day: '2-digit' };
    }

    var date = new Date(dateString);
    var localizedDate = date.toLocaleDateString(window.I18n.locale, options);
    return localizedDate;
  };
});
