angular.module('app.services').factory('TKTimeEntryHelper', function () {
  // The six time entry types:
  //   - suggestion
  //   - psuedo-suggestion
  //   - placeholder
  //   - temp
  //   - suggestion clearing
  //   - confirmed
  //
  // All six are mutually exclusive, i.e. a given time entry
  // can only match one of these six types at a time.

  const isSuggestion = (timeEntry) =>
    timeEntry.is_suggestion && timeEntry.scheduled_hours !== 0;

  const isPseudoSuggestion = (timeEntry) =>
    timeEntry.is_suggestion && timeEntry.scheduled_hours === 0;

  const isPlaceholder = (timeEntry) => timeEntry.placeholder;

  const isTemp = (timeEntry) => timeEntry.temp;

  const isSuggestionClearing = (timeEntry) =>
    timeEntry.hours === 0 &&
    !timeEntry.notes &&
    !timeEntry.is_suggestion &&
    !isPlaceholder(timeEntry) &&
    !isTemp(timeEntry);

  const isConfirmed = (timeEntry) =>
    !timeEntry.is_suggestion &&
    !isPlaceholder(timeEntry) &&
    !isTemp(timeEntry) &&
    !isSuggestionClearing(timeEntry);

  // additional convenience functions
  const isVisible = (timeEntry) =>
    isPlaceholder(timeEntry) || isConfirmed(timeEntry);

  const TKTimeEntryHelper = {
    isSuggestion,
    isPseudoSuggestion,
    isPlaceholder,
    isTemp,
    isSuggestionClearing,
    isConfirmed,
    isVisible,
  };

  return TKTimeEntryHelper;
});
