/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app.filters').filter('dateRelativeToNow', function () {
  const ONE_MINUTE = 60 * 1000;
  const ONE_HOUR = ONE_MINUTE * 60;
  const ONE_DAY = ONE_HOUR * 24;
  const ONE_WEEK = ONE_DAY * 7;

  const millisecondsToMinutes = (ms) => Math.floor(ms / ONE_MINUTE);
  const millisecondsToHours = (ms) => Math.floor(ms / ONE_HOUR);
  const millisecondsToDays = (ms) => Math.floor(ms / ONE_DAY);
  const millisecondsToWeeks = (ms) => Math.floor(ms / ONE_WEEK);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const s = function (n) {
    if (n === 1) {
      return '';
    } else {
      return 's';
    }
  };

  const twelveToTwelve = (hours) => hours % 12 || 12;

  const twoDigits = function (minutes) {
    if (minutes < 10) {
      return `0${minutes}`;
    } else {
      return `${minutes}`;
    }
  };

  const amOrPm = function (hours) {
    if (hours > 11) {
      return 'pm';
    } else {
      return 'am';
    }
  };

  const getYearMonthDay = (date) => [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  ];

  const getHoursAndMinutes = (date) => [date.getHours(), date.getMinutes()];

  const getHoursAndMinutesDisplay = function (date) {
    const [hours, minutes] = Array.from(getHoursAndMinutes(date));
    return `${twelveToTwelve(hours)}:${twoDigits(minutes)} ${amOrPm(hours)}`;
  };

  const displayDate = (
    year,
    month,
    day // TODO use i18n here
  ) => `${monthNames[month]} ${day}, ${year}`;

  const filterFunction = function (dateString) {
    const date = new Date(dateString);
    if (_.isNaN(date.getDate())) {
      return '...';
    }
    const now = new Date();

    const yearMonthDay = getYearMonthDay(date);
    const thisYearMonthDay = getYearMonthDay(now);
    const isToday = _.isEqual(yearMonthDay, thisYearMonthDay);
    if (isToday) {
      return `Today at ${getHoursAndMinutesDisplay(date)}`;
    }

    const [year, month, dayOfMonth] = Array.from(yearMonthDay);
    const [thisYear, thisMonth, thisDayOfMonth] = Array.from(thisYearMonthDay);
    const dateAtZeroHour = new Date(year, month, dayOfMonth);
    const todayAtZeroHour = new Date(thisYear, thisMonth, thisDayOfMonth);
    const timeAgo = todayAtZeroHour - dateAtZeroHour;
    let daysAgo = millisecondsToDays(timeAgo);
    const isYesterday = daysAgo === 1;
    if (isYesterday) {
      return `Yesterday at ${getHoursAndMinutesDisplay(date)}`;
    }
    if (daysAgo < 7) {
      daysAgo = millisecondsToDays(timeAgo);
      return `${daysAgo} days ago`;
    }
    if (daysAgo <= 31) {
      const weeksAgo = millisecondsToWeeks(timeAgo);
      return `${weeksAgo} week${s(weeksAgo)} ago`;
    }
    if (year === thisYear) {
      const monthsAgo = thisMonth - month;
      return `${monthsAgo} month${s(monthsAgo)} ago`;
    }
    return displayDate(year, month, dayOfMonth);
  };

  return filterFunction;
});
