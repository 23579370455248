/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
angular.module('app').controller('BridgeController', [
  '$scope',
  '$element',
  '$rootScope',
  '$log',
  'TKBridge',
  function ($scope, $element, $rootScope, $log, TKBridge) {
    // The bridge controller has a session ready variable, which allows any embedded
    // widgets to not appear until the session data is available, with a
    //   ng-if="sessionReady"
    // directive.
    $scope.sessionReady = false;

    const domElement = $element[0];

    TKBridge.signin.then(() => ($scope.sessionReady = true));

    // The list of events to propagate to the parent app
    const propagateEvents = [
      'tk-submit-approvals',
      'tk-show-approvals-filter',
      'tk-time-tracker-loaded',
    ];

    const elem = angular.element(domElement);

    elem.on('tk-approvables-type', function (event, b, c) {
      const params = event.originalEvent.detail;
      const param = params[0];
      return $scope.$broadcast.apply($scope, [event.type].concat(params));
    });

    const propagateEvent = function ($event, ...params) {
      // $log.info "===================================================================================="
      // $log.info "Received event to propagate to container app"
      // $log.info "------------------------------------------------------------------------------------"
      // $log.info "event:", $event
      // $log.info "arguments:", arguments
      // $log.info "===================================================================================="
      const event = document.createEvent('CustomEvent');
      event.initCustomEvent($event.name, true, false, params);
      return domElement.dispatchEvent(event);
    };

    return _.each(propagateEvents, (name) => $scope.$on(name, propagateEvent));
  },
]);
